<!-- 饰品商城 -->
<template>
	<div class="app StarStore">
		<div
		  :class="[
		    { introduced: GainStarModal }
		  ]"
		></div>
		<div class="s"></div>
		<!-- 菜单分类 -->
		<MenuItems @starShopSkinListS="starShopSkinListS" />
		<div class="integral">
			<div class="my_integral" v-if="this.$store.state.token">
				星币：{{$store.state.userInfo.total_star ? $store.state.userInfo.total_star : 0.00}}
			</div>
			<div class="get_integral"
			:style="VPointer()"
			 v-if="this.$store.state.token"
			 @click="skipGainStar">
				获取星币
			</div>
			<div
			  class="fight-body-battleground-but-ShowModal"
			  v-show="GainStarModal"
			>
			  <GainStar
			    ref="GainStarModalRef"
			    :visible.sync="GainStarModal"
			  />
			</div>

			<div class="integral_log"
			:style="VPointer()"
			 v-if="this.$store.state.token"
			 @click="$router.push('/MyStarLog')">
				星空日志
			</div>
		</div>
		<!-- 全部商品 -->
		<div class="StoreAllGoods">
			<ul v-if="shopSkinList.length">
				<li v-for="item in shopSkinList" :key="item.id">
					<div class="back">
						<img :src="item.shop_thumb" alt="" />
					</div>
					<div class="subject">
						<img :src="item.image_url" alt="" />
					</div>
					<div class="shadow">
						<img src="@/assets/images/showImages/shadow.png" alt="" />
					</div>

					<el-tooltip placement="bottom">
						<div slot="content">{{ item.item_name }}</div>
						<div class="nameText" :title="item.item_name">
							{{ item.item_name }}
						</div>
					</el-tooltip>
					<div class="price">
						<div class="img">
							<img src="https://oss.n7buff.com/star_currency.png" alt="" />
						</div>
						<div class="priceText">{{ item.price }}</div>
					</div>
					<div class="exchange" :style="VPointer()"
						@click="ImmediateExchange(item.id,item.price,item.item_name)">
						立即兑换
					</div>
				</li>
			</ul>
			<div v-else class="noMore">
				<img src="@/assets/images/dataNone.png" style="width:200px;height:200px" />
				<div>没有更多了</div>
			</div>
		</div>
		<div class="loadMore" v-if="page_size < total_rows">
			<img :style="VPointer()" @click="loadMore" src="@/assets/images/LuckyOrnament/Load-more.png" alt="" />
		</div>
	</div>
</template>

<script>
	import MenuItems from '@/components/MenuItems.vue'
	import GainStar from './components/GainStar.vue'
	import {
		get_star_shop_list,
		buy_star_skin
	} from '@/api/index/StarStore'
	export default {
		name: 'StarStore',
		data() {
			return {
				imgurl: 'https://oss.n7buff.com/star_currency.png',
				shopSkinList: [], // 商城饰品列表
				total_rows: null,
				page: 1,
				page_size: 15,
				skin_category_id: null, // 一级id
				skin_category_sub_id: null, // 二级id
				quality_id: null, // 类别id
				rarity_id: null, // 品质id
				exterior_id: null, // 外观id
				lucky_recomm: 0 ,// 是否推荐
				GainStarModal: false, //获取星币
			}
		},
		components: {
			MenuItems,
			GainStar
		},
		created() {
			if (sessionStorage.getItem('n7_token')) {
				this.$store.dispatch('getPlayerApi')
			}
			this.getSkinListApi()
		},
		methods: {
			// 子传父获取
			starShopSkinListS(shopSkinList, parmas, total_rows) {
				this.nameAccessories = parmas.name
				this.skin_category_id = parmas.skin_category_id
				this.skin_category_sub_id = parmas.skin_category_sub_id
				this.quality_id = parmas.quality_id
				this.rarity_id = parmas.rarity_id
				this.exterior_id = parmas.exterior_id
				this.start_price = parmas.start_price
				this.end_price = parmas.end_price
				this.lucky_recomm = parmas.lucky_recomm
				this.total_rows = total_rows
				this.shopSkinList = shopSkinList
			},
			getSkinListApi() {
				get_star_shop_list({
					page: this.page,
					page_size: this.page_size,
					name: this.nameAccessories,
					skin_category_id: this.skin_category_id,
					skin_category_sub_id: this.skin_category_sub_id,
					quality_id: this.quality_id,
					rarity_id: this.rarity_id,
					exterior_id: this.exterior_id,
					lucky_recomm: this.lucky_recomm,
					start_price: parseInt(Number(this.start_price)),
					end_price: parseInt(Number(this.end_price)),
				}).then((res) => {
					if (res.status === 1) {
						this.total_rows = res.data.total_rows
						this.shopSkinList = res.data.rows
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			// 立即兑换
			ImmediateExchange(id, item_price, item_name) {
				// 判断用户是否登录
				if (!sessionStorage.getItem('n7_token')) {
					this.$store.dispatch('setLogSignRetrieve', true)
					return
				}

				this.$confirm('是否消耗 ' + item_price + '星币 兑换 ' + item_name + ' ?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						dangerouslyUseHTMLString: true
					})
					.then(() => {
						buy_star_skin({
							skin_id: id
						}).then((res) => {
							if (res.status === 1) {
								this.$store.dispatch('getPlayerApi')
								this.$message({
									message: '兑换成功',
									type: 'success'
								})
								this.getSkinListApi()
							} else {
								this.$message.error(res.msg)
							}
						})
					})
					.catch(() => {
						this.$message({
							type: 'info',
							message: '取消兑换'
						})
					})
			},
			//跳转获取星币
			skipGainStar(){
				this.GainStarModal = true
				var mo = function (e) {
				  e.preventDefault()
				}
				document.body.style.overflow = 'hidden'
				this.$refs.GainStarModalRef.addData = []
				document.addEventListener('touchmove', mo, false) //禁止页面滑动
			},
			// 加载更多
			loadMore() {
				this.page_size = this.page_size + 15
				this.getSkinListApi()
			},
		}
	}
</script>
<style lang="scss" scoped>
	// 引入css类
	@import '@/styles/StarStore.scss';
	/* .MenuItems{
		border-bottom:0;
	} */
	// 弹出
	.introduced {
	  width: 100%;
	  height: 100%;
	  position: fixed;
	  top: 0;
	  left: 0;
	  background-color: rgba(0, 0, 0, 0.85);
	  z-index: 98;
	  transition: all 0.15s linear;
	}
	.fight-body-battleground-but-ShowModal {
	  position: fixed;
	  top: 50%;
	  left: 50%;
	  transform: translate(-50%, -50%);
	  z-index: 99;
	}
	.noMore {
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		div {
			color: #999;
		}
	}

	.priceText {
		color: #FFA500;
	}

	.integral {
		width: 100%;
		height: 0.3rem;
		padding-top: 0.2rem;
		//background-color: #307086;
		//border: 0.01rem #1ef0ed solid;
		line-height: 0.3rem;
		font-size: 0.1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.my_integral {
		color: #FFA500 ;
		left:.1rem;
		text-align: left;
		font-size: 0.15rem;
		display: flex;
		margin-left: 0.2rem;
	}
	/* .my_integral:hover{
		color: #FFA500 ;
	} */
	.integral_log {
		width: 10%;
		//right:.1rem;
		//background: url('https://oss.n7buff.com/web/images/get_star.png') no-repeat;
		background-size: 100% 100%;
		border: 0.015rem #FFA500 solid;
		border-radius: 0.01953rem;
		//right: 0.1rem;
		//width: 120px;
		color: #FFFFFF ;
		line-height: 0.25rem;
		margin-right: 0.1rem;
		font-size: 0.12rem;
	}
	.integral_log:hover{
		color: #FFA500 ;
	}
	.get_integral{
		width: 10%;
		//height: 5rem;
		margin-left: 5rem;
		right:0.1rem;
		//background: url('https://oss.n7buff.com/web/images/get_star.png') no-repeat;
		border: 0.015rem #FFA500 solid;
		border-radius: 0.01953rem;
		text-align: center;
		line-height: 0.25rem;
		color: #FFFFFF ;
		height: 0.25rem;
		font-size: 0.12rem;
	}
	.get_integral:hover{
		color: #FFA500 ;
	}


</style>
