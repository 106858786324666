<!-- SC:GO盲盒 -->
<template>
  <div class="home app">
    <div class="home-body">
      <HomeBody />
    </div>
  </div>
</template>

<script>
import HomeBody from './HomeBody.vue'
export default {
  name: 'K5Home',
  components: {
    HomeBody
  },
  data() {
    return {}
  },
  created() {
		if (sessionStorage.getItem('n7_token')) {
			this.$store.dispatch('getPlayerApi')
		}

	},
  methods: {}
}
</script>
<style lang="scss" scoped>
</style>
