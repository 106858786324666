<!-- HomeFoot -->

<template>
  <div class="foot">
    <img
      class="foot-decorate"
      src="https://oss.n7buff.com/web/images/Bottom%20menu.png"
      alt=""
    />
    <img class="foot-img" src="@/assets/images/line.png" alt="" />
    <div class="foot-z">
      <ul class="foot-z-head">
        <li>
          <div>{{ getTotalInfoList.open_box_num }}</div>
          <div>已开启盲盒总数</div>
        </li>
        <img src="@/assets/images/Bottom.png" alt="" />
        <li>
          <div>{{ getTotalInfoList.register_num }}</div>
          <div>已注册人数</div>
        </li>
        <img src="@/assets/images/Bottom.png" alt="" />
        <li>
          <div>{{ getTotalInfoList.online_num }}</div>
          <div>在线人数</div>
        </li>
      </ul>
      <ul class="foot-z-body">
        <li @click="goHelp(1)" :style="VPointer()">关于我们</li>
        |
        <li @click="goHelp(2)" :style="VPointer()">隐私政策</li>
        |
        <li @click="goHelp(3)" :style="VPointer()">用户协议</li>
        |
        <li @click="goHelp(4)" :style="VPointer()">常见问题</li>
        |
        <li @click="goHelp(5)" :style="VPointer()">相关教程</li>
        |
        <li @click="goHelp(6)" :style="VPointer()">合规声明</li>
        |
        <li @click="goHelp(7)" :style="VPointer()">联系我们</li>
      </ul>
      <ul class="foot-z-foot foot-text-color">
        <li :style="VPointer()" @click="goWebView(1)" v-if="false">
          <img src="@/assets/images/BeiAn.png" alt="">
          鲁公安网备：37010202003012</li>
        <li :style="VPointer()" @click="goWebView(2)">备案号：鲁ICP备2023038418号-3</li>
        <li v-if="false">增值电信业务经营许可证：鲁B2-20230230</li>
        <li>版权所属：济南星界网络科技服务有限公司</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { get_total_info } from '@/api/index/home'
export default {
  name: 'HomeFoot',
  data() {
    return {
      getTotalInfoList: []
    }
  },
  created() {
    this.getTotalInfoApi()
  },
  methods: {
    getTotalInfoApi() {
      get_total_info().then((res) => {
        this.getTotalInfoList = res.data
      })
    },
    goHelp(e) {
      // console.log(123);
      this.$router.push('/Helpcenter?id='+e)
    },
	goWebView(type){
	  if(type==1){
	    window.open('https://beian.miit.gov.cn/#/Integrated/index')
	  }
	  if(type==2){
	    window.open('https://beian.miit.gov.cn/#/Integrated/index')
	  }
	},
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.foot {
  color: aliceblue;
  margin-top: 50px;
  position: relative;
  &-decorate {
    width: 100%;
    position: absolute;
    z-index: -1;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &-img {
    width: 100%;
  }
  &-z {
    margin: 50px 0 0px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-head {
      display: flex;
      align-items: center;
      li {
        :first-child {
          margin-bottom: 5px;
        }
      }
      img {
        margin: 0 40px;
      }
    }
    &-body {
      display: flex;
	  margin: .2rem auto;
      li {
        margin: 0 5px;
      }
    }
    &-foot {
      display: flex;
      flex-direction: column;
      align-content: center;
      padding-bottom: 0.1rem;
      img{
        width: .1rem;
        height: .1rem;
      }
    }
	.foot-text-color{
		color: #878787;
	}
	.foot-z-foot{
		padding-top: 0.1rem;
		li{
			margin-top: 0.03rem;
		}
	}
  }
}
</style>
