import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations.js'
import actions from './actions.js'
// import createPersistedState from "vuex-persistedstate"
import { getToken,getUserInfo,getUserVoice } from '@/utils/auth'
Vue.use(Vuex)
const state = {
  token:getToken(), // token
  userInfo:getUserInfo(),
  logSignRetrieve: false, // 登录注册弹出层
  Audio:getUserVoice()|| true, // 声音
}

const getters = {

}
export default new Vuex.Store({
	state,
	getters,
	mutations,
	actions
})
