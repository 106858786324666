import request from '@/api/request'
// 箱子所包含饰品-详情页
export const get_box_data = (data) => {
  return request({
    url: '/api/box/get_box_data',
    method: 'POST',
    data
  })
}

// 箱子--开箱
export const open_box = (data) => {
  return request({
    url: '/api/open_box/open_box',
    method: 'POST',
    data
  })
}

// 开箱历史记录
export const get_open_box_record = (data) => {
  return request({
    url: '/api/box/get_open_box_record',
    method: 'POST',
    data
  })
}
//箱子开箱历史记录
export const get_drop_record = (data) => {
  return request({
    url: '/api/box/get_drop_record',
    method: 'POST',
    data
  })
}



