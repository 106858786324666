<template>
  <div>
    <div :class="[{ introduced: CaseDetails }]"></div>
    <div id="navigation">
      <div class="operation">
        <div class="operation_item ">
          <p @click="returnRoute" :style="VPointer()">返回</p>
          <p :style="VPointer()" v-show="roomStatus == 1 && innerStatus " @click="toleaveRoom()">
            退出房间
          </p>
        </div>
        <div class="operation_item">
          <p
            v-clipboard:copy="url + '/battleDetail?id=' + this.$route.query.id"
            v-clipboard:success="copy"
            v-clipboard:error="onError"
            :style="VPointer()"
          >
            复制分享链接
          </p>
          <p @click="getRoomPrice" :style="VPointer()">
            创建同样的房间<span>{{ battleBoxSumPrice }}</span>
          </p>
          <p v-show="roomStatus == 1 && !innerStatus && battle_type != 3" @click="tojoinRoom()">
            加入房间
          </p>
        </div>
      </div>

      <div class="case_show">
        <div
          class="case_show_item"
          v-for="(item, index) in battleBoxList"
          :key="index"
          :style="VPointer()"
          :class="{ active: start.round == index }"
          @click="CaseDetailsTo(item.id)"
        >
          <div class="checkbox"></div>
          <div
            class="pic"
            :style="'background-image: url(' + item.img_main + ');'"
          >
            <img :src="item.img_active" alt=""/>
          </div>
          <img
            src="@/assets/images/icon_right.png"
            class="arrow"
            v-if="battleBoxList.length - 1 != index"
            alt=""
          />
        </div>
      </div>
      <div class="CaseDetails" v-if="CaseDetails">
        <CaseDetails
          :visible.sync="CaseDetails"
          :battleBoxData="battleBoxData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  RoomPrice,
  get_battle_box_data,
  get_battle_room_detail,
  create_battle_room,
  join_battle_room,
  sign_out_battle_room
} from '@/api/index/BlindBattle'
import CaseDetails from '../../views/BlindBoxFight/components/CaseDetails.vue'

export default {
  name: 'ANavigation',
  props: [
    'boxList',
    'roomPrice',
    'battleBoxSumPrice',
    'battleBoxList',
    'roomStatus',
    'innerStatus',
    'start',
    'battle_type'
  ],
  components: {
    CaseDetails
  },
  mounted() {
    this.url = window.location.origin + '/#'
  },
  data() {
    return {
      imgurl: require('@/assets/images/n7_money.png'),
      url: '',
      changePrice: 0,
      CaseDetails: false,
      battleBoxData: []
    }
  },
  methods: {
    async CaseDetailsTo(id) {
      const res = await get_battle_box_data({id: id})
      this.battleBoxData = res.data
      this.CaseDetails = true
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', mo, false) //禁止页面滑动
    },
    returnRoute() {
      if (this.$route.query.source) {
        this.$router.push('/BlindBoxFight')
      } else {
        this.$emit('clear')
        this.$router.push('/BlindBoxFight')
      }
    },

    //退出战场
    toleaveRoom() {
      this.$confirm(`确定要退出房间吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      })
        .then(() => {
          sign_out_battle_room({battle_room_id: this.$route.query.id}).then(
            (res) => {
              if (res.status == 1) {
                this.$store.dispatch('getPlayerApi')
                this.$message({
                  message: '已退出',
                  type: 'success',
                  duration: 1000
                })
                setTimeout(() => {
                  this.$router.push('/BlindBoxFight')
                }, 500)
              } else {
                this.$message.error(res.msg)
              }
            }
          )
        })
        .catch(() => {
        })
    },

    //加入战场
    tojoinRoom() {
      this.$confirm(
        `消耗<img style="width:0.2rem" src="${this.imgurl}">${this.battleBoxSumPrice}加入房间<br/>确定加入房间吗?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true
        }
      )
        .then(() => {
          join_battle_room({battle_room_id: this.$route.query.id}).then(
            (res) => {
              if (res.status == 1) {
                this.$store.dispatch('getPlayerApi')
                this.$message.success('加入成功')
                this.$emit('Refresh_room')
              } else {
                this.$message.error(res.msg)
              }
            }
          )
        })
        .catch(() => {
        })
    },

    //复制链接
    copy() {
      this.$message.success('复制成功')
    },
    onError() {
      this.$message.error('复制失败')
    },

    getRoomPrice() {
      this.tocopyRoom(`确定要消耗<img style="width:0.2rem" src="${this.imgurl}">${this.battleBoxSumPrice}<br/>创建房间吗?`)

      // RoomPrice(this.$route.query.id).then((res) => {
      //   if (res.data.data == this.roomPrice) {
      //     this.tocopyRoom(
      //       `确定要消耗${this.battleBoxSumPrice}创建房间吗?`
      //     )
      //   } else {
      //     this.changePrice = res.data.data
      //     this.tocopyRoom(
      //       `当前房间价格已变更为<img src="${this.imgurl}">${this.changePrice},是否继续创建?`
      //     )
      //   }
      // })
    },

    //复制房间
    tocopyRoom(v) {
      this.$confirm(v, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      })
        .then(() => {
          RoomPrice({battle_room_id: this.$route.query.id}).then((res) => {
            if (res.status == 1) {
              this.$store.dispatch('getPlayerApi')
              this.$message.success('创建成功')
              setTimeout(() => {
                this.$router.replace(
                  '/battleDetail?id=' + res.data.battle_room_id
                )
                this.$emit('tocopyRoom')
              }, 1000)
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {
        })
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';

#navigation {
  width: 1340px;
  height: 229px;
  background: url('@/assets/images/fightImages/frame.png') no-repeat center;
  background-size: contain;
  padding: 0 70px;

  .operation {
    width: 100%;
    height: 70px;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .operation_item {
      height: 40px;
      display: flex;

      p {
        height: 40px;
        padding: 0 22px;
        border: 3px solid $theme;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        font-size: 14px;
        font-weight: bold;

        i {
          width: 11px;
          height: 17px;
          margin-right: 9px;
          ///background: url(../../../assets/images/Battle/battleDetail/return.png) no-repeat center;
          background-size: contain;
          transform: translateY(1px);
        }

        span {
          color: $theme;
          font-weight: bold;
          margin-left: 6px;
        }

        &:hover {
          color: $theme;

          i {
            //background: url(../../../assets/images/Battle/battleDetail/return_hover.png) no-repeat center;
            background-size: contain;
          }
        }
      }
    }
  }

  .case_show {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;

    .case_show_item {
      width: 134px;
      height: 130px;
      margin: 0 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .checkbox {
        width: 180px;
        height: 180px;
        background: url('@/assets/images/fightImages/Photoefficiency.png') no-repeat 50%;
        background-size: contain;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        opacity: 0;
        transition: 0.3s;
      }

      .pic {
        min-width: 190px;
        width: 120px;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        //background-image: url(../../../assets/images/Battle/box.png);
        background-repeat: no-repeat;
        background-position: center;
        transition: 0.3s;
        background-size: 70% 80%;
        position: absolute;

        img {
          max-width: 85%;
          max-height: 85%;
        }
      }

      .next1 {
        position: absolute;
        top: 60%;
        left: 155%;
        transform: translate(-50%, -50%);
      }

      .next2 {
        position: absolute;
        top: 60%;
        left: 130%;
        transform: translate(-50%, -50%);
      }

      .next3 {
        position: absolute;
        top: 60%;
        left: 120%;
        transform: translate(-50%, -50%);
      }

      .next4 {
        position: absolute;
        top: 60%;
        left: 120%;
        transform: translate(-50%, -50%);
      }

      .next5 {
        position: absolute;
        top: 60%;
        left: 120%;
        transform: translate(-50%, -50%);
      }

      .next6 {
        position: absolute;
        top: 60%;
        left: 123%;
        transform: translate(-50%, -50%);
      }

      &:last-child .next {
        display: none;
      }

      &:last-child &-back {
        margin-right: 0px;
      }

      &.active {
        .checkbox {
          opacity: 1;
        }

        .pic {
          transform: scale(1.1);
        }
      }
    }
  }
}

.introduced {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 98;
  transition: all 0.15s linear;
}

.CaseDetails {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.arrow {
  width: 25px;
  margin-left: 10px;
  z-index: 2;
  position: absolute;
  right: -60px;
}

// @import "@/assets/media/media.scss";
</style>
