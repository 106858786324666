import request from '@/api/request'
// 盲盒对战-箱子列表
export const get_battle_box_list = (data) => {
  return request({
    url: '/api/battle_box/get_battle_box_list',
    method: 'POST',
    data
  })
}

// 盲盒对战-箱子详情
export const get_battle_box_data = (data) => {
  return request({
    url: '/api/battle_box/get_battle_box_data',
    method: 'POST',
    data
  })
}


// 盲盒对战-创建房间
export const create_battle_room = (data) => {
  return request({
    url: '/api/battle_box/create_battle_room',
    method: 'POST',
    data
  })
}


// 盲盒对战-加入房间
export const join_battle_room = (data) => {
  return request({
    url: '/api/battle_box/join_battle_room',
    method: 'POST',
    data
  })
}


// 盲盒对战-退出房间
export const sign_out_battle_room = (data) => {
  return request({
    url: '/api/battle_box/sign_out_battle_room',
    method: 'POST',
    data
  })
}


// 盲盒对战-对战中房间列表
export const get_battle_room_list = (data) => {
  return request({
    url: '/api/battle_box/get_battle_room_list',
    method: 'POST',
    data
  })
}


// 盲盒对战-对战结束房间列表
export const get_battle_room_record = (data) => {
  return request({
    url: '/api/battle_box/get_battle_room_record',
    method: 'POST',
    data
  })
}
// 盲盒对战-我的对战记录
export const get_my_battle_room_record = (data) => {
  return request({
    url: '/api/battle_box/get_my_battle_room_record',
    method: 'POST',
    data
  })
}

// 盲盒对战-对战详情
/* export const get_battle_room_detail = (data) => {
  return request({
    url: '/api/battle_box/get_battle_room_detail',
    method: 'POST',
    data
  })
} */

export function get_battle_room_detail(data) {
	return request({
	  url: '/api/battle_box/get_battle_room_detail',
	  method: 'POST',
	  data
	})
}


// 盲盒对战-玩法介绍
export const get_play_introduction = (data) => {
  return request({
    url: '/api/battle_box/get_play_introduction',
    method: 'POST',
    data
  })
}

// 创建同样的房间
export const RoomPrice = (data) => {
  return request({
    url: '/api/battle_box/copy_battle_room',
    method: 'POST',
    data
  })
}

