<!-- 开箱 -->
<template>
  <div class="home">
    <div class="">
      <BoxShowBodyOpen :boxData="boxData" :cdkBoxList="cdkBoxList" @getBoxDataList="getBoxDataList" />
      <BoxShowBodyIn :skinsList="boxData.skins" />
    </div>
  </div>
</template>

<script>
import BoxShowBodyOpen from './BoxShowBodyOpen.vue'
import BoxShowBodyIn from './BoxShowBodyIn.vue'
import {get_cdk_box_list, get_cdk_box_data} from "@/api/index/cdk";
export default {
  name: 'K5Home',
  components: {
    BoxShowBodyOpen,
    BoxShowBodyIn
  },
  data() {
    return {
      boxData: {},
      cdkBoxList: []
    }
  },
  created() {
		if (sessionStorage.getItem('n7_token')) {
			this.$store.dispatch('getPlayerApi')
		}
    this.getCdkBoxList()
  },
  methods: {
    async getCdkBoxList() {
      const res = await get_cdk_box_list({ type: 'get_cdk_box_list' })
      this.cdkBoxList = res.data
      //console.log(this.boxData)
    },
    async getBoxDataList(id) {
      const res = await get_cdk_box_data({ cdk_box_id:id })
      this.boxData = res.data
      //console.log(this.boxData)
    },
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.home-body {
  position: relative;
		//margin-top: 110px;
	}
</style>
