<!-- 个人中心 -->
<template>
  <div class="app">
    <div class="personalCenter">
      <div class="personalCenter-title">
        <div class="lineImg">
          <img src="@/assets/images/Personal-line.png" alt="" class="line" v-show="content == 'personalCenter'">
          <img src="@/assets/images/Personal.png" alt="" class="textTitle" v-show="content == 'personalCenter'">
          <img src="@/assets/images/knapsack_line.png" alt="" class="line1" v-show="content == 'MyBackpack'">
          <img src="@/assets/images/knapsack.png" alt="" class="textTitle" v-show="content == 'MyBackpack'">
          <img src="@/assets/images/journal-line.png" alt="" class="line2" v-show="content == 'MyLog'">
          <img src="@/assets/images/journal.png" alt="" class="textTitle" v-show="content == 'MyLog'">
          <img src="@/assets/images/extension-line.png" alt="" class="line3" v-show="content == 'MyPromotion'">
          <img src="@/assets/images/extension.png" alt="" class="textTitle" v-show="content == 'MyPromotion'">
        </div>
        <div
          class="personalUser-title"
          :style="VPointer()"
          @click="personalCenter"
          
        > 
          <img
            src="@/assets/images/personalCenter/my.png"
            alt=""
            v-show="content !== 'personalCenter'"
          />
          <img
            class="b"
            src="@/assets/images/personalCenter/my2.png"
            alt=""
            v-show="content === 'personalCenter'"
          />
        </div>
        <div class="MyBackpack-title" :style="VPointer()" @click="MyBackpack">
          <img
            src="@/assets/images/personalCenter/我的背包.png"
            alt=""
            v-show="content !== 'MyBackpack'"
          />
          <img
            class="b"
            src="@/assets/images/personalCenter/我的背包2.png"
            alt=""
            v-show="content === 'MyBackpack'"
          />
        </div>
        <div class="MyLog-title" :style="VPointer()" @click="MyLog">
          <img
            src="@/assets/images/personalCenter/我的日志.png"
            alt=""
            v-show="content !== 'MyLog'"
          />
          <img
            class="b"
            src="@/assets/images/personalCenter/我的日志2.png"
            alt=""
            v-show="content === 'MyLog'"
          />
        </div>
        <div class="MyPromotion-title" :style="VPointer()" @click="MyPromotion">
          <img
            src="@/assets/images/personalCenter/我的推广.png"
            alt=""
            v-show="content !== 'MyPromotion'"
          />
          <img
            class="b"
            src="@/assets/images/personalCenter/我的推广2.png"
            alt=""
            v-show="content === 'MyPromotion'"
          />
        </div>
        <div class="MyPromotion-title relative" :style="VPointer()" @click="message">
          <img
            src="@/assets/images/personalCenter/站内信息.png"
            alt=""
            v-show="content !== 'StationInformation'"
          />
          <img
            class="b"
            src="@/assets/images/personalCenter/站内信息2.png"
            alt=""
            v-show="content === 'StationInformation'"
          />
          <div class="nums" v-if="$store.state.userInfo.no_read_num>0">{{$store.state.userInfo.no_read_num}}</div>
        </div>
      </div>
      <div class="content">
        <img src="@/assets/images/personalCenter/left.png" alt="" />
        <div class="content-n">
          <PersonalUser
            v-show="content === 'personalCenter'"
            :content="content"
          />
          <MyBackpack v-show="content === 'MyBackpack'" :content="content" />
          <MyLog v-show="content === 'MyLog'" :content="content" />
          <MyPromotion v-show="content === 'MyPromotion'" :content="content" />
          <StationInformation
            v-show="content === 'StationInformation'"
            :content="content"
            @getDetail="getDetail"
          />
          <StationInformationDetail @pageBack="pageBack" :content="content" :id="StationInformationDetailId" v-show="content === 'StationInformationDetail'" />
        </div>
        <img src="@/assets/images/personalCenter/right.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import PersonalUser from './components/personalUser.vue'
import MyBackpack from './components/MyBackpack.vue'
import MyLog from './components/MyLog.vue'
import MyPromotion from './components/MyPromotion.vue'
import StationInformation from './components/stationInformation.vue'
import StationInformationDetail from './components/StationInformationDetail.vue'
export default {
  name: 'personalCenter',
  data() {
    return {
      content: 'personalCenter',
      StationInformationDetailId:''
    }
  },
  watch:{
    '$route'(){
       this.content=this.$route.query.name
    }
  },
  components: {
    PersonalUser,
    MyBackpack,
    MyLog,
    MyPromotion,
    StationInformation,
    StationInformationDetail,
  },
  beforeDestroy(){
    localStorage.removeItem('personKey')
  },
  created() {
		
    if(this.$route.query.name){
      this.$nextTick(()=>{
        this.content=this.$route.query.name
      })
    }else{
       if(localStorage.getItem('personKey')){
          this.$nextTick(()=>{
            this.content=localStorage.getItem('personKey')
          })
        }
    }
    
  },
  methods: {
    //切换站内信详情
    getDetail(e){
      this.content='StationInformationDetail'
      this.StationInformationDetailId=e
    },
    pageBack(){
      this.content = 'StationInformation'
      this.StationInformationDetailId=''
    },
    // 个人中心
    personalCenter() {
      this.content = 'personalCenter'
      localStorage.setItem('personKey','personalCenter')
    },
    // 我的背包
    MyBackpack() {
      this.content = 'MyBackpack'
      localStorage.setItem('personKey','MyBackpack')
    },
    // 我的日志
    MyLog() {
      this.content = 'MyLog'
      localStorage.setItem('personKey','MyLog')
    },
    // 我的推广
    MyPromotion() {
      this.content = 'MyPromotion'
      localStorage.setItem('personKey','MyPromotion')
    },
    // 站内信息
    message() {
      this.content = 'StationInformation'
      localStorage.setItem('personKey','StationInformation')
    },
  }
}
</script>
<style lang="scss" scoped>
.relative{
  position: relative;
}
.nums{
  position: absolute;
  top: 8px;
  right: 20px;
  width: 28px;
  height: 28px;
  background: #e55e58;
  border-radius: 50%;
  text-align: center;
  line-height: 28px;
  transform: scale(.7);

}
// 引入css类
// @import '';
.lineImg{
  width: 100px;
  height: 100px;
  position: absolute;
  top: -80px;
  // bottom: 0;
  left: 195px;
  .line{
    height: 120px;
    width: 180px;
  }
  .line1{
    height: 230px;
    width: 180px;
  }
  .line2{
    height: 340px;
    width: 180px;
  }
   .line3{
    height: 447px;
    width: 180px;
  }
  .textTitle{
    position: absolute;
    top: 20px;
    right: -200px;
    width: 115px;

  }
}
.personalCenter {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  padding: 60px 20px 20px 50px;
  .personalCenter-title {
    margin-top: 40px;
    position: relative;
    .personalUser-title {
      
      margin-bottom: 50px;
      &:last-child {
        margin: 0;
      }
      img {
        width: 200px;
      }
    }
    .MyBackpack-title {
      @extend .personalUser-title;
      img {
        width: 200px;
      }
    }
    .MyLog-title {
      @extend .personalUser-title;
      img {
        width: 200px;
      }
    }
    .MyPromotion-title {
      @extend .personalUser-title;
      img {
        width: 200px;
      }
    }
  }
  .content {
    display: flex;
    margin-left: 70px;
    img {
      height: 610px;
    }
    &-n {
      width: 1050px;
      height: 100%;
    }
  }
}
</style>
