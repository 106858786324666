<template>
  <div class="help_box">
    <ul class="HelpList">
      <li
        @click="tab_help(1)"
        :class="tabId == 1 ? 'bgc' : ''"
        :style="VPointer()"
      >
        关于我们
      </li>
      <li
        @click="tab_help(2)"
        :class="tabId == 2 ? 'bgc' : ''"
        :style="VPointer()"
      >
        隐私政策
      </li>
      <li
        @click="tab_help(3)"
        :class="tabId == 3 ? 'bgc' : ''"
        :style="VPointer()"
      >
        用户协议
      </li>
      <li
        @click="tab_help(4)"
        :class="tabId == 4 ? 'bgc' : ''"
        :style="VPointer()"
      >
        常见问题
      </li>
      <li
        @click="tab_help(5)"
        :class="tabId == 5 ? 'bgc' : ''"
        :style="VPointer()"
      >
        相关教程
      </li>
      <li
        @click="tab_help(6)"
        :class="tabId == 6 ? 'bgc' : ''"
        :style="VPointer()"
      >
        合规声明
      </li>
      <li
        @click="tab_help(7)"
        :class="tabId == 7 ? 'bgc' : ''"
        :style="VPointer()"
      >
        联系我们
      </li>
    </ul>

    <div class="bigBox">
      <div class="list">
        <div class="inline">
          <img src="@/assets/images/顶部菜单栏分割线.png" alt="" />
        </div>
        <!-- 关于我们 -->
        <div v-show="tabId == 1" class="list_item" :style="VPointer()">
          <div
            class="triangle"
            :class="0 == listId_one ? 'triangle_color' : ''"
          ></div>
          {{ get_about_usObj.title }}
        </div>
        <!-- 隐私政策 -->
        <div v-show="tabId == 2" class="list_item" :style="VPointer()">
          <div
            class="triangle"
            :class="0 == listId_two ? 'triangle_color' : ''"
          ></div>
          {{ get_privacy_olicyObj.title }}
        </div>
        <!-- 用户协议 -->
        <div v-show="tabId == 3" class="list_item" :style="VPointer()">
          <div
            class="triangle"
            :class="0 == listId_two ? 'triangle_color' : ''"
          ></div>
          {{ get_terms_of_useObj.title }}
        </div>
        <!-- 常见问题 -->
        <ul v-show="tabId == 4">
          <li
            :style="VPointer()"
            v-for="(item, index) in getproblemArr"
            :key="index"
            @click="problem_list(index)"
            :class="index == listId_three ? 'bgc' : ''"
          >
            <div
              class="triangle"
              :class="index == listId_three ? 'triangle_color' : ''"
            ></div>
            {{ item.title }}
          </li>
        </ul>
        <!-- 相关教程 -->
        <ul v-show="tabId == 5">
          <li
            :style="VPointer()"
            v-for="(item, index) in get_tutorialsArr"
            :key="index"
            @click="tutorials_list(index)"
            :class="index == listId_four ? 'bgc' : ''"
          >
            <div
              class="triangle"
              :class="index == listId_four ? 'triangle_color' : ''"
            ></div>
            {{ item.title }}
          </li>
        </ul>
        <!-- 合规声明 -->
        <ul v-show="tabId == 6">
          <li
            :style="VPointer()"
            v-for="(item, index) in complianceStatement"
            :key="index"
            @click="tutorials_list(index)"
            :class="index == listId_four ? 'bgc' : ''"
          >
            <div
              class="triangle"
              :class="index == listId_four ? 'triangle_color' : ''"
            ></div>
            {{ item.title }}
          </li>
        </ul>
        <!-- 联系我们 -->
        <ul v-show="tabId == 7">
          <li :style="VPointer()" class="bgc">
            <div class="triangle triangle_color"></div>
            联系我们
          </li>
        </ul>
      </div>
      <!-- 关于我们 -->
      <div
        class="cente get_about_us"
        v-show="tabId == 1"
        v-html="get_about_usObj.content"
      ></div>
      <!-- 隐私政策 -->
      <div
        class="cente get_privacy_olicy"
        v-show="tabId == 2"
        v-html="get_privacy_olicyObj.content"
      ></div>
      <!-- 用户协议 -->
      <div
        class="cente get_terms_of_use"
        v-show="tabId == 3"
        v-html="get_terms_of_useObj.content"
      ></div>
      <!-- 常见问题 -->
      <div class="cente get_problem" v-show="tabId == 4">
        <div
          v-for="(item, index) in getproblemArr"
          :key="index"
          v-show="index == listId_three"
        >
          <div class="content" v-html="item.content"></div>
        </div>
      </div>
      <!-- 相关教程 -->
      <div class="cente get_tutorials" v-show="tabId == 5">
        <div
          v-for="(item, index) in get_tutorialsArr"
          :key="index"
          v-show="index == listId_four"
        >
          <div class="content" v-html="item.content"></div>
        </div>
      </div>
      <!-- 合规声明 -->
      <div class="cente get_problem" v-show="tabId == 6">
        <div
          v-for="(item, index) in complianceStatement"
          :key="index"
          v-show="index == listId_five"
        >
          <div class="content" v-html="item.content"></div>
        </div>
      </div>
      <!-- 联系我们 -->
      <div v-show="tabId == 7">
        <div class="contact">
          <div class="contactbox">
            <div class="imgbox">
              <img :src="contact.qq_group_chat" alt="" srcset="" />
            </div>
            <div class="text">QQ群聊</div>
          </div>
          <div class="contactbox">
            <div class="imgbox">
              <img :src="contact.wechat_qr_code" alt="" srcset="" />
            </div>
            <div class="text">微信公众号</div>
          </div>
          <div class="contactbox">
            <div class="imgbox">
              <img :src="contact.tiktok_qr_code" alt="" srcset="" />
            </div>
            <div class="text">抖音</div>
          </div>
        </div>
        <div class="contact">
          <div class="contactbox">
            <div class="text">QQ号码：{{contact.qq_group_chat_number}}</div>
          </div>
          <div class="contactbox">
            <div class="text">客服邮箱：{{contact.email_group_chat}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  get_about_us,
  get_privacy_olicy,
  get_terms_of_use,
  get_problem,
  get_tutorials,
  get_compliance,
  get_contact_us
} from '@/api/index/HelpCenter'
export default {
  data() {
    return {
      tabId: 1,
      get_about_usObj: {},
      get_privacy_olicyObj: {},
      get_terms_of_useObj: {},
      getproblemArr: [],
      get_tutorialsArr: [],
      listId_one: 0,
      listId_two: 0,
      listId_three: 0,
      listId_four: 0,
      listId_five: 0,
      complianceStatement: [],
      contact: {}
    }
  },
  watch:{
    '$route'(){
    this.tabId = this.$route.query.id
    }
  },
  methods: {
    tutorials_list(index) {
      this.listId_four = index
    },
    problem_list(index) {
      this.listId_three = index
    },
    notice() {
      // 关于我们
      get_about_us().then((res) => {
        this.get_about_usObj = res.data
      })
      // 隐私政策
      get_privacy_olicy().then((res) => {
        this.get_privacy_olicyObj = res.data
      })
      // 用户协议
      get_terms_of_use().then((res) => {
        this.get_terms_of_useObj = res.data
      })
      // 常见问题
      get_problem().then((res) => {
        this.getproblemArr = res.data
      })
      // 相关教程
      get_tutorials().then((res) => {
        this.get_tutorialsArr = res.data
      })
      // 合规声明
      get_compliance().then((res) => {
        this.complianceStatement = res.data
      })
      // 联系我们
      get_contact_us().then((res) => {
        this.contact = res.data
      })
    },

    // tab切换
    tab_help(e) {
      this.tabId = e
    }
  },
  created() {
		if (sessionStorage.getItem('n7_token')) {
			this.$store.dispatch('getPlayerApi')
		}
    this.tabId = this.$route.query.id
    this.notice()
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/Helpcenter.scss';
.contact {
  width: 800px;

  margin-top: 50px;
  padding-top: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.contactbox {
  text-align: center;
  .imgbox {
    padding: 5.5px;
    border: 2px solid #7debec;
    border-radius: 10px;
  }
  img {
    width: 140px;
    height: 140px;
    border-radius: 10px;
    padding: 10px;
    display: inline-block;
    background: #fff;
  }
  .text {
    color: #fff;
    margin-top: 10px;
  }
}
</style>
