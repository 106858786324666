<!-- 我的推广 -->
<template>
  <div class="MyPromotion">
    <!-- 我的推广 -->
    <div class="back management">
      <div class="management-head">我的推广</div>
      <div class="managementC">
        <div class="cell">
          <div class="lefttitle">我的邀请码：</div>
          <div style=" position: relative;flex:1">
              <input
                type="text"
                placeholder="请输入"
                v-model="myPayoff.invite_code"
              />
            <div class="btnbox">
                <div  v-clipboard:copy="myPayoff.invite_code"
              v-clipboard:success="copy"
              v-clipboard:error="onError" class="confirm" :style="VPointer()">复制</div>
              <div   :style="VPointer()"  class="confirm">修改</div>
            </div>
          </div>
        </div>
        <div class="bor"></div>
        <div class="postbox">
          <div class="lefttitle">我的推广链接：</div>
          <div style=" position: relative;flex:1">
             <input type="text" v-model="invite_code_url " placeholder="请输入" />
              <div class="btnbox">
                <div class="confirm" v-clipboard:copy="url + '?code=' + myPayoff.invite_code" v-clipboard:success="copy"
                  v-clipboard:error="onError"  :style="VPointer()">复制</div>
                </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 我的收益 -->
    <div class="backIncome MyIncome">
      <div class="income-head">我的推广</div>
      <div class="incomeC">
        <div>推广人数 <br />{{ myPayoff.direct_push_num }}</div>
        <div>邀请用户累计充值 <br />{{ myPayoff.direct_push_plat_price }}</div>
        <div>抽成比例 <br />{{ myPayoff.commission_billie }}%</div>
        <div>累计收益 <br />{{ myPayoff.commission }}</div>
        <div>可提取收益 <br />{{ myPayoff.no_commission }}</div>
        <div :style="VPointer()" @click="all_retrieve_commissionApi">
          全部提取
        </div>
      </div>
    </div>
    <div class="detail sticky-table">
      <table border="1" cellspacing="0" width="100%">
        <tr class="formH">
          <th>时 &nbsp; 间</th>
          <th>佣 &nbsp; 金</th>
          <th>状 &nbsp; 态</th>
        </tr>
        <tr class="formB" v-for="item in rowsList" :key="item.id">
          <td>{{ item.update_time }}</td>
          <td>{{ item.parent_player_commission }}</td>
          <td>
            {{ item.parent_player_commission_status === 1 ? '正常' : '已取出' }}
          </td>
        </tr>
      </table>
      <el-pagination
      class="pagination"
      background
      layout="prev, pager, next"
      @current-change="handleCurrentChange"
      :page-size="page_size"
      :total="total_rows"
      prev-text="上一页"
      next-text="下一页"
    >
    </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  rechargeGet_list,
  retrieve_commission,
  all_retrieve_commission,
  get_extension_info
} from '@/api/index/personalCenter'
export default {
  name: 'MyPromotion',
  data() {
    return {
      page: 1,
      url:'',
      total_rows: null,
      invite_code_url:"",
      page_size: 12,
      rowsList: [],
      recharge_commission_id: '', // 单一提取
      myPayoff: {} // 我的收益
    }
  },
  props: {
    content: String
  },
  watch: {
    content() {
      if (this.content === 'MyPromotion') {
		  if (sessionStorage.getItem('n7_token')) {
		  	this.$store.dispatch('getPlayerApi')
		  }
        this.rechargeGet_listApi()
        this.get_extension_infoApi()
      }
    }
  },
  created() {
     this.url=window.location.origin+'/#'
  },


  methods: {
    copy() {
      this.$message.success('复制成功')
    },
    onError() {
      this.$message.error('复制失败')
    },
     handleCurrentChange(val) {
      this.page = val
      this.rechargeGet_listApi()
    },
    // 推广分红-列表
    rechargeGet_listApi() {
      rechargeGet_list({
        page: this.page,
        page_size: this.page_size
      }).then((res) => {
        this.rowsList = res.data.rows
        this.total_rows = res.data.total_rows
      })
    },
    // 推广分红-单一提取
    retrieve_commissionApi() {
      retrieve_commission({
        recharge_commission_id: this.recharge_commission_id
      }).then((res) => {
        //console.log(res)
        this.rowsList = res.data.rows
        //console.log(this.rowsList)
      })
    },
    // 推广分红-全部提取
    all_retrieve_commissionApi() {
      all_retrieve_commission().then((res) => {
        //console.log(res)
        if (res.status === 1) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.IDredact = false
		  this.$store.dispatch('getPlayerApi')
          this.get_player_infoApi()
		  this.get_extension_infoApi()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 推广分红-我的收益
    get_extension_infoApi() {
      get_extension_info({
        page: this.page,
        page_size: this.page_size
      }).then((res) => {
        //console.log(res)
        this.myPayoff = res.data
        this.invite_code_url= this.url+ '/?code=' +  res.data.invite_code
        //console.log(this.rowsList)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.pagination{
  margin-top: 20px;
}
::v-deep .el-pagination.is-background .btn-next{
   background-color: #1ef0ed;
    height: 30px;
    width: 80px;
    border-radius: 5px;
    margin: 0 10px;
    color: #000;
    font-size: 13px;
  cursor: url('@/assets/images/commom/hand-pointer (1).png'), auto;

}
::v-deep .el-pagination.is-background .btn-next:disabled{
color: #9e9e9e;
cursor: not-allowed !important;

}
::v-deep .el-pagination.is-background .btn-prev:disabled{
color: #9e9e9e;
cursor: not-allowed !important;
}
::v-deep .el-pager .more::before {
  cursor: url('@/assets/images/commom/hand-pointer (1).png'), auto;
}
::v-deep .el-pagination.is-background .btn-prev{
   background-color: #1ef0ed;
    height: 30px;
    font-size: 13px;
    width: 80px;
    border-radius: 5px;
    margin: 0 10px;
    color: #000;
  cursor: url('@/assets/images/commom/hand-pointer (1).png'), auto;

}
.lefttitle{
  width: 150px;
}
// 引入css类
// @import '';
.MyPromotion {
  margin-top: 20px;
  .back {
    margin: 0 auto;
    background: url('@/assets/images/personalCenter/1.png') no-repeat;
    background-size: 100% 100%;
    width: 1000px;
    height: 100%;
    padding: 10px 0;
    margin-top: 10px;
  }
  .management {
    display: flex;
    width: 1010px;
    margin: 0 0 0 10px;
  padding-right: 20px;
    align-items: center;
    .management-head {
      width: 120px;
      margin-right: 10px;
      color: $theme;
    }
    .managementC {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex:1;
      .cell {
        display: flex;
        margin-bottom: 5px;
        width: 100%;
        .btnbox{
          display: flex;
          position: absolute;
          top: 0;right: 0;
        }
        input {
          font-size: 16px;
          width:100%;
          height: 40px;
          background: none;
          outline: none;
          border: none;
          border: 2px $theme solid;
          padding: 0 5px;
          color: #fff;
        }
        .confirm {
          border: 2px $theme solid;
          height: 40px;
          line-height: 40px;
          min-width: 90px;
        }
        .confirm:hover{
          background: #1ef0ed;
          color: #000;
        }
      }
      .bor {
        width: 100%;
        height: 2px;
        background: -webkit-linear-gradient(
          left,
          #000 -4%,
          $theme 50%,
          #000 100%
        );
      }
      .postbox {
        @extend .cell;
        display: flex;
        margin-top: 5px;
        margin-bottom: 0;
      }
    }
  }
  .backIncome {
    margin: 0 0 0 10px;
    background: url('@/assets/images/personalCenter/3.png') no-repeat;
    background-size: 100% 100%;
    width: 1010px;
    height: 100%;
    padding: 10px 0;
    margin-top: 10px;
  }
  .MyIncome {
    display: flex;
    align-items: center;
    .income-head {
      width: 120px;
      // margin-right: 10px;
      color: $theme;
    }
    .incomeC {
      display: flex;
      div:nth-child(1) {
        width: 85px;
      }
      div:nth-child(2) {
        width: 145px;
      }
      div:nth-child(3) {
        width: 90px;
      }
      div:nth-child(4) {
        width: 100px;
      }
      div:nth-child(5) {
        width: 100px;
      }
      div:nth-child(6) {
        padding: 1px 20px;
        height: 27px;
        border: 1px $theme solid;
        border-radius: 50px;
        margin: 5px 0 0 10px;
      }
    }
  }
}
// 表格
.detail {
  margin: 10px;
  margin-top: 9px;
  padding-right: 9px;
  overflow-y: scroll;
  height: 550px;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: none;
    border-radius: 10px;
  }
  /* scroll轨道背景 */
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: rgba(216, 216, 216, 0);
  }
  /* 滚动条中能上下移动的小块 */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
    background-color: rgb(119, 117, 117);
  }
  .formH {
    th {
      height: 50px;
      border-top: 1px #808080 solid;
    }
  }
  .formB {
    td {
      height: 30px;
      &:nth-child(5) {
        color: $theme;
      }
    }
  }
}

.sticky-table th {
  position: sticky;
  top: 0; /* 首行永远固定在头部  */
  //background-color: $hoverText; /*设置表头背景色*/
}

.sticky-table th:first-child {
  z-index: 2; /*表头的首列要在上面*/
}
</style>
