<!-- HomeBodyShow -->
<template>
  <div class="show" ref="tableoOut">
    <BoxShowBodyOpenTake />
    <!-- <div class="show-board" v-for="item in Banner" :key="item.id">
      <img :src="item.thumb" alt="" />
    </div> -->
    <div v-if="Banner.length">
      <swiper :options="swiperOption" ref="mySwiper" class="show-board">
        <swiper-slide v-for="(item, index) in Banner" :key="index">
          <img :src="item.thumb" alt="" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import BoxShowBodyOpenTake from './BoxShowBodyOpenTake.vue'
import { get_banner } from '@/api/index/home'

import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
export default {
  name: 'HomeBodyShow',
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        }
      },
      tableHei: 'auto',
      timer: null,
      size: 0,
      Banner: []
    }
  },
  components: {
    BoxShowBodyOpenTake,
    swiper,
    swiperSlide
  },
  created() {
    this.getBannerApi()
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    }
  },
  methods: {
    getBannerApi() {
      get_banner().then((res) => {
        this.Banner = res.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */

.swiper-container {
  width: 1000px;
  margin: 0 auto;
}

.show {
  overflow: hidden;
  &-ul {
    display: flex;
    justify-content: space-around;
    //轮播动画
    animation: move 10s linear infinite;
    &-li {
      margin: 0 20px;
      width: 180px;
      position: relative;

      &-frame {
        width: 180px;
      }
      &-main {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &-name {
        color: #b5dade;
        @extend .show-ul-li-main;
        transform: translate(-46%, 96%);
        font-weight: 700;
        font-size: 14px;
        width: 170px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    // 旋转
    &-li:hover {
      transition: all 1.5s linear;
      // opacity: 0;
      transform: rotateY(360deg);
    }
  }
  &-ul:hover {
    animation-play-state: paused;
  }
  &-board {
    img {
      margin-top: 50px;
      width: 1000px;
      height: 200px;
    }
  }
}

@keyframes move {
  0% {
    right: 0;
  }
  100% {
    right: -50%;
    /* margin-left: -100px; */
    transform: translateX(-42.5%);
  }
}
</style>
