<template>
  <div id="transverse" ref="transverse" :class="{ active: open_key }">
    <div class="animationx_back">
      <div class="animationx_main">
        <div class="animationx_box" ref="singleopen_move" :class="{ transition: Transition }" :style="'transform: translateX(-' + TranslateX + 'px)'" @transitionend="Transitionend">
          <div class="animationx_item" :style="item.rarity_thumb | OpenBack" v-for="(item, index) in volutionList" :key="index" ref="animationx_item">
            <img :src="item.image_url" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'transverse',
  props: {
    goodsList: {
      type: Array
    },
    prize_data: {
      type: Object
    }
  },
  data() {
    return {
      open_key: false, //显示
      TranslateX: 0, //偏移值
      volutionList: [], //处理后数组
      Transition: false, //过渡效果
      process: new Audio('https://oss.n7buff.com/web/audio/roll9.mp3') //过程声音
    }
  },

  mounted() {
    this.prohibit()
  },

  methods: {
    //显示且确定偏移距离
    show() {
      this.$nextTick(() => {
        this.Transition = true
        let Width = this.$refs.singleopen_move.clientWidth
        let item_Width = Width / this.volutionList.length
		//console.log("item_Width" + item_Width)
        let min = item_Width / 10
        let max = item_Width - item_Width / 10
        this.TranslateX = item_Width * 33 - item_Width * 2 - item_Width / 2 //+ parseInt(Math.random() * (max - min + 1) + min, 10)
        //正中间              //最左边                       //随机值
        this.process.play()
        this.open_key = true
      })
    },

    Transitionend() {
      //console.log('动画结束')
      this.end()
    },

    //拼接并打乱数组
    Arraysplicing(data, str) {
      this.volutionList = []
      let expect = 34 - this.goodsList.length

      if (expect >= 0) {
        this.I_num = Math.floor(expect / this.goodsList.length) + 2
      } else {
        this.I_num = 1
      }

      let A_Arr = []
      for (let i = 0; i < this.I_num; i++) {
        A_Arr = A_Arr.concat(this.goodsList)
      }

      //打乱
      function randSort(A_Arr) {
        for (let i = 0, len = A_Arr.length; i < len; i++) {
          let rand = parseInt(Math.random() * len)
          let temp = A_Arr[rand]
          A_Arr[rand] = A_Arr[i]
          A_Arr[i] = temp
        }
        return A_Arr
      }
      this.volutionList = randSort(A_Arr)

      // console.log(data, str)

      this.$emit('finishCallback', data, str)
    },

    //处理中奖
    Determine() {
      // console.log('奖品打印')
      // console.log(this.prize_data)
      this.volutionList[32] = this.prize_data.list[0]
      this.$forceUpdate()
      this.show()
    },

    //动画结束
    end() {
		//this.$emit('end')
     this.open_key = false
      this.$emit('end')
      setTimeout(() => {
        this.Transition = false
        this.TranslateX = 0
      }, 300)
    },

    //弹出后禁止屏幕滑动
    prohibit() {
      this.$refs.transverse.addEventListener('touchmove', function(event) {
        event.preventDefault()
      })
    }
  },
  watch: {
    prize_data(v) {
      if (v.show == 'transverse') this.Determine()
    }
  }
}
</script>

<style lang="scss" scoped>
#transverse {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  background-color: rgba($color: #000000, $alpha: 0.8);
  opacity: 0;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
  box-sizing: border-box;

  &.active {
    z-index: 1000;
    opacity: 1;
  }

  .animationx_back {
    width: 1500px;
    height: 480px;
    background: url(https://oss.n7buff.com/web/images/showImages/bak.png) no-repeat center;
    background-size: contain;
    position: relative;
    padding: 0 25px;
    box-sizing: border-box;

    &::after {
      display: block;
      content: '';
      width: 28px;
      height: 372px;
      background: url('@/assets/images/showImages/line_x.png') no-repeat center;
      background-size: contain;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 56px;
    }

    .animationx_main {
      width: 1400px;
      height: 280px;
      margin-top: 100px;
      box-sizing: border-box;
      overflow: hidden;

      .animationx_box {
        white-space: nowrap;
        transition: none;
        display: inline-block;

        .animationx_item {
          width: 380px;
          height: 280px;
          background: url('@/assets/images/showImages/line_x.png') no-repeat center;
          background-size: contain;
          display: inline-block;
          position: relative;
          img {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            max-width: 80%;
            max-height: 80%;
          }
        }

        &.transition {
          transition: 9s cubic-bezier(0.1, 0.3, 0.15, 1);
        }
      }
    }
  }
}
</style>
