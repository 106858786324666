// 手机号
export function isMobile(path) {
  return /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/
    .test(path)
}
// 身份证号
export function isAuthentication(path) {
  return /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/
    .test(path)
}

// 禁词
export function isSensitiveWord(path) {
  return /^(?!.*(?:卡|马扁|戈壁|装13|绿茶婊|日了狗|丢你老母|淫|点击获取|免费领取|JB|经痛|性|乳|信用卡|生殖器|傻狗|Z网|d2|备案|110|死全家|暴毙|律师函|傻b|习|玖伍|鸡巴|sm|nmsl|孝子|孤儿|jj|导管|烧杯|烧鸡|伞兵|打款|多少钱|打钱|转账|汇款|交易|杀|男人|站|sb|开导|尸|病毒 |新冠|虐待|疫情|恒河|浮尸|hellokitty|内裤|jiji|国家 |最|报警|金牌|顶级|推广号|死妈|推广|习近平|傻逼|傻屌|主席|国母|法轮功|亏|官方|贷款|毒品|海洛因|暴力|qq|屄|屌|淫|抖音|虎牙|你妈逼|操你妈|爹|爷|爸|95|刷卡|九|五|赌|骗|⑨|妈|娘|母亲|父亲|张越|叫爸爸|咪咪|屌爆了|太阳|周恩来|赌博|毛泽东|装逼|蛋疼|白莲花|刷|逼|吃翔|点击|秒杀|疯抢|退钱|松|下面|鸡|诈骗|欺诈|报案|立案|退钱|截|贷|死|日你|草你|肏|操你|艹|我草|我操|支付宝|举报|搜|9|5|党|主播|推广|66skin|86skin|87skin|86|85skin|egg|蛋网|dog|投诉|市监局|违法|滨州|消费者|死全家|播|全家|工作人员|du|台|中华人民共和国|vllc|微信|vx|zfb|屁眼|腚眼|党|政)).*/
    .test(path)
}


