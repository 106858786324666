import Cookies from 'js-cookie'

const TokenKey = 'n7_token'
const TOKEN_LOGOUT_TIME = '_TOKEN_LOGOUT_TIME'
const USER_INFO = 'USER_INFO'
const USER_VOICE = 'USER_VOICE'
// 取token
export function getToken() {
	return sessionStorage.getItem(TokenKey)
}
// 存token
export function setToken(token) {
	return sessionStorage.setItem(TokenKey, token)
}

export function removeToken() {
	return Cookies.remove(TokenKey)
}
export function setTokenTimeout() {
	return window.sessionStorage.setItem(TOKEN_LOGOUT_TIME, Date.now())
}
export function getTokenTimeout() {
	return parseInt(window.sessionStorage.getItem(TOKEN_LOGOUT_TIME))
}
// 存用户信息
export function setUserInfo(UserInfo) {
	return sessionStorage.setItem(USER_INFO, JSON.stringify(UserInfo))
}
// 取用户信息
export function getUserInfo() {
	if (!sessionStorage.getItem(USER_INFO)) {
		console.log('用户信息', sessionStorage.getItem(USER_INFO))
		return JSON.parse(sessionStorage.getItem(USER_INFO)) || {}
	} else {
		return {}
	}

}

// 存用户声音
export function setUserVoice(Audio) {
	return sessionStorage.setItem(USER_VOICE, Audio)
}
// 取用户声音
export function getUserVoice() {
	return sessionStorage.getItem(USER_VOICE) || true
}
