<template>
  <div :class="`headPortrait-${classNumber}`">
    <img
      :src="userModel.player_head_img"
      class="headPortrait-round"
      :class="{ active: userModel.iconUrl == null }"
    />
    <img
      :src="userModel.iconUrl"
      class="headPortraitAround"
      v-show="userModel.iconUrl != null"
    />
    <!-- <img
      src="@/assets/images/celebration/goldaround.png"
      class="headPortraitAround"
    /> -->
  </div>
</template>

<script>
// import CommenHeadPortrait from "@/views/User/components/CommenHeadPortrait.vue";
// <CommenHeadPortrait classNumber="small" :userModel="UserData.userIcon" />
export default {
  props: {
    classNumber: {
      type: String,
      default: 'normal'
    },
    userModel: {}
  },
  mounted() {
    // console.log(this.userModel, "5415151515");
  }
  // watch: {
  //   userModel(v) {
  //     console.log(v);
  //   },
  // },
}
</script>
<style lang="scss">
* {
  box-sizing: border-box;
}

.headPortrait-round {
  &.active {
    border: 1px solid #1ef0ed;
    border-radius: 50%;
  }
}
.headPortrait-big {
  width: 100px;
  height: 100px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .headPortrait {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .headPortraitAround {
    display: block;
    width: 86px;
    height: 86px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    border: 0px solid transparent;
    outline: none;
  }
}
.headPortrait-normal {
  width: 40px;
  height: 40px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .headPortrait {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .headPortraitAround {
    display: block;
    width: 68px;
    height: 68px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    border: 0px solid transparent;
    outline: none;
  }
}
.headPortrait-small {
  width: 22px;
  height: 22px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .headPortrait {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .headPortraitAround {
    display: block;
    width: 36px;
    height: 36px;
    position: absolute;
    top: -8px;
    left: -8px;
    border: 0px solid transparent;
    outline: none;
  }
}
//@import "@/assets/media/media.scss";
</style>
