<template>
	<div id="portrait" :class="{'active' : active}">
		<div class="Mask" style="transition: .3s;height: 100%;" :style="'transform: scale(' + Scale + ');'">
			<div class="animation_container">
				<div class="animation_back">
					<!-- 标题盒子 -->
					<div class="oneOn">
						<p class="get" >{{ boxDatas.name }}</p>
					</div>
					<div class="animation_out" ref="portrait">
						<div class="left" v-if="volutionList.length == 1">
						  <img src="@/assets/images/BoxShowPopup/left.png" alt="" />
						</div>
						<div class="animation_box" v-for="(item,index) in volutionList" :key="index"
							:style="'transform: translateY(' + translateY+ 'px);'" ref="sound" @transitionend="Transitionend">
							<div class="animation_item" v-for="(item,index) in volutionList[index]">
								<div class="back" :style="item.rarity_thumb | OpenBack"></div>
								<img :src="item.image_url" :alt="item.item_name">
							</div>
						</div>
						<div class="right" v-if="volutionList.length == 1">
						  <img src="@/assets/images/BoxShowPopup/right.png" alt="" />
						</div>
					</div>

					<div class="animationy_mains">
						<div class="animationy_boxs" v-for="(item,index) in volutionList" :key="index">
							<img  src="https://oss.n7buff.com/web/images/BoxShowPopup/box-bottom-deng.png" alt=""  />
						</div>
					</div>
				</div>

			</div>

		</div>
	</div>
</template>

<script>
	export default {
		name: 'portrait',
		props: {
			boxDatas:{
				//type: Array
			},
			goodsList: { //箱内物品
				type: Array
			},

			prize_data: { //奖品信息
				//type: Array
			},

			showSounds: {
				type: Boolean
			}
		},

		data() {
			return {
				Num: 5, //开启数量
				Height:0,
				translateY:0, //偏移值
				Scale: 0, //缩放值
				processY: new Audio('https://oss.n7buff.com/web/audio/roll9.mp3'),//过程声音
				volutionList: [], //滚动数据
				I_num: 0,
				active: false,
				soundStyleArr: [
					'animation_box soundone',
					'animation_box soundtwo',
					'animation_box soundthree',
					'animation_box soundfour',
					'animation_box soundfive',
					'animation_box soundsix',
					'animation_box soundseven',
					'animation_box soundeight',
					'animation_box soundnine',
					'animation_box soundten'
				],
				Transitionend_arr: []
			}
		},

		mounted() {
			 this.Height = this.$refs.portrait.clientHeight
		},



		methods: {
			open(num) {
				//console.log("prize_data..." + this.prize_data)
				this.translateY = -(this.Height * 33)
				this.Num = num
				this.Arraysplicing()
			},

			//拼接并打乱数组
			Arraysplicing() {
				this.volutionList = []

				let expect = 34 - this.goodsList.length
				if (expect >= 0) {
					this.I_num = (Math.floor(expect / this.goodsList.length) + 2)
				} else {
					this.I_num = 1
				}


				for (let k = 0; k < this.Num; k++) {
					let A_Arr = []


					for (let i = 0; i < this.I_num; i++) {
						A_Arr = A_Arr.concat(this.goodsList)
					}

					//打乱
					function randSort(A_Arr) {
						for (let i = 0, len = A_Arr.length; i < len; i++) {
							let rand = parseInt(Math.random() * len)


							let temp = A_Arr[rand]
							A_Arr[rand] = A_Arr[i]
							A_Arr[i] = temp


						}
						return A_Arr
					}
					//插入
					this.volutionList.push(randSort(A_Arr))
				}
        console.log(this.volutionList)
				this.Determine()
			},

			//处理中奖
			Determine() {
				this.$nextTick(() => {
					for (let k = 0; k < this.volutionList.length; k++) {
						this.volutionList[k][0] = this.prize_data[k]
						this.$refs.sound[k].className = this.soundStyleArr[Math.floor(Math.random() * (9 - 0 + 1) +
							0)]
					}
					this.$forceUpdate()
					this.$nextTick(() => {
						this.start()
					})
				})
			},

			Transitionend(){

				this.Transitionend_arr.push(true)

				if (this.Transitionend_arr.length == this.$refs.sound.length) {
					setTimeout(()=>{
						this.close()
					},300)

				}
			},

			start() {
				if (this.$route.name == 'open') {
					this.$emit('Reset')
				}
				this.active = true
				setTimeout(() => {

					this.$nextTick(() => {
						this.Scale = 1
						this.translateY = 0
						if (this.showSounds) {
							this.processY.play()
						}
					})


				}, 20)
			},

			close() {
				this.$emit('closeY', true)
				this.active = false
					this.$emit('end')
					setTimeout(() => {
						// console.log('调用')
						this.$nextTick(() => {
							for (let i = 0; i < this.$refs.sound.length; i++) {
								this.$refs.sound[i].className = 'animation_box active'
							}
							this.translateY = -(this.Height * 33)
							this.Transitionend_arr = []
						})
					}, 10)
			}
		},
	}
</script>

<style lang="scss" scoped>
	#portrait {
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: -1;
		opacity: 0;
		background: rgba($color: #000000, $alpha: .8);
		transform: .3s;

		&.active {
			z-index: 1000;
			opacity: 1;
		}

		@keyframes Shaking {
			0% {
				transform: rotate(-5deg);
			}

			100% {
				transform: rotate(5deg);
			}
		}

		.animation_container {
			height: 100%;
			// padding-bottom: 100px;
			.box_pic {
				display: flex;
				justify-content: center;

				img {
					width: 300px;
					// animation:  Shaking .15s infinite;
				}
			}

			.animation_back {
				width: 1400px;
				height: 100%;
				background: url('https://oss.n7buff.com/web/images/BoxShowPopup/background.png') no-repeat center;
				//background-size: contain;
				background-size: 1200px;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;

				&::after {
					display: block;
					content: '';
					width: 1200px;
					height: 23px;
					position: absolute;
					top: 50%;
					left: 8%;
					transform: translateY(-50%) translateX(-6px);
					background: url('@/assets/images/showImages/line_y.png') no-repeat center;
					background-size: contain;
				}
			.oneOn {
				width: 1400px;
				margin-top: -600px;
				position: absolute;
				.get {
				  width: 1400px;
				  position: absolute;
				  z-index: 999;
				  font-size: 0.25rem;
				  font-weight: 700;
				  letter-spacing:0.1rem
				}
			}
				.animation_out {
					width: 930px;
					height: 186px;
					overflow: hidden;
					transform: translateX(-2px);
					display: flex;
					.left {
					  margin-top: 63px;
					  img {
						width: 300px;
					  }
					}
					.right {
					  @extend .left;
					}
					.animation_box {
						flex: 1;
						transition: 9s cubic-bezier(0.2, 0.29, 0.15, .91);

						&.soundone {
							transition: 9s cubic-bezier(0.2, 0.29, 0.15, .91);
						}

						&.soundtwo {
							transition: 9s cubic-bezier(0.2, 0.3, 0.14, .92);
						}

						&.soundthree {
							transition: 9s cubic-bezier(0.2, 0.31, 0.15, 1);
						}

						&.soundfour {
							transition: 9s cubic-bezier(0.2, 0.32, 0.14, .89);
						}

						&.soundfive {
							transition: 9s cubic-bezier(0.2, 0.33, 0.15, .9);
						}

						&.soundsix {
							transition: 9s cubic-bezier(0.2, 0.32, 0.14, .98);
						}

						&.soundseven {
							transition: 9s cubic-bezier(0.2, 0.31, 0.15, .95);
						}

						&.soundeight {
							transition: 9s cubic-bezier(0.2, 0.3, 0.14, .96);
						}

						&.soundnine {
							transition: 9s cubic-bezier(0.2, 0.29, 0.14, .92);
						}

						&.soundten {
							transition: 9s cubic-bezier(0.2, 0.3, 0.15, .97);
						}




						&.active {
							transition: none !important;
						}

						.animation_item {
							width: 100%;
							height: 186px;
							position: relative;

							.back {
								position: absolute;
								width: 100%;
								height: 100%;
								left: 0;
								top: 0;
								background-position: center;
								background-repeat: no-repeat;
								background-size: 100% 100%;
								opacity: .6;
							}

							img {
								max-width: 80%;
								max-height: 80%;
								position: absolute;
								left: 0;
								right: 0;
								top: 0;
								bottom: 0;
								margin: auto;
							}
						}
					}
				}
				.animationy_mains{
					width: 930px;
					height: 200px;
					margin-top: 300px;
					box-sizing: border-box;
					overflow: hidden;
					display: flex;
					position: absolute;
					.animationy_boxs{
						flex: 1;
						width: 930px;
						height: 200px;
						box-sizing: border-box;
						display: -webkit-box;
						display: -webkit-flex;
						display: flex;
						img{
							-webkit-box-flex: 1;
							-webkit-flex: 1;
							flex: 1;
							width: 100%;
							max-height: 80%;
						}
					}

				}
			}
		}

	}
</style>
