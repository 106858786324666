import { postLogin } from '@/api/index/logIn'
import { get_player } from '@/api/index/personalCenter'
import { setTokenTimeout } from '@/utils/auth'
import router from '@/router'
export default {
  // 登录
  async login(context,login_res) {
    //const res = await postLogin(logInForm)
    context.commit('mutationsToken', login_res.data.token)
    //console.log(res)
    // 登录时保存时间
    setTokenTimeout()
    // 获取用户基本信息
    const inof = await get_player()
    context.commit('setGet_player', inof.data)
  },
  // 验证码登录
  async codeLogin(context, token) {
    context.commit('mutationsToken', token)
    // 登录时保存时间
    setTokenTimeout()
    // 获取用户基本信息
    const inof = await get_player()
    context.commit('setGet_player', inof.data)
  },
    // 获取用户基本信息
  async getPlayerApi(context) {
    const inof = await get_player()
    // console.log(inof);
    context.commit('setGet_player', inof.data)
  },


  //声音
  setAudio(context, u) {
    context.commit('setAudio', u)
  },
  //登录注册弹出层
  setLogSignRetrieve(context, u) {
    context.commit('setLogSignRetrieve', u)
  },
  // 退出登录
  logout(context) {
    // 清楚Token
    context.commit('mutationsToken', '')
    // 清楚Info
    // context.commit('setUserInfo', {})
    router.push(`/`)
  }
}
