<!-- 免费皮肤 -->
<template>
  <div class="app skin">
    <div class="a"></div>
    <div class="skin-body">
      <FreeSkinBody />
    </div>
  </div>
</template>

<script>
import FreeSkinBody from './FreeSkinBody.vue'
export default {
  name: 'K5Home',
  components: {
    FreeSkinBody
  },
  data() {
    return {}
  },
  created() {
		if (sessionStorage.getItem('n7_token')) {
			this.$store.dispatch('getPlayerApi')
		}
	},
  methods: {}
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.a {
  height: 70px;
}
.skin-head {
  width: 100%;
  z-index: 999;
  position: fixed;
  top: 0;
}
.skin-body {
  color: aliceblue;
}
</style>
