<!-- 公告 -->
<template>
  
  <div class="alert">
    
    <div class="text" v-html="content"></div>
    <div class="text" v-html="content"></div>
  </div>
</template>

<script>
import { postNotice } from '@/api/index/home'
export default {
  name: 'HomeBodyAlert',
  data() {
    return {
      content: ''
    }
  },
  created() {
    this.noticeApi()
  },
  methods: {
    noticeApi() {
      postNotice().then((res) => {
        this.content = res.data.content
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.alert {
  width: 1200px;
  height: 25px;
  border-radius: 40px;
  background-color: transparent;
  margin: 60px auto;
  margin-top: 40px;
  line-height: 25px;
  font-weight: 700;
  overflow: hidden;
  position: relative;
  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: slide 50s linear infinite;
	
    ::v-deep p {
      width: 100%;
      display: inline;
    }
    ::v-deep p span {
      white-space: nowrap !important;
      width: 100px;
      display: inline;
      font-size: 16px !important;
      background-color: rgba($color: #000000, $alpha: 0) !important;
    }
    @keyframes slide {
      0% {
        transform: translate(8%, -50%);
      }
      100% {
        transform: translate(-105%, -50%);
      }
    }
  }
}
</style>
