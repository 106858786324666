<!-- 玩法介绍 -->
<template>
  <div class="">
    <div
      class="int"
      :visible="visible"
      @update:visible="$emit('update:visible', $event)"
    >
      <div class="int-box">
        <div class="int-box-title">
          <div class="int-box-title-text">玩法介绍</div>
        </div>
        <div class="int-box-frame">
          <div class="int-box-frame-off" :style="VPointer()" @click="off"></div>
          <div class="int-box-frame-content">
            <div class="int-box-frame-content-head">
              {{ playIntroduction.title }}
            </div>
            <div v-html="playIntroduction.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get_play_introduction } from '@/api/index/AccessoriessTore'
export default {
  name: 'StoreGamePlay',
  data() {
    return {
      playIntroduction: {}
    }
  },
  props: {
    visible: Boolean
  },
  watch: {
    visible(val) {
      if (val) {
        this.getPlayIntroductionApi()
      }
    }
  },
  created() {},
  methods: {
    // 盲盒对战-玩法介绍
    getPlayIntroductionApi() {
      get_play_introduction().then((res) => {
        //console.log(res)
        this.playIntroduction = res.data
      })
    },
    // 关闭
    off() {
      this.$emit('update:visible', false)
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = '' //出现滚动条
      document.removeEventListener('touchmove', mo, false)
    }
  }
}
</script>
<style lang="scss" scoped>
// 引入css类
// @import '';
@import '@/styles/BlindBoxFightShow.scss';
.int-box-frame {
  width: 600px;
  height: 600px;
  background-size: 600px;
}
.int-box-frame-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  &-head {
    color: #23fffd;
    margin-bottom: 30px;
  }
  & p {
    text-align: left;
  }
}
</style>
