<!-- 开箱 -->
<template>
  <div class="body">
    <div :class="[{ introduced: AnimationsPopup },{ introduced: GamePlayShowModal }]"></div>
    <BodyBackground/>
    <!--		<HomeBodyAlert class="body-alert" />-->
    <img style="top: 35%" class="body-background" src="https://oss.n7buff.com/web/images/cdkBox/top_bak_cdk.png"
         alt=""/>
    <div class="body-main">
      <div class="free-title">
        <img class="free-title-off" src="@/assets/images/2title.png" alt=""/>
        <div class="free-title-off-myOne " :style="VPointer()" @click="$router.push({ path: '/GradeBox' })">等级箱子
        </div>
        <div class="free-title-off-myTow free-title-off-tow" :style="VPointer()">口令箱子</div>
        <div class="paly-text" :style="VPointer()" @click="GamePlay">
          玩法介绍
        </div>
      </div>
      <div class="InBox">
        <swiper :options="mySwiperOption" class="cdk-ul">
          <swiper-slide v-for="(item,index) in cdkBoxList" :key="index" class="cdk-li">
            <div class="cdk-li-back" @click="CaseDetailsTo(item,index)" :style="VPointer()">
              <div class="cdk-li-back-box">
                <img :src="item.img_active" alt=""/>
              </div>
              <div
                class="cdk-li-back-main"
              >
                <img :src="item.img_main" alt=""/>
              </div>
            </div>
            <div class="cdk-body-mainUl-li-name">{{ item.name }}</div>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev" :style="VPointer()" slot="button-prev"></div>
        <div class="swiper-button-next" :style="VPointer()" slot="button-next"></div>
      </div>
      <!-- 主体物 -->
      <div class="cdk-main-openBox" :style="VPointer()">
        <img class="cdk-main-openBox-box" :src="boxData.img_main" alt=""/>
        <img class="cdk-main-openBox-res" :src="boxData.img_active" alt=""/>
      </div>
      <div class="cdk-inputBox">
        <input type="text" placeholder="请输入密钥" v-model="password">
        <div class="cdk-inputBox-btn" @click="open()" :style="VPointer()">立即开启</div>
      </div>
      <BoxShowPopup class="BoxShowBodyOpenTake" v-show="AnimationsPopup && skinsList"
                    :visible.sync="AnimationsPopup" :count="count" :resultList="resultList" :skinsList="skinsList"
                    :show_prize="show_prize.show"/>
      <portrait v-if="showAnimations" :boxDatas="boxData" :goodsList="boxData.skins" :prize_data="prize_data"
                :showSounds="showSounds" @end="end" ref="portrait"></portrait>
      <!--  玩法介绍    -->
      <div
        class="fight-body-battleground-but-ShowModal"
        v-show="GamePlayShowModal"
      >
        <GamePlay :visible.sync="GamePlayShowModal" />
      </div>
    </div>
  </div>
</template>

<script>
import transverse from './transverse.vue'
import portrait from './portrait.vue'
// import BoxShowPopup from '../../components/boxShow/BoxShowPopup.vue'
import BoxShowPopup from '../../components/cdkBoxShow/BoxShowPopup.vue'
import BodyBackground from '../../components/BodyBackground.vue'
import HomeBodyAlert from '../../components/HomeBodyAlert.vue'
import 'swiper/dist/css/swiper.css'
import {swiper, swiperSlide} from 'vue-awesome-swiper'

import {
  open_box,
  get_open_box_record,
  get_drop_record
} from '@/api/index/boxCorrelation'
import {open_cdk_box} from "@/api/index/cdk";
import GamePlay from "@/views/cdk/components/GamePlay.vue";

export default {
  name: 'BoxShowBody',
  components: {
    GamePlay,
    portrait,
    BoxShowPopup,
    BodyBackground,
    swiper,
    swiperSlide
  },
  data() {
    return {
      count: 1, // 箱子个数
      showAnimations: true, // 动画
      AnimationsPopup: false, // 弹出层
      audio: new Audio('https://oss.n7buff.com/web/audio/Shoot.mp3'), //过渡声音
      current: new Audio('https://oss.n7buff.com/web/audio/roll9.mp3'), //过程声音
      show_audio: new Audio('https://oss.n7buff.com/web/audio/Shoot.mp3'), //过程声音
      prize_data: [],
      show_prize: {
        //奖品信息(展示所用)
        show: false,
        list: []
      },
      recordList: [], // 开箱历史
      resultList: [], // 结果
      skinsList: [], // 动画总数据,
      showSounds: true,
      timer: null,
      mySwiperOption: {
        slidesPerView: 5,
        spaceBetween: 16,
        slidesPerGroup: 5,
        pagination: {
          el: '.swiper-pagination', //与slot="pagination"处 class 一致
          clickable: true, //轮播按钮支持点击
        },
        //自动播放
        // autoplay: {
        //   delay: 4000,
        //   disableOnInteraction: false
        // },
        //循环
        loop: true,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      activeBox: {},
      password: '',
      GamePlayShowModal:false
    }
  },
  props: {
    boxData: Object,
    cdkBoxList: Array
  },
  watch: {
    // 监听弹出层的变化
    cdkBoxList() {
      if (this.cdkBoxList.length > 0) {
        this.$emit('getBoxDataList', this.cdkBoxList[0].id)
        this.activeBox = this.cdkBoxList[0]
      }
    },
    AnimationsPopup() {
      if (!this.AnimationsPopup) {
        this.audio.pause()
      }
    }
  },
  created() {
    if (this.$route.name == 'BoxShow') {
      this.getOpenBoxRecordApi()
    } else {
      this.timer = null
    }
  },
  methods: {
    // 开箱动画数据
    // async getBoxDataApi() {
    //   const res = await get_box_data({ id: this.$route.query.id })
    //   this.skinsList = res.data.skins
    //   console.log(this.skinsList)
    // },
    // 玩法介绍弹出
    GamePlay() {
      this.GamePlayShowModal = true
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', mo, false) //禁止页面滑动
    },
    CaseDetailsTo(item) {
      this.activeBox = item
      this.$emit('getBoxDataList', item.id)
    },
    // 开箱记录
    getOpenBoxRecordApi() {
      get_drop_record({
        box_id: this.$route.query.id,
        size: 20
      }).then((res) => {
        this.recordList = res.data
        this.getTimeOpenBoxRecordApi()
      })
    },
    getTimeOpenBoxRecordApi() {
      this.timer = setInterval(() => {
        get_drop_record({
          box_id: this.$route.query.id,
          size: 20
        }).then((res) => {
          this.recordList = res.data
        })
      }, 2000)
    },
    // 点击开启按钮
    open() {
      // 判断用户是否登录
      //console.log("sessionStorage.getItem('n7_token')" + sessionStorage.getItem('n7_token'))
      if (!sessionStorage.getItem('n7_token')) {
        this.$store.dispatch('setLogSignRetrieve', true)
        return
      }

      clearInterval(this.timer)
      this.timer = null
      open_cdk_box({
        cdk_box_id: this.activeBox.id,
        password: this.password
      }).then((res) => {
        //console.log(res.data)
        if (res.status === 1) {
          this.$store.dispatch('getPlayerApi')
          this.resultList = res.data
          let list = res.data
          this.prize_data = res.data // list.filter(item => item.label == null)
          if (this.showAnimations) {
            //动画开启
            this.Dijah = true
            // this.current.play()
            if (this.resultList.length > 1) {
              this.$refs.portrait.open(this.resultList.length)
            } else {
              this.$refs.portrait.open(this.resultList.length)
            }
          } else {
            //动画关闭
            this.$nextTick(() => {
              this.show_prize = {
                show: true,
                list: this.resultList
              }
              this.AnimationsPopup = true
              if (this.showSounds) {
                this.show_audio.play()
              }

            })
            this.getTimeOpenBoxRecordApi()
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 数量设置
    countImg(count) {
      this.count = count
    },
    // 声音开关
    showSound() {
      this.showSounds = !this.showSounds
    },
    // 动画开关
    showAnimation() {
      if (this.showAnimations) {
        this.showAnimations = false
      } else {
        this.showAnimations = true
      }
    },
    //重置动画效果参数
    end() {
      if (this.showSounds) {
        this.show_audio.play()
      }
      this.AnimationsPopup = true
      this.TranslateY = 80; //箱子上浮距离重置
      this.show_prize = {
        show: true,
        list: this.prize_data.list
      }
      this.prize_data = []
      this.getTimeOpenBoxRecordApi()
    },
    finishCallback(data, str) {
      // console.log('data, str: ', data, str)
      setTimeout(() => {
        this.$nextTick(() => {
          this.prize_data = {
            show: str,
            list: data
          }
        })
      }, 550)
    },
  },
  //销毁定时器
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/BoxShowBody.scss';
@import '@/styles/FreeSkinBody.scss';
.introduced {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 90;
  transition: all 0.15s linear;
}

.BoxShowBodyOpenTake {
  position: fixed;
  left: 10%;
  top: 5%;
  z-index: 91;
}
.fight-body-battleground-but-ShowModal{
  z-index: 99;
  position: relative;
  //margin-top: 10vh;
}
.body {
  padding-top: 50px;
}

.InBox {
  width: 1339px;
  margin: 0 auto;
  //padding: 0 145px;
  padding: 0 80px;
  position: relative;
}

.body-main {
  height: 700px;
}

.cdk-openBox {
  width: 275px;
  height: 87px;
  //background: url("@/assets/images/cdk-btn.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 27px;
  font-weight: bold;
  color: #FFFFFF;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-49%, 52%);
}

.cdk-inputBox {
  width: 913px;
  height: 72px;
  background: url("https://oss.n7buff.com/web/images/cdkBox/cdk-input.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 78%;
  left: 50%;
  transform: translate(-49%, 52%);
  padding-left: 51px;

  input {
    width: 70%;
    border: none;
    background: transparent;
    font-size: 31px;
    color: #FFFFFF;
    outline: none;
    &::placeholder {
      color: #FFFFFF;
    }
  }

  .cdk-inputBox-btn {
    width: 228px;
    height: 100%;
    position: absolute;
    right: 0;
    font-size: 31px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.cdk-main-openBox {
  width: 496px;
  margin: 0 auto;
  //position: relative;
  &-box {
    height: 275px;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-49%, 52%);
  }

  &-res {
    height: 180px;
    position: absolute;
    top: 22%;
    left: 50%;
    transform: translate(-49%, 88%);
  }

  //margin-top: 100px;
  //position: relative;
  //position: absolute;
}

//  轮播图
.cdk-ul {
  margin-top: 16px;
  //padding: 0 100px;
  .cdk-li {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-back {
      //background: url('@/assets/images/fightImages/Photoefficiency.png') no-repeat;
      width: 236px;
      height: 148px;
      background-size: 100%;
      position: relative;
      //padding-top: 20px;

      &-box {
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -20%);

        & > img {
          width: 140px;
        }
      }

      &-main {
        & > img {
          width: 185px;
        }
      }
    }
  }
}

.swiper-button-prev, .swiper-button-next {
  width: 72px;
  height: 99px;
  background-image: url("https://oss.n7buff.com/web/images/cdkBox/swiper-left.png");
  background-size: 100%;
  top: 35%;
  left: 0;
}

.swiper-button-next {
  background-image: url("https://oss.n7buff.com/web/images/cdkBox/swiper-right.png");
  background-size: 100%;
  top: 35%;
  left: auto;
  right: 0;
}
.paly-text{
  width: 85px;
  height: 42px;
  background: url("@/assets/images/GradeBox/paly.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:14px;
  color: #4EFEFE;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: -10px;
}
</style>
