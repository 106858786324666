<template>
  <div class="card-body">
<!--    <div :class="[{ introduced: AnimationsPopup }]"></div>-->
    <div class="card-main" v-if="challengeDetail">
      <div class="game-des" @click="gamePopShow = true" :style="VPointer()">玩法介绍</div>
      <div class="card-title">
        <span>{{ challengeDetail.name }}</span>
      </div>
      <div class="card-nav">
        <ul>
          <li :style="VPointer()"
              @click="seeBarrier('one_pass_data', 1, 'one_pass_status','one_open_info')"
          >
            <!--   选中状态         -->
            <img class="card-nav-image"  v-if="activeDetail === 'one_pass_data' && openChallenge.step === 1"  src="@/assets/images/card/active.png" alt="">
            <!--   选中状态         -->
            <img class="card-nav-image"  v-if="activeDetail === 'one_pass_data' && openChallenge.step === undefined"  src="@/assets/images/card/active.png" alt="">
            <!--   过关状态         -->
            <img class="card-nav-image" v-if="openChallenge.one_pass_status === 4 && lookGuanKa !== 'one_pass_data'"  src="@/assets/images/card/pass.png" alt="">
            <!--   未过关状态         -->
            <img class="card-nav-image" v-if="activeDetail !== 'one_pass_data' && openChallenge.one_pass_status !== 4 && lookGuanKa !== 'one_pass_data'" src="@/assets/images/card/nopass.png" alt="">
            <!--   选中查看状态         -->
            <img class="card-nav-image" v-if="lookGuanKa === 'one_pass_data' && openChallenge.step !== 1 && openChallenge.step !== undefined"  src="@/assets/images/card/click-active.png" alt="">
            <!--    枪        -->
            <div class="card-nav-qiang-box">
              <div class="card-nav-qiang-info">
                <img :src="challengeDetail.one_pass_data.image_url" alt="">
              </div>
              <el-tooltip class="item" effect="dark" :content="challengeDetail.one_pass_data.item_name" placement="bottom">
                <div class="card-nav-qiang-title">{{ challengeDetail.one_pass_data.item_name }}</div>
              </el-tooltip>
            </div>
            <span class="card-nav-title card-nav-title-active"   v-if="activeDetail === 'one_pass_data' && openChallenge.step === 1" >{{ challengeDetail.one_pass_data.name }}</span>
            <span class="card-nav-title card-nav-title-active"   v-if="activeDetail === 'one_pass_data' && openChallenge.step === undefined" >{{ challengeDetail.one_pass_data.name }}</span>
            <span class="card-nav-title card-nav-title-pass"     v-if="openChallenge.one_pass_status === 4 && lookGuanKa !== 'one_pass_data'">{{ challengeDetail.one_pass_data.name }}</span>
            <span class="card-nav-title card-nav-title-nopass"   v-if="activeDetail !== 'one_pass_data' && openChallenge.one_pass_status !== 4 && lookGuanKa !== 'one_pass_data'">{{ challengeDetail.one_pass_data.name }}</span>
            <span class="card-nav-title card-nav-title-click-active" v-if="lookGuanKa === 'one_pass_data' && openChallenge.step !== 1 && openChallenge.step !== undefined">{{ challengeDetail.one_pass_data.name }}</span>
          </li>
          <li :style="VPointer()"
              @click="seeBarrier('two_pass_data', 2, 'two_pass_status','two_open_info')"
          >
            <img class="card-nav-image" v-if="activeDetail === 'two_pass_data' && openChallenge.step === 2" src="@/assets/images/card/active.png" alt="">
            <img class="card-nav-image" v-if="openChallenge.two_pass_status === 4 && lookGuanKa !== 'two_pass_data'"  src="@/assets/images/card/pass.png" alt="">
            <img class="card-nav-image" v-if="activeDetail !== 'two_pass_data' && openChallenge.two_pass_status !== 4 && lookGuanKa !== 'two_pass_data' " src="@/assets/images/card/nopass.png" alt="">
            <img class="card-nav-image" v-if="lookGuanKa === 'two_pass_data' && openChallenge.step !== 2"  src="@/assets/images/card/click-active.png" alt="">
            <div class="card-nav-qiang-box">
              <div class="card-nav-qiang-info">
                <img :src="challengeDetail.two_pass_data.image_url" alt="">
              </div>
              <el-tooltip class="item" effect="dark" :content="challengeDetail.two_pass_data.item_name" placement="bottom">
                <div class="card-nav-qiang-title">{{ challengeDetail.two_pass_data.item_name }}</div>
              </el-tooltip>
            </div>
            <span class="card-nav-title card-nav-title-active"   v-if="activeDetail === 'two_pass_data' && openChallenge.step === 2" >{{ challengeDetail.two_pass_data.name }}</span>
            <span class="card-nav-title card-nav-title-pass"     v-if="openChallenge.two_pass_status === 4 && lookGuanKa !== 'two_pass_data'">{{ challengeDetail.two_pass_data.name }}</span>
            <span class="card-nav-title card-nav-title-nopass"   v-if="activeDetail !== 'two_pass_data' && openChallenge.two_pass_status !== 4 && lookGuanKa !== 'two_pass_data'">{{ challengeDetail.two_pass_data.name }}</span>
            <span class="card-nav-title card-nav-title-click-active" v-if="lookGuanKa === 'two_pass_data' && openChallenge.step !== 2 ">{{ challengeDetail.two_pass_data.name }}</span>
          </li>
          <li :style="VPointer()"
              @click="seeBarrier('tre_pass_data', 3, 'tre_pass_status','tre_open_info')"
          >
            <img class="card-nav-image" v-if="activeDetail === 'tre_pass_data' && openChallenge.step === 3" src="@/assets/images/card/active.png" alt="">
            <img class="card-nav-image" v-if="openChallenge.tre_pass_status === 4  && lookGuanKa !== 'tre_pass_data'"  src="@/assets/images/card/pass.png" alt="">
            <img class="card-nav-image" v-if="activeDetail !== 'tre_pass_data' && openChallenge.tre_pass_status !== 4 && lookGuanKa !== 'tre_pass_data' " src="@/assets/images/card/nopass.png" alt="">
            <img class="card-nav-image" v-if="lookGuanKa === 'tre_pass_data' && openChallenge.step !== 3"  src="@/assets/images/card/click-active.png" alt="">
            <div class="card-nav-qiang-box">
              <div class="card-nav-qiang-info">
                <img :src="challengeDetail.tre_pass_data.image_url" alt="">
              </div>
              <el-tooltip class="item" effect="dark" :content="challengeDetail.tre_pass_data.item_name" placement="bottom">
                <div class="card-nav-qiang-title">{{ challengeDetail.tre_pass_data.item_name }}</div>
              </el-tooltip>
            </div>
            <span class="card-nav-title card-nav-title-active"   v-if="activeDetail === 'tre_pass_data' && openChallenge.step === 3" >{{ challengeDetail.tre_pass_data.name }}</span>
            <span class="card-nav-title card-nav-title-pass"     v-if="openChallenge.tre_pass_status === 4 && lookGuanKa !== 'tre_pass_data'">{{ challengeDetail.tre_pass_data.name }}</span>
            <span class="card-nav-title card-nav-title-nopass"   v-if="activeDetail !== 'tre_pass_data' && openChallenge.tre_pass_status !== 4 && lookGuanKa !== 'tre_pass_data'">{{ challengeDetail.tre_pass_data.name }}</span>
            <span class="card-nav-title card-nav-title-click-active" v-if="lookGuanKa === 'tre_pass_data' && openChallenge.step !== 3 ">{{ challengeDetail.tre_pass_data.name }}</span>
          </li>
          <li :style="VPointer()"
              @click="seeBarrier('for_pass_data', 4,'for_pass_status', 'for_open_info')"
          >
            <img class="card-nav-image" v-if="activeDetail === 'for_pass_data' && openChallenge.step === 4" src="@/assets/images/card/active.png" alt="">
            <img class="card-nav-image" v-if="openChallenge.for_pass_status === 4 && lookGuanKa !== 'for_pass_data'"  src="@/assets/images/card/pass.png" alt="">
            <img class="card-nav-image" v-if="activeDetail !== 'for_pass_data' && openChallenge.for_pass_status !== 4 && lookGuanKa !== 'for_pass_data' " src="@/assets/images/card/nopass.png" alt="">
            <img class="card-nav-image" v-if="lookGuanKa === 'for_pass_data' && openChallenge.step !== 4"  src="@/assets/images/card/click-active.png" alt="">
            <div class="card-nav-qiang-box">
              <div class="card-nav-qiang-info">
                <img :src="challengeDetail.for_pass_data.image_url" alt="">
              </div>
              <el-tooltip class="item" effect="dark" :content="challengeDetail.for_pass_data.item_name" placement="bottom">
                <div class="card-nav-qiang-title">{{ challengeDetail.for_pass_data.item_name }}</div>
              </el-tooltip>
            </div>
            <span class="card-nav-title card-nav-title-active"   v-if="activeDetail === 'for_pass_data' && openChallenge.step === 4" >{{ challengeDetail.for_pass_data.name }}</span>
            <span class="card-nav-title card-nav-title-pass"     v-if="openChallenge.for_pass_status === 4 && lookGuanKa !== 'for_pass_data'">{{ challengeDetail.for_pass_data.name }}</span>
            <span class="card-nav-title card-nav-title-nopass"   v-if="activeDetail !== 'for_pass_data' && openChallenge.for_pass_status !== 4 && lookGuanKa !== 'for_pass_data'">{{ challengeDetail.for_pass_data.name }}</span>
            <span class="card-nav-title card-nav-title-click-active" v-if="lookGuanKa === 'for_pass_data' && openChallenge.step !== 4 ">{{ challengeDetail.for_pass_data.name }}</span>
          </li>
          <li :style="VPointer()"
              @click="seeBarrier('fiv_pass_data', 5, 'fiv_pass_status','fiv_open_info')"
          >
            <img class="card-nav-image" v-if="activeDetail === 'fiv_pass_data' && openChallenge.step === 5" src="@/assets/images/card/active.png" alt="">
            <img class="card-nav-image" v-if="openChallenge.fiv_pass_status === 4"  src="@/assets/images/card/pass.png" alt="">
            <img class="card-nav-image" v-if="activeDetail !== 'fiv_pass_data' && openChallenge.fiv_pass_status !== 4 && lookGuanKa !== 'fiv_pass_data' " src="@/assets/images/card/nopass.png" alt="">
            <img class="card-nav-image" v-if="lookGuanKa === 'fiv_pass_data' && openChallenge.step !== 5"  src="@/assets/images/card/click-active.png" alt="">
            <div class="card-nav-qiang-box">
              <div class="card-nav-qiang-info">
                <img :src="challengeDetail.fiv_pass_data.image_url" alt="">
              </div>
              <el-tooltip class="item" effect="dark" :content="challengeDetail.fiv_pass_data.item_name" placement="bottom">
                <div class="card-nav-qiang-title">{{ challengeDetail.fiv_pass_data.item_name }}</div>
              </el-tooltip>
            </div>
            <span class="card-nav-title card-nav-title-active"   v-if="activeDetail === 'fiv_pass_data' && openChallenge.step === 5" >{{ challengeDetail.fiv_pass_data.name }}</span>
            <span class="card-nav-title card-nav-title-pass"     v-if="openChallenge.fiv_pass_status === 4 && lookGuanKa !== 'fiv_pass_data'">{{ challengeDetail.fiv_pass_data.name }}</span>
            <span class="card-nav-title card-nav-title-nopass"   v-if="activeDetail !== 'fiv_pass_data' && openChallenge.fiv_pass_status !== 4 && lookGuanKa !== 'fiv_pass_data'">{{ challengeDetail.fiv_pass_data.name }}</span>
            <span class="card-nav-title card-nav-title-click-active" v-if="lookGuanKa === 'fiv_pass_data' && openChallenge.step !== 5 ">{{ challengeDetail.fiv_pass_data.name }}</span>
          </li>
        </ul>
      </div>
      <div class="encourageRegion" ref="cardArea">
        <div class="encourage-body">
          <div class="encourage-ul">

            <div class="encourage-li animate__animated "
                 :class="openChallenge.one_pass_status === 4 && openChallenge.one_pass_barrier ? 'animate__shakeX':''"
                 :style="`background-image: url(${challengeDetail.one_pass_data.rarity_thumb}) !important;`"
            >
              <div class="encourage-li-title">第一关 <span v-if="openChallenge.one_pass_status === 4" style="color: #FF7B07;">已获得</span>  </div>
              <img :src="challengeDetail.one_pass_data.image_url" alt="">
              <div class="encourage-li-bottom">
                <span class="encourage-li-bottom-price"> <img src="@/assets/images/card/dzxy.png" alt=""> <i>{{ challengeDetail.one_pass_data.price }}</i>  </span>
                <el-tooltip class="item" effect="dark" :content="challengeDetail.one_pass_data.item_name" placement="bottom">
                <span class="encourage-li-bottom-name">{{ challengeDetail.one_pass_data.item_name }}</span>
                </el-tooltip>
              </div>
              <img class="suo" v-if="openChallenge.one_pass_status !== 4" src="@/assets/images/card/suo.png">
            </div>
            <div class="encourage-li animate__animated"
                 :class="openChallenge.two_pass_status === 4 && openChallenge.two_pass_barrier ? 'animate__shakeX':''"
                 :style="`background-image: url(${challengeDetail.two_pass_data.rarity_thumb}) !important;`"
            >
              <div class="encourage-li-title">第二关 <span v-if="openChallenge.two_pass_status === 4" style="color: #FF7B07;">已获得</span> </div>
              <img :src="challengeDetail.two_pass_data.image_url" alt="">
              <div class="encourage-li-bottom">
                <span class="encourage-li-bottom-price"> <img src="@/assets/images/card/dzxy.png" alt=""> <i>{{ challengeDetail.two_pass_data.price }}</i>  </span>
                <el-tooltip class="item" effect="dark" :content="challengeDetail.two_pass_data.item_name" placement="bottom">
                  <span class="encourage-li-bottom-name">{{ challengeDetail.two_pass_data.item_name }}</span>
                </el-tooltip>
              </div>
              <img class="suo" v-if="openChallenge.two_pass_status !== 4" src="@/assets/images/card/suo.png">
            </div>
            <div class="encourage-li animate__animated"
                 :class="openChallenge.tre_pass_status === 4 && openChallenge.tre_pass_barrier ? 'animate__shakeX':''"
                 :style="`background-image: url(${challengeDetail.tre_pass_data.rarity_thumb}) !important;`"
            >
              <div class="encourage-li-title">第三关 <span v-if="openChallenge.tre_pass_status === 4" style="color: #FF7B07;">已获得</span> </div>
              <img :src="challengeDetail.tre_pass_data.image_url" alt="">
              <div class="encourage-li-bottom">
                <span class="encourage-li-bottom-price"> <img src="@/assets/images/card/dzxy.png" alt=""> <i>{{ challengeDetail.tre_pass_data.price }}</i>  </span>
                <el-tooltip class="item" effect="dark" :content="challengeDetail.tre_pass_data.item_name" placement="bottom">
                  <span class="encourage-li-bottom-name">{{ challengeDetail.tre_pass_data.item_name }}</span>
                </el-tooltip>
              </div>
              <img class="suo" v-if="openChallenge.tre_pass_status !== 4" src="@/assets/images/card/suo.png">
            </div>
            <div class="encourage-li animate__animated"
                 :class="openChallenge.for_pass_status === 4  && openChallenge.for_pass_barrier ? 'animate__shakeX':''"
                 :style="`background-image: url(${challengeDetail.for_pass_data.rarity_thumb}) !important;`"
            >
              <div class="encourage-li-title">第四关 <span v-if="openChallenge.for_pass_status === 4" style="color: #FF7B07;">已获得</span> </div>
              <img :src="challengeDetail.for_pass_data.image_url" alt="">
              <div class="encourage-li-bottom">
                <span class="encourage-li-bottom-price"> <img src="@/assets/images/card/dzxy.png" alt=""> <i>{{ challengeDetail.for_pass_data.price }}</i>  </span>
                <el-tooltip class="item" effect="dark" :content="challengeDetail.for_pass_data.item_name" placement="bottom">
                  <span class="encourage-li-bottom-name">{{ challengeDetail.for_pass_data.item_name }}</span>
                </el-tooltip>
              </div>
              <img class="suo" v-if="openChallenge.for_pass_status !== 4" src="@/assets/images/card/suo.png">
            </div>
            <div class="encourage-li animate__animated"
                 :class="openChallenge.fiv_pass_status === 4 && openChallenge.fiv_pass_barrier ? 'animate__shakeX':''"
                 :style="`background-image: url(${challengeDetail.fiv_pass_data.rarity_thumb}) !important;`"
            >
              <div class="encourage-li-title">第五关  <span v-if="openChallenge.fiv_pass_status === 4" style="color: #FF7B07;">已获得</span></div>
              <img :src="challengeDetail.fiv_pass_data.image_url" alt="">
              <div class="encourage-li-bottom">
                <span class="encourage-li-bottom-price"> <img src="@/assets/images/card/dzxy.png" alt=""> <i>{{ challengeDetail.fiv_pass_data.price }}</i>  </span>
                <el-tooltip class="item" effect="dark" :content="challengeDetail.fiv_pass_data.item_name" placement="bottom">
                  <span class="encourage-li-bottom-name">{{ challengeDetail.fiv_pass_data.item_name }}</span>
                </el-tooltip>
              </div>
              <img class="suo" v-if="openChallenge.fiv_pass_status !== 4" src="@/assets/images/card/suo.png">
            </div>
          </div>
        </div>
        <transition-group
          class="card-licensing-area"
          name="file"
          tag="div"
          v-show="drawList.length > 0"

        >
          <div
            :class="['card-item', `card-item-${index + 1}`]"
            v-for="(item, index) in drawList"
            :key="'item'+item.id"
            @mouseenter="mouse()"
            ref="cardItem"
          >
            <div
              :class="['card', selectArr.includes(index) ? 'cardAnimate' : '', fanpaiClass ? 'contxtAnimate2':'']"
            >
              <div class="card-open-price" v-if="pass_num === 1 && activeDetail === 'one_pass_data'">
                <img src="@/assets/images/card/dzxy.png" alt="">
                <span>{{ challengeDetail.price }}</span>
              </div>
              <div class="card-open-btn" :style="VPointer()" @click="openRotateCard(index)" v-if="item.showBtn">
                立即开启
              </div>
            </div>
            <div
              :class="['contxt', selectArr.includes(index) ? 'contxtAnimate' : '', fanpaiClass ? 'cardAnimate2':'', item.active ? 'activeItem':'']"
            >
              <div class="card-contxt-title">{{ item.item_name }}</div>
              <div class="card-context-img" >
                <img :src="item.image_url" :style="item.item_name === '0.1'? 'transform: rotate(0deg)':''">
              </div>
            </div>
          </div>
        </transition-group>
      </div>

    </div>
    <openPraise
      :visible="AnimationsPopup"
      :resultList="resultList"
      :result="result"
      :pass_num="pass_num"
      @success="continueChallenge"
      @fail="restart"
      @end="receivePassSkin"
      @fivEnd="fivRestart"
    />
    <openPop  v-if="challengeDetail" :closeOpenPopShow="closeOpenPopShow" :price="challengeDetail.price || 0" :openNum="openNum" @close="closeOpenPop()" @determine="rotateCard"></openPop>
    <cardGameDes v-if="gamePopShow" :gamePopShow="gamePopShow" @close="gamePopShow = false"></cardGameDes>
  </div>
</template>
<script>
import openPraise from "@/components/card/openPraise.vue";
import openPop from "@/components/card/openPop.vue";
import cardGameDes from "@/components/card/cardGameDes.vue";

import {
  get_challenge_detail,
  get_open_challenge_detail, next_step,
  open_challenge,
  process_pass,
  receive_pass_skin
} from "@/api/index/card";

export default {
  components: {openPraise, openPop, cardGameDes},
  data() {
    return {
      selectArr: [],
      drawList: [],
      audio: new Audio('https://oss.n7buff.com/web/audio/dui.mp3'),
      audioClick: new Audio('https://oss.n7buff.com/web/audio/mens.mp3'), //过渡声音
      AnimationsPopup: false,
      closeOpenPopShow: false,
      gamePopShow: false,
      resultList:[],
      count: 1, // 箱子个数
      pass_num: 1, //当前第几关
      show_prize: {
        //奖品信息(展示所用)
        show: false,
        list: []
      },
      challengeDetail: null, // 详情数据
      openChallenge: null,
      activeDetail:'one_pass_data', // 选中的详情
      activeStatus: 'one_pass_status',
      activeWin: 'one_win_status',
      openKeyIndex: 'one_open_info', // 开箱子得key值
      result: false , //是否过关
      lookGuanKa:'', //  查看关卡
      lookGuanKaStatus:'', //查看关卡状态
      lookGuanKaOpen: '', //通关后选中的位置
      openNum: 0, //开启位置
      fanpaiClass: false,
      shuffledArray: [],
      barrier: false,
      activeBarrier: 'one_pass_barrier',
      shuffleAnimationResilt: false
    }
  },
  mounted() {
    this.getChallengeDetail()
  },
  methods:{
    // 关闭确定弹窗
    closeOpenPop() {
      this.closeOpenPopShow = false
    },
    // 获取关卡详情
    getChallengeDetail() {
      get_challenge_detail({ challenge_id:this.$route.query.challenge_id }).then(res => {
        console.log(res)
        this.challengeDetail = res.data.challenge_detail
        this.openChallenge = res.data.open_challenge
        switch (res.data.open_challenge.step){
          case 1:
            this.activeDetail = 'one_pass_data'
            this.activeStatus = 'one_pass_status'
            this.activeWin = 'one_win_status'
            this.openKeyIndex = 'one_open_info'
            this.activeBarrier = 'one_pass_barrier'
            this.pass_num = 1
            break;
          case 2:
            this.activeDetail = 'two_pass_data'
            this.activeStatus = 'two_pass_status'
            this.activeWin = 'two_win_status'
            this.openKeyIndex = 'two_open_info'
            this.activeBarrier = 'two_pass_barrier'
            this.pass_num = 2
            break;
          case 3:
            this.activeDetail = 'tre_pass_data'
            this.activeStatus = 'tre_pass_status'
            this.activeWin = 'tre_win_status'
            this.openKeyIndex = 'tre_open_info'
            this.activeBarrier = 'tre_pass_barrier'
            this.pass_num = 3
            break;
          case 4:
            this.activeDetail = 'for_pass_data'
            this.activeStatus = 'for_pass_status'
            this.activeWin = 'for_win_status'
            this.openKeyIndex = 'for_open_info'
            this.activeBarrier = 'for_pass_barrier'
            this.pass_num = 4
            break;
          case 5:
            this.activeDetail = 'fiv_pass_data'
            this.activeStatus = 'fiv_pass_status'
            this.activeWin = 'fiv_win_status'
            this.openKeyIndex = 'fiv_open_info'
            this.activeBarrier = 'fiv_pass_barrier'
            this.pass_num = 5
            break;
        }
        if (this.openChallenge[this.activeStatus] !== 4 && this.openChallenge[this.activeWin] === 2){
          if (this.openChallenge[this.activeWin] === 2){
            this.resultList = []
            this.resultList.push({
              image_url:this.challengeDetail[this.activeDetail].image_url,
              item_name:this.challengeDetail[this.activeDetail].item_name,
              price:this.challengeDetail[this.activeDetail].price,
            })
            this.result = true
            this.showOpenReResult(this.openChallenge[this.openKeyIndex])
          }
          if (this.openChallenge[this.activeWin] === 3){
            this.resultList = []
            this.resultList.push({
              image_url:require('@/assets/ling.png'),
              item_name:'0.1',
              price: 0.01,
            })
            this.result = false
          }
          this.AnimationsPopup = true
        } else {
          this.getDrawList()
        }
      })
    },
    // 获取闯关详情
    getOpenChallengeDetail(){
      get_open_challenge_detail({
        open_challenge_id: this.openChallenge.id,
        challenge_id: this.$route.query.challenge_id,
      }).then(res => {
        console.log(res.data.step)
        this.openChallenge = res.data
        switch (res.data.step){
          case 1:
            this.activeDetail = 'one_pass_data'
            this.activeStatus = 'one_pass_status'
            this.activeWin = 'one_win_status'
            this.openKeyIndex = 'one_open_info'
            this.activeBarrier = 'one_pass_barrier'
            this.pass_num = 1
            break;
          case 2:

            this.activeDetail = 'two_pass_data'
            this.activeStatus = 'two_pass_status'
            this.activeWin = 'two_win_status'
            this.openKeyIndex = 'two_open_info'
            this.activeBarrier = 'two_pass_barrier'
            this.pass_num = 2
            console.log(this.activeDetail)
            break;
          case 3:
            this.activeDetail = 'tre_pass_data'
            this.activeStatus = 'tre_pass_status'
            this.activeWin = 'tre_win_status'
            this.openKeyIndex = 'tre_open_info'
            this.activeBarrier = 'tre_pass_barrier'
            this.pass_num = 3
            break;
          case 4:
            this.activeDetail = 'for_pass_data'
            this.activeStatus = 'for_pass_status'
            this.activeWin = 'for_win_status'
            this.openKeyIndex = 'for_open_info'
              this.activeBarrier = 'for_pass_barrier'
            this.pass_num = 4
            break;
          case 5:
            this.activeDetail = 'fiv_pass_data'
            this.activeStatus = 'fiv_pass_status'
            this.activeWin = 'fiv_win_status'
            this.openKeyIndex = 'fiv_open_info'
            this.activeBarrier = 'fiv_pass_barrier'
            this.pass_num = 5
            break;
        }
      })
    },
    // 获取下一关详情
    getOpenChallengeDetailTwo() {
      get_open_challenge_detail({
        open_challenge_id: this.openChallenge.id,
        challenge_id: this.$route.query.challenge_id,
      }).then(res => {
        console.log(res.data.step)
        this.openChallenge = res.data
        switch (res.data.step){
          case 1:
            this.activeDetail = 'one_pass_data'
            this.activeStatus = 'one_pass_status'
            this.activeWin = 'one_win_status'
            this.openKeyIndex = 'one_open_info'
            this.activeBarrier = 'one_pass_barrier'
            this.pass_num = 1
            break;
          case 2:

            this.activeDetail = 'two_pass_data'
            this.activeStatus = 'two_pass_status'
            this.activeWin = 'two_win_status'
            this.openKeyIndex = 'two_open_info'
            this.activeBarrier = 'two_pass_barrier'
            this.pass_num = 2
            console.log(this.activeDetail)
            break;
          case 3:
            this.activeDetail = 'tre_pass_data'
            this.activeStatus = 'tre_pass_status'
            this.activeWin = 'tre_win_status'
            this.openKeyIndex = 'tre_open_info'
            this.activeBarrier = 'tre_pass_barrier'
            this.pass_num = 3
            break;
          case 4:
            this.activeDetail = 'for_pass_data'
            this.activeStatus = 'for_pass_status'
            this.activeWin = 'for_win_status'
            this.openKeyIndex = 'for_open_info'
            this.activeBarrier = 'for_pass_barrier'
            this.pass_num = 4
            break;
          case 5:
            this.activeDetail = 'fiv_pass_data'
            this.activeStatus = 'fiv_pass_status'
            this.activeWin = 'fiv_win_status'
            this.openKeyIndex = 'fiv_open_info'
            this.activeBarrier = 'fiv_pass_barrier'
            this.pass_num = 5
            break;
        }
        this.getDrawList()
      })
    },
    async seeBarrier(value, index, Status, open) {
      if (this.lookGuanKa === value){
        return
      }
      if (!this.shuffleAnimationResilt){
        this.$message.warning('请等待当前关卡洗牌完成！')
        return
      }
      // this.activeDetail = value
      this.selectArr = []
      this.lookGuanKa = value
      this.lookGuanKaStatus = Status
      this.lookGuanKaOpen = open
      if (this.lookGuanKa === this.activeDetail){
        this.drawList = []
        setTimeout(()=>{
          this.getDrawList()
        },10)
      } else {
        // this.openChallenge.step = index
        this.drawList = []
        setTimeout(()=>{
          this.lookDrawList()
        },10)
      }
    },
    // 当前关卡洗牌操作
    getDrawList() {
      this.drawList = []
      this.shuffledArray = []
      let length = this.challengeDetail[this.activeDetail].pass_base
      for (let i =0 ; i<length; i++){
        this.drawList.push({item_name:'0.1', image_url:require('@/assets/ling.png'),id: i+1, showBtn: false})
        this.selectArr.push(i)
      }
      const max = this.challengeDetail[this.activeDetail].pass_base
      const num = this.getRandomNumber(1,max, 5 ) -1
      this.drawList[num].item_name = this.challengeDetail[this.activeDetail].item_name
      this.drawList[num].image_url = this.challengeDetail[this.activeDetail].image_url
      this.shuffleAnimationResilt = false
      this.shuffleAnimation()
      // this.drawList = new Array(this.challengeDetail[this.activeDetail].pass_base)
      // this.$nextTick(()=>{
      //   const parentElement = this.$refs.cardArea.getBoundingClientRect();
      //   const childElement = this.$refs.cardItem;
      //   childElement.forEach((item,index) => {
      //     console.log('left', item.getBoundingClientRect().left - parentElement.left )
      //     console.log('top', item.getBoundingClientRect().top - parentElement.top )
      //   })
      // })
      // this.selectArr = JSON.parse(JSON.stringify(this.drawList))
      // setTimeout(()=>{
      //   this.selectArr = []
      // }, 2000)
    },
    // 当前关卡展示开牌结果
    showOpenReResult(index) {
      console.log(index)
      this.drawList = []
      let length = this.challengeDetail[this.activeDetail].pass_base
      for (let i =0 ; i<length; i++){
        if (i === (index - 0)) {
          this.drawList.push({item_name:this.challengeDetail[this.activeDetail].item_name, image_url:this.challengeDetail[this.activeDetail].image_url,id: i+1, showBtn: false, active: true})
        } else {
          this.drawList.push({item_name:'0.1', image_url:require('@/assets/ling.png'),id: i+1, showBtn: false})
        }

        this.selectArr.push(i)
      }
    },
    // 洗牌动画操作
    async shuffleAnimation(){
      await this.delay(200);
        this.selectArr = []
        this.fanpaiClass = true
      await this.delay(100);
      this.fanpaiClass = false
      if (this.drawList.length === 2){
        this.drawList = this.drawList.reverse()
      }else {
        this.drawList = this.shuffle(this.drawList)
      }
      await this.delay(150);
        if (this.drawList.length === 2){
          this.drawList = this.drawList.reverse()
        }else {
          this.drawList = this.shuffle(this.drawList)
        }
      await this.delay(340);
        if (this.drawList.length === 2){
          this.drawList = this.drawList.reverse()
        }else {
          this.drawList = this.shuffle(this.drawList)
        }
      await this.delay(300);
        if (this.drawList.length === 2){
          this.drawList = this.drawList.reverse()
        }else {
          this.drawList = this.shuffle(this.drawList)
        }
      await this.delay(200);
        if (this.drawList.length === 2){
          this.drawList = this.drawList.reverse()
        }else {
          this.drawList = this.shuffle(this.drawList)
        }
      await this.delay(230);
        if (this.drawList.length === 2){
          this.drawList = this.drawList.sort()
        }else {
          this.drawList = this.shuffle(this.drawList)
        }
      await this.delay(240);
      if (this.drawList.length === 2){
        this.drawList = this.drawList.reverse()
      }else {
        this.drawList = this.shuffle(this.drawList)
      }
      await this.delay(140);
      if (this.drawList.length === 2){
        this.drawList = this.drawList.reverse()
      }else {
        this.drawList = this.shuffle(this.drawList)
      }
      await this.delay(300);
      if (this.drawList.length === 2){
        this.drawList = this.drawList.reverse()
      }else {
        this.drawList = this.shuffle(this.drawList)
      }
      await this.delay(200);
      if (this.drawList.length === 2){
        this.drawList = this.drawList.sort()
      }else {
        this.drawList = this.shuffle(this.drawList)
      }
      await this.delay(300);
      if (this.drawList.length === 2){
        this.drawList = this.drawList.reverse()
      }else {
        this.drawList = this.shuffle(this.drawList)
      }
      await this.delay(300);
      if (this.drawList.length === 2){
        this.drawList = this.drawList.reverse()
      }else {
        this.drawList = this.shuffle(this.drawList)
      }
      await this.delay(300);
      if (this.drawList.length === 2){
        this.drawList = this.drawList.reverse()
      }else {
        this.drawList = this.shuffle(this.drawList)
      }
      await this.delay(200);
      if (this.drawList.length === 2){
        this.drawList = this.drawList.reverse()
      }else {
        this.drawList = this.shuffle(this.drawList)
      }
      await this.delay(300);
      if (this.drawList.length === 2){
        this.drawList = this.drawList.reverse()
      }else {
        this.drawList = this.shuffle(this.drawList)
      }
      await this.delay(500);
      if (this.drawList.length === 2){
        this.drawList = this.drawList.reverse()
      }else {
        this.drawList = this.shuffle(this.drawList)
      }
      await this.delay(200);
      if (this.drawList.length === 2){
        this.drawList = this.drawList.reverse()
      }else {
        this.drawList = this.shuffle(this.drawList)
      }
      await this.delay(600);
      if (this.drawList.length === 2){
        this.drawList = this.drawList.reverse()
      }else {
        this.drawList = this.shuffle(this.drawList)
      }
      await this.delay(1500);
        this.drawList.forEach(item => {
          item.showBtn = true;
        });
        this.shuffleAnimationResilt = true
    },
    // 定时器方法
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    // 查看关卡
    async lookDrawList(){
      this.drawList = []
      let length = this.challengeDetail[this.lookGuanKa].pass_base
      for (let i =0 ; i<length; i++){
        this.drawList.push({item_name:'0.1', image_url:require('@/assets/ling.png'),id: i+1,showBtn: false})
      }
      console.log(this.openChallenge[this.lookGuanKaStatus])
      if (this.openChallenge[this.lookGuanKaStatus] !== 4) {
        setTimeout(()=> {
          // this.drawList = []
          this.drawList = this.shuffle(this.drawList)
        },100)
      } else {
        await this.getOpenChallengeDetail()
        console.log(this.openChallenge[this.lookGuanKaOpen])
        const OpenNum = this.openChallenge[this.lookGuanKaOpen] - 0
        this.drawList[OpenNum].item_name = this.challengeDetail[this.lookGuanKa].item_name
        this.drawList[OpenNum].image_url = this.challengeDetail[this.lookGuanKa].image_url
        this.drawList[OpenNum].active = true
        this.selectArr.push(OpenNum);
        for (let i = 0; i < this.drawList.length; i++) {
          setTimeout(() => {
            this.selectArr.push(i);
          }, 10);
        }
      }

    },
    mouse() {
      // console.log(1);
      // console.log(this.$route.name);
      if (this.$store.state.Audio) {
        //this.audio.play()
        this.audio.play().catch(() => {});
      }
    },
    // 点击翻转
    openRotateCard(index) {
      this.openNum = index
      if (this.pass_num === 1){
        this.closeOpenPopShow = true
      } else {
        this.rotateCard(index)
      }

    },
    async rotateCard(index) {
      this.closeOpenPopShow = false
      if (this.$store.state.Audio) {
        this.audioClick.play().catch(() => {});
      }
      if (this.selectArr.length > 0){
        return
      }
      this.lookGuanKa = ''
      let data = null
      if (this.pass_num === 1){
        // eslint-disable-next-line no-unused-vars
        data = await open_challenge({
          challenge_id: this.$route.query.challenge_id -0,
          open_key: index,
          pass_num: this.pass_num
        })
        this.openChallenge.id = data.data.open_challenge_id
      } else {
        data = await process_pass({
          open_challenge_id: this.openChallenge.id - 0,
          challenge_id: this.$route.query.challenge_id -0,
          // [this.openKey]: index,
          open_key: index,
          pass_num: this.pass_num
        })
      }
      this.drawList.forEach(item => {
        item.image_url = require('@/assets/ling.png')
        item.item_name = '0.1'
        item.price = 0.01
      })
      if (data.data.status) {
        this.drawList[index].item_name = this.challengeDetail[this.activeDetail].item_name
        this.drawList[index].image_url = this.challengeDetail[this.activeDetail].image_url
        this.resultList = []
        this.resultList.push({
          image_url:this.challengeDetail[this.activeDetail].image_url,
          item_name:this.challengeDetail[this.activeDetail].item_name,
          price:this.challengeDetail[this.activeDetail].price,
        })
        if (this.pass_num === 5){
          this.openChallenge.fiv_pass_status = 4
        }
        console.log(this.pass_num, '当前成功关卡')
      } else {
        const max = this.challengeDetail[this.activeDetail].pass_base - 1
        const num = this.getRandomNumber(0,max,index )
        this.drawList[num].item_name = this.challengeDetail[this.activeDetail].item_name
        this.drawList[num].image_url = this.challengeDetail[this.activeDetail].image_url
        this.resultList = []
        this.resultList.push({
          image_url:require('@/assets/ling.png'),
          item_name:'0.1',
          price: 0.01,
        })
      }
      console.log(this.drawList, '中奖数组')
      this.result = data.data.status
      // this.getOpenChallengeDetail()
      this.AnimationsPopup = true
      await this.delay(1400)
      this.selectArr.push(index);
      for (let i = 0; i < this.drawList.length; i++) {
        setTimeout(() => {
          this.selectArr.push(i);
        }, 10);
      }
    },
    // 0.1随机生成位置
    getRandomNumber(min, max, skip) {
      let randomNumber;

      do {
        randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
      } while (randomNumber === skip);

      return randomNumber;
    },
    // 打乱数组
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min) // +1是保证可以取到上限值
    },
    shuffle(arr) {
      let _arr = arr.slice();
      for (let i = _arr.length - 1; i > 0; i--) {
        let j = this.getRandomInt(0, i);
        let t = _arr[i];
        _arr[i] = _arr[j];
        _arr[j] = t;
      }
      return _arr;
    },
    shuffleArray() {
      do {
        this.shuffledArray = [...this.drawList];
        this.shuffles(this.shuffledArray);
      } while (JSON.stringify(this.drawList) === JSON.stringify(this.shuffledArray));
    },
    shuffles(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    },
    // 挑战成功下一关
    async continueChallenge() {
      console.log(this.activeStatus,this.activeWin,'2222222222222222222222')
      this.openChallenge[this.activeStatus] = 4
      this.openChallenge[this.activeWin] = 2
      this.openChallenge[this.activeBarrier] = true
      switch (this.activeDetail){
        case 'one_pass_data':
          // this.activeDetail = 'two_pass_data'
          // this.activeStatus = 'two_pass_status'
          // this.activeWin = 'two_win_status'
          // this.openKey = 'two_open_key'
          this.pass_num = 2
          break;
        case 'two_pass_data':
          // this.activeDetail = 'tre_pass_data'
          // this.activeStatus = 'tre_pass_status'
          // this.activeWin = 'tre_win_status'
          // this.openKey = 'tre_open_key'
          this.pass_num = 3
          break;
        case 'tre_pass_data':
          // this.activeDetail = 'for_pass_data'
          // this.activeStatus = 'for_pass_status'
          // this.activeWin = 'for_win_status'
          // this.openKey = 'for_open_key'
          this.pass_num = 4
          break;
        case 'for_pass_data':
          // this.activeDetail = 'fiv_pass_data'
          // this.activeStatus = 'fiv_pass_status'
          // this.activeWin = 'fiv_win_status'
          // this.openKey = 'fiv_open_key'
          this.pass_num = 5
          break;
        case 'fiv_pass_data':
          this.activeDetail = 'one_pass_data'
          // this.activeStatus = 'fiv_pass_status'
          // this.activeWin = 'fiv_win_status'
          // this.openKey = 'fiv_open_key'
          this.pass_num = 1
          break;
      }
      this.openChallenge.step = this.pass_num
      this.AnimationsPopup = false
      this.drawList = []
      next_step({
        open_challenge_id: this.openChallenge.id,
        challenge_id: this.$route.query.challenge_id,
        pass_num: this.pass_num
      }).then(async () => {
        console.log('获取接口成功')
        await this.getOpenChallengeDetailTwo()
      })
      // this.getChallengeDetail()
    },
    // 挑战失败重新开始
    restart() {
        this.activeDetail = 'one_pass_data'
        this.activeStatus = 'one_pass_status'
        this.activeWin = 'one_win_status'
        this.activeBarrier = 'one_pass_barrier'
        this.pass_num =  1
        this.AnimationsPopup = false
        this.drawList = []
        setTimeout(()=>{
          this.getChallengeDetail()
        },10)
    },
    // 结束挑战放入背包
    receivePassSkin() {
      this.AnimationsPopup = false
      receive_pass_skin({
        open_challenge_id: this.openChallenge.id -0,
        challenge_id: this.$route.query.challenge_id,
        pass_num: this.pass_num
      }).then(res => {
        console.log(res)
        this.$message.success('结束挑战成功！饰品已自动放入背包')
        this.activeDetail = 'one_pass_data'
        this.activeStatus = 'one_pass_status'
        this.activeWin = 'one_win_status'
        this.activeBarrier = 'one_pass_barrier'
        this.pass_num = 1

        this.drawList = []
        setTimeout(()=>{
          this.getChallengeDetail()
        },10)

      })
    },
    // 第五关重新开始
    fivRestart(){
      this.AnimationsPopup = false
      this.$message.success('全部挑战成功！饰品已自动放入背包')
      this.activeDetail = 'one_pass_data'
      this.activeStatus = 'one_pass_status'
      this.activeWin = 'one_win_status'
      this.activeBarrier = 'one_pass_barrier'
      this.pass_num = 1

      this.drawList = []
      setTimeout(()=>{
        this.getChallengeDetail()
      },10)
    }
  }
}
</script>
<style>
.file-move {
  transition: transform 0.5s;
}
.file {
  position: absolute;
}
</style>
<style scoped lang="scss">

.introduced {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 90;
  transition: all 0.15s linear;
}
.BoxShowBodyOpenTake {
  position: fixed !important;
  left: 10%;
  top: 15%;
  z-index: 91;
}
.game-des{
  width: 49px;
  height: 103px;
  background: url("@/assets/images/card/game-des.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  right: -109px;
  padding:0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00FFFC;
  font-size: 14px;
}
.card-main{
  width: 1139px;
  margin: 0 auto;
  position: relative;
}
.card-body{
  width: 100%;
  height: 100%;
  background: url("https://oss.n7buff.com/web/images/card/card-bg.png") no-repeat;
  background-size: cover;
  padding-top: 24px;
  overflow: auto;
}
.card-title{
  width:960px ;
  height: 43px;
  background: url("@/assets/images/card/title.png") no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
  display: flex;
  line-height: 43px;
  justify-content: center;
  span{
    font-size: 20px;
    font-weight: bold;
  }
}
.card-nav{
  width: 100% ;
  ul{
    display: flex;
    align-items: center;
    margin-top: 26px;
    li{
      width: 125px;
      height: 130px;
      //border-radius: 50%;
      //overflow: hidden;
      margin-right: 128px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      //background-image: url("@/assets/images/card/nopass.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;
      .card-nav-qiang-box{
        width: 90%;
        margin: 0 auto;
        position: absolute;
        top: 0;
        z-index: 2;
        display: flex;
        justify-content: center;
        img{
          width: 80%;
        }
        .card-nav-qiang-title{
          width: 80%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          position: absolute;
          margin: 0 auto;
          bottom: 0;
          color: #00FFFC;
          font-size: 11px;
        }
      }
      .card-nav-title{
        width: 120px;
        font-size: 11px ;
        color: #FFFFFF;
        position: absolute;
        z-index: 2;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .card-nav-title-active{
        background-image: url("@/assets/images/card/active-text-bg.png") !important;
      }
      .card-nav-title-pass{
        background-image: url("@/assets/images/card/pass-text-bg.png") !important;
      }
      .card-nav-title-nopass{
        background-image: url("@/assets/images/card/nopass-text-bg.png") !important;
      }
      .card-nav-title-click-active{
        background-image: url("@/assets/images/card/click-active-text-bg.png") !important;
      }
      .card-nav-image{
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
      }
      .card-nav-des{
        font-size: 16px ;
        color: #FFFFFF;
        margin-top: 5px;
        position: relative;
        z-index: 2;
      }
      &:last-of-type{
        margin-right: 0;
        &::after{
          display: none;
        }
      }
      &::after{
        content: '';
        width: 45px;
        height: 30px;
        position: absolute;
        background: url("@/assets/images/card/jiantou.png") no-repeat;
        background-size: 100% 100%;
        right: -67%;
      }
    }
  }
}
.nopass{ background-image: url("@/assets/images/card/nopass.png"); }
.pass{ background-image: url("@/assets/images/card/pass.png"); }
.active{
  border-radius: 50%;
  overflow: hidden;
  //background-image: url("@/assets/images/card/active.png") !important;
  box-shadow: 0 0 0.09766rem 0.01302rem #7debec, 0 0 0.13021rem 0.01302rem #7debec inset;
  animation: activeRotate 5s linear infinite running;
}
// 奖牌区域
.encourageRegion{
  display: flex;
  margin-top: 26px;
}
.encourage-body{
  width: 157px;
  .encourage-li{
    width: 100%;
    height: 79px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("@/assets/images/card/pass-li-bg.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 20px;
    &::after{
      content: "";
      width: 15px;
      height: 11px;
      position: absolute;
      background: url("@/assets/images/card/sanjiao.png") no-repeat;
      background-size: 100% 100%;
      bottom: -10px;
    }
    .suo{
      width: 15px;
      height: 19px;
      position: absolute;
    }
    &:last-of-type{
      margin-bottom: 0;
      &::after{
        display: none;
      }
    }
    .encourage-li-title{
      font-size: 12px;
      color: #00FFFC;
      position: absolute;
      left: 9px;
      top: 8px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-right: 20px;
    }
    img{
      width: 50%;
      margin: 0 auto;
      display: block;
    }
    .encourage-li-bottom{
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 10px;
      width: 100%;
      padding: 0 10px;
      .encourage-li-bottom-price{
        display: flex;
        //align-items: center;
        font-size: 11px;
        color: #FF7B07;
        i{
          font-style: normal;
          height: 15px;
          line-height: 18px;
          display: block;
        }
        img{
          width: 12px;
          height: 12px;
          margin-right: 4px;
          margin-top: 2px;
        }
      }
      .encourage-li-bottom-name{
        font-size:11px;
        color: #FFFFFF;
        margin-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}




// 选中转圈

@keyframes activeRotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
.card-licensing-area{
  //width: 100%;
  min-width: 963px;
  max-width: 963px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 10px;
  margin-left: 17px;
  .card-item{
    width: 163px;
    height: 217px;
    margin-right: 34px;
    margin-bottom: 38px;
    position: relative;
    transform: translate(0);
    &:nth-child(5n){
      margin-right: 0;
    }
    .card{
      width: 100%;
      height: 100%;
      background: url("@/assets/images/card/opposite.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      display: flex;
      justify-content: center;
      z-index: 2;
      backface-visibility: hidden;
      .card-open-price{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 30px;
        img{
          width: 16px;
          height: 16px;
          display: block;
          margin-right: 2px;
        }
        span{
          color: #FF7B07;
          font-size: 14px;
          line-height: normal;
        }
      }
      .card-open-btn{
        width: 74px;
        height: 34px;
        display: flex;
        justify-content: center;
        line-height: 32px;
        font-size: 11px;
        background: url("@/assets/images/card/card-btn.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        bottom: -5px;
        margin: 0 auto;
        cursor: pointer;
      }
    }
    .contxt{
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: url("@/assets/images/card/straight.png") no-repeat;
      background-size: 100% 100%;
      transform: rotateY(0deg);
      opacity: 1;
      backface-visibility: hidden;
      .card-contxt-title{
        width: 45%;
        margin: 0 auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        padding-top: 10px;
        font-size: 14px;
      }
      .card-context-img{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
        img{
          width: 80%;
          transform: rotate(30deg);
        }
      }
    }
    .activeItem{
      background-image: url("@/assets/images/card/straight-active.png") !important;
    }
  }
}
.cardAnimate {
  animation: rotetaCard 0.5s ease-in;
  animation-fill-mode: forwards;
}
.cardAnimate2{
  animation: rotetaCard 0.5s ease-in;
  animation-fill-mode: backwards;
  animation-iteration-count: 1;
}
/* 正面 */
.contxtAnimate {
  animation: contxtRotate 0.5s ease-in;
  animation-fill-mode: forwards;
}
.contxtAnimate2{
  animation: contxtRotate 0.5s ease-in;
  animation-fill-mode: backwards;
  animation-iteration-count: 1;
}
/* 正面卡牌翻牌动画 */
@keyframes contxtRotate1 {
  from {
    transform: rotateY(180deg);
    opacity: 1;
  }

  to {
    transform: rotateY(0deg);
    opacity: 1;
  }
}
@keyframes contxtRotate {
  from {
    transform: rotateY(180deg);
    opacity: 1;
  }

  to {
    transform: rotateY(0deg);
    opacity: 1;
  }
}

/* 背面卡牌翻牌动画 */
@keyframes rotetaCard {
  from {
    transform: rotateY(0);
  }

  to {
    transform: rotateY(180deg);
  }
}

</style>
