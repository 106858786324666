<!-- 开箱 -->
<template>
	<div class="body">
		<div :class="[{ introduced: AnimationsPopup },{ introduced: GamePlayShowModal }]"></div>

		<BodyBackground />
<!--		<HomeBodyAlert class="body-alert" />-->
<!--		<img class="body-background" src="https://oss.n7buff.com/web/images/showImages/top_bak.png" alt="" />-->
    <div class="free-title" >
      <img class="free-title-off" src="@/assets/images/2title.png" alt="" />
      <div class="free-title-off-myOne free-title-off-one" :style="VPointer()" >等级箱子</div>
      <div class="free-title-off-myTow" :style="VPointer()" @click="$router.push({ path: '/CdkBox' })" >口令箱子</div>
      <div class="paly-text" :style="VPointer()" @click="GamePlay">
        玩法介绍
      </div>
    </div>
		<div class="InBox">

      <!--  标题    -->
<!--      <div class="grade-title">-->
<!--        <span>等级箱子</span>-->
<!--      </div>-->
      <div class="grade-speed-box">
        <div class="grade-speed">
          <div class="grade-icon">
            <img :src="gradeData.grade_img" alt="">
          </div>
          <span class="grade-num" v-if="gradeData.grade_num !== 5">XP  {{ $store.state.userInfo.grade_amount || 0 }}/{{ gradeData.end_num }}</span>
          <span class="grade-num" v-if="gradeData.grade_num === 5">XP  {{ $store.state.userInfo.grade_amount }}</span>
          <div class="grade-line" v-if="gradeData.grade_num !== 5" :style="'width:'+ ($store.state.userInfo.grade_amount/gradeData.end_num)*100+'%'">

          </div>
          <div class="grade-line" v-if="gradeData.grade_num === 5" style="width: 100%;">

          </div>
        </div>

      </div>

      <!-- 轮播图  -->
      <div class="lunbo">
        <carousel-3d
          v-if="boxDataList.length"
          :autoplay="false"
          :autoplayTimeout="3000"
          :perspective="340"
          :space="handleResize()"
          :display="5"
          :animationSpeed="1000"
          :width="251"
          :height="365"
          :border="0"
          :on-slide-change="onSlideChanged"
        >
          <slide  v-for="(item, i) in boxDataList" :index="i" :key="i">
            <template slot-scope="obj">
<!--              <img :src="item.src" @click="imgClick(item,obj)" />-->
              <div class="lunbo-li" @click="imgClick(item,obj,i)">
                <div class="lunbo-box">
                  <img class="lunbo-box-box" :src="item.img_main" alt="" />
                  <img class="lunbo-box-res" :src="item.img_active" alt="" />
                </div>

                <div class="lunbo-li-title">{{ item.name }}</div>
                <div class="lock-btn" v-if="$store.state.userInfo.grade_id >= item.id">
                  已解锁
                </div>
                <div class="lock-btn" v-else>
                  <img src="@/assets/images/GradeBox/lock.png" alt="">
                  锁定
                </div>
              </div>
            </template>
          </slide>
        </carousel-3d>

      </div>
      <!--  开箱    -->
      <div class="box-main">
        <div class="box-main-hover" :style="VPointer()">
          <img class="body-main-openBox-box" :src="boxData.img_main" alt="" />
          <img class="body-main-openBox-res" :src="boxData.img_active" alt="" />
        </div>
        <!-- 开启按钮 -->
        <div @click="open(boxDataList[activeBox].id)" class="box-main-openBut" :style="VPointer()" >
          免费{{open_box_num}}次
          </div>
        <p class="box-des" v-if="boxDataList.length">{{ boxDataList[activeBox].remark }}</p>
      </div>

			<BoxShowPopup class="BoxShowBodyOpenTake" v-show="AnimationsPopup && skinsList"
				:visible.sync="AnimationsPopup" :count="count" :resultList="resultList" :skinsList="skinsList"
				:show_prize="show_prize.show" />
			<!-- 多开 -->
			<portrait v-if="showAnimations" :boxDatas="boxData" :goodsList="boxData.skins" :prize_data="prize_data"
				:showSounds="showSounds" @end="end" ref="portrait"></portrait>
      <!--  玩法介绍    -->
      <div
        class="fight-body-battleground-but-ShowModal"
        v-show="GamePlayShowModal"
      >
        <GamePlay :visible.sync="GamePlayShowModal" />
      </div>
		</div>
	</div>
</template>

<script>
	import transverse from './transverse.vue'
	import portrait from './portrait.vue'
	// import BoxShowPopup from '../../components/boxShow/BoxShowPopup.vue'
	import BoxShowPopup from '../../components/boxShow/BoxShowPopup.vue'
	import BodyBackground from '../../components/BodyBackground.vue'
	import HomeBodyAlert from '../../components/HomeBodyAlert.vue'
	import {
		open_box,
		get_open_box_record,
		get_drop_record
	} from '@/api/index/boxCorrelation'
  import { Carousel3d, Slide } from 'vue-carousel-3d'
  import {get_open_box_num, open_grade_box} from "@/api/index/Grade";
  import GamePlay from "@/views/Grade/components/GamePlay.vue";

  export default {
		name: 'BoxShowBody',
		components: {
      GamePlay,
      Carousel3d,
      Slide,
			transverse,
			portrait,
			BoxShowPopup,
			BodyBackground,
			HomeBodyAlert
		},
    computed: {
      carouselWidth() {
        const pixelValue = 500; // 设置组件的宽度，单位为像素
        const fontSize = window.innerWidth;
        const remValue = fontSize / 5;
        return remValue;
      },
      carouselHeight() {
        const pixelValue = 300; // 设置组件的高度，单位为像素
        return `calc(${pixelValue / 16}rem)`;
      }
    },
		data() {
			return {
				count: 1, // 箱子个数
				showAnimations: true, // 动画
				AnimationsPopup: false, // 弹出层
				audio: new Audio('https://oss.n7buff.com/web/audio/Shoot.mp3'), //过渡声音
				current: new Audio('https://oss.n7buff.com/web/audio/roll9.mp3'), //过程声音
				show_audio: new Audio('https://oss.n7buff.com/web/audio/Shoot.mp3'), //过程声音
				prize_data: [],
				show_prize: {
					//奖品信息(展示所用)
					show: false,
					list: []
				},
				recordList: [], // 开箱历史
				resultList: [], // 结果
				skinsList: [], // 动画总数据,
				showSounds: true,
				timer: null,
        open_box_num: 0, //开启次数
        activeBox: 0,
        gradeData: {},
        GamePlayShowModal:false,
        pageWidth: 0,
        carouselShow: false
			}
		},
		props: {
      boxDataList: {
        type: Array,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: []
      },
      boxData:Object,
      gradeList: Array
		},
		watch: {
			// 监听弹出层的变化
			AnimationsPopup() {
				if (!this.AnimationsPopup) {
					this.audio.pause()
				}
			},
      boxDataList() {
				if (this.boxDataList.length > 0) {
          console.log('1111')
          this.$emit('getBoxDataList', this.boxDataList[0].id)
          if (!sessionStorage.getItem('n7_token')) {
            return
          }
          this.getOpenBoxNumApi(this.boxDataList[0].id)
				}
			},
      gradeList() {
        if (!sessionStorage.getItem('n7_token')) {
          this.getGrade(0)
        }else {
          this.getGrade(this.$store.state.userInfo.grade_amount)
        }

      },
      pageWidth(){

      },
		},
		created() {
      this.carouselShow = true
			if (this.$route.name == 'BoxShow') {
				this.getOpenBoxRecordApi()
			} else {
				this.timer = null
			}
		},
    mounted() {
      window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize); // 移除事件监听
    },
    methods: {
			// 开箱动画数据
			// async getBoxDataApi() {
			//   const res = await get_box_data({ id: this.$route.query.id })
			//   this.skinsList = res.data.skins
			//   console.log(this.skinsList)
			// },
      onSlideChanged (index) {
        console.log(index)
        this.activeBox = index
        this.$emit('getBoxDataList', this.boxDataList[this.activeBox].id)
        if (!sessionStorage.getItem('n7_token')) {
          return
        }
        this.getOpenBoxNumApi(this.boxDataList[this.activeBox].id)
      },
      handleResize() {
        const fontSize = window.innerWidth;
        const remValue = fontSize / 5;
        return remValue;
      },
      // 玩法介绍弹出
      GamePlay() {
        this.GamePlayShowModal = true
        var mo = function (e) {
          e.preventDefault()
        }
        document.body.style.overflow = 'hidden'
        document.addEventListener('touchmove', mo, false) //禁止页面滑动
      },
      // 获取当前等级
      getGrade(value) {
        console.log(value)
        const grades = this.gradeList
        for (let i = grades.length - 1; i >= 0; i--) {
          const currentGrade = grades[i];
          if (value >= currentGrade.start_num && value <= currentGrade.end_num) {
            this.gradeData = currentGrade
            console.log(this.gradeData)
            this.$forceUpdate()
            return currentGrade;
          }
        }
        return null; // 如果没有匹配的等级区间，则返回null
      },
      imgClick(data,obj,index) {
        console.log(data,obj)
        this.activeBox = index
        this.$emit('getBoxDataList', data.id)
        if (!sessionStorage.getItem('n7_token')) {
          return
        }
        this.getOpenBoxNumApi(data.id)
      },
      //  获取等级盲盒开启次数
      async getOpenBoxNumApi(id){
        const res = await get_open_box_num({grade_box_id: id})
        // console.log(res)
        this.open_box_num = res.data.open_box_num
      },
			// 开箱记录
			getOpenBoxRecordApi() {
				get_drop_record({
					box_id: this.$route.query.id,
					size: 20
				}).then((res) => {
					this.recordList = res.data
					this.getTimeOpenBoxRecordApi()
				})
			},
			getTimeOpenBoxRecordApi() {
				this.timer = setInterval(() => {
					get_drop_record({
					box_id: this.$route.query.id,
					size: 20
				}).then((res) => {
						this.recordList = res.data
					})
				}, 2000)
			},
			// 点击开启按钮
			open(id) {
				// 判断用户是否登录
				//console.log("sessionStorage.getItem('n7_token')" + sessionStorage.getItem('n7_token'))
				if (!sessionStorage.getItem('n7_token')) {
					this.$store.dispatch('setLogSignRetrieve', true)
					return
				}

				clearInterval(this.timer)
				this.timer = null
        open_grade_box({
          grade_box_id: id,
					// total: this.count
				}).then((res) => {
					//console.log(res.data)
					if (res.status === 1) {
						this.$store.dispatch('getPlayerApi')
            this.open_box_num --
            this.getOpenBoxNumApi(id)

						this.resultList = res.data
						let list = res.data
						this.prize_data = res.data // list.filter(item => item.label == null)
						if (this.showAnimations) {
							//动画开启
							this.Dijah = true
							// this.current.play()
							if (this.resultList.length > 1) {
								this.$refs.portrait.open(this.resultList.length)
							} else {
								this.$refs.portrait.open(this.resultList.length)
							}
						} else {
							//动画关闭
							this.$nextTick(() => {
								this.show_prize = {
									show: true,
									list: this.resultList
								}
								this.AnimationsPopup = true
								if (this.showSounds) {
									this.show_audio.play()
								}

							})
							this.getTimeOpenBoxRecordApi()

						}
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			// 数量设置
			countImg(count) {
				this.count = count
			},
			// 声音开关
			showSound() {
				this.showSounds = !this.showSounds
			},
			// 动画开关
			showAnimation() {
				if (this.showAnimations) {
					this.showAnimations = false
				} else {
					this.showAnimations = true
				}
			},
			//重置动画效果参数
			end() {
				if (this.showSounds) {
					this.show_audio.play()
				}
				this.AnimationsPopup = true
				this.TranslateY = 80; //箱子上浮距离重置
				this.show_prize = {
					show: true,
					list: this.prize_data.list
				}
				this.prize_data = []
				this.getTimeOpenBoxRecordApi()
			},
			finishCallback(data, str) {
				// console.log('data, str: ', data, str)
				setTimeout(() => {
					this.$nextTick(() => {
						this.prize_data = {
							show: str,
							list: data
						}
					})
				}, 550)
			},
		},
		//销毁定时器
		beforeDestroy() {
			clearInterval(this.timer)
			this.timer = null
		}
	}
</script>
<style lang="scss" scoped>
	@import '@/styles/BoxShowBody.scss';
  @import '@/styles/BlindBoxFight.scss';
  @import '@/styles/FreeSkinBody.scss';
	.introduced {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.9);
		z-index: 90;
		transition: all 0.15s linear;
	}

	.BoxShowBodyOpenTake {
		position: fixed;
		left: 10%;
		top: 15%;
		z-index: 91;
	}
  .fight-body-battleground-but-ShowModal{
    z-index: 99;
    position: relative;
    //margin-top: 10vh;
  }
  .grade-title{
    width: 100%;
    height: 77px ;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("@/assets/images/GradeBox/title-bg.png") no-repeat;
    background-size: 100% 100%;
    span{
      font-size: 24px;
      color: #FFFFFF;
      font-weight: bold;
    }
  }
  .body{
    padding-top: 50px;
  }
  .InBox{
    padding: 0 145px;
  }
  .grade-speed{
    width: 700px;
    height: 32px;
    margin: 0 auto;
    margin-top: 20px;
    background: url("@/assets/images/GradeBox/speed-bg.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    align-items: center;
    //justify-content: center;
    padding: 0 14px;
    .grade-line{
      width: 0%;
      height: 16px;
      position: relative;
      background: #5CCEF3;
      //transform: perspective(5px) rotateX(-5deg);
      //&:after{
      //  content: '';
      //  width: 18px;
      //  height: 28px;
      //  background: url("@/assets/images/GradeBox/speed-left.png") no-repeat;
      //  background-size: 100% 100%;
      //  position: absolute;
      //  left: 0;
      //  top: -4px;
      //}
    }
    .grade-num{
      font-size: 12px;
      position: absolute;
      right: 20px;
      z-index: 2;
    }
    .grade-icon{
        width: 45px;
        position: absolute;
        left: -50px;
        img{
          width: 100%;
        }
    }
  }
  // 轮播图
  .lunbo{
    display: flex;
    margin-top: 10px;
    .lunbo-li{
      width: 251px;
      height: 345px;
      background: url("@/assets/images/GradeBox/lunbo-bg.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 0;
      .lunbo-box{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 179px;
        height: 143px;
        position: relative;
        margin: 0 auto;
        margin-top: 38px;
        .lunbo-box-box{
          height:163px ;
        }
        .lunbo-box-res{
          height: 98px;
          position: absolute;
          top: 10%;
        }
      }
      .lunbo-li-title{
        text-align: center;
        font-size:24px ;
        margin-top: 20px;
      }
      .lock-btn{
        width: 133px;
        height: 33px;
        background: url("@/assets/images/GradeBox/lock-bg.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: 30px;
        color: #272636;
        img{
          width: 22px;
          height: 22px;
        }
      }
    }
    .current{
      .lunbo-li{
        background: url("@/assets/images/GradeBox/lunbo-bg-active.png") no-repeat;
        background-size: 100% 100%;
      }
      .lock-btn{
        background: url("@/assets/images/GradeBox/lock-bg-active.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
//  开箱主体
  .box-main{
    width: 80%;
    height: 378px;
    background: url("@/assets/images/GradeBox/open-bg.png") no-repeat bottom;
    background-size: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 100px;
    .body-main-openBox-box{
      //width: 623px;
      height: 360px;
      top: 17%;
    }
    .body-main-openBox-res{
      //width: 404px;
      height: 207px;
      top: 7%;
    }
    .box-main-openBut{
      width: 204px;
      height: 54px;
      position: absolute;
      bottom: 60px !important;
      background: url("@/assets/images/GradeBox/box-open.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      font-size: 22px;
      color: #FFFFFF;
      font-weight: bold;
      line-height: 58px;
    }
    .box-des{
      text-align: center;
      font-size: 16px;
      color: #FFFFFF;
      position: absolute;
      bottom: -5px;
    }
  }
  .carousel-3d-slide{
    background: transparent !important;
  }
  .grade-speed-box{
    position: relative;
    z-index: 2;
    display: flex;
    //align-items: center;


  }
  .paly-text{
    width: 85px;
    height: 42px;
    background: url("@/assets/images/GradeBox/paly.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:14px;
    color: #4EFEFE;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -10px;
  }
  .carousel-3d-slide img{
    width: auto;
  }
  .carousel-3d-slide,.carousel-3d-slider{
    width: 251px !important;
    height: 345px !important;
  }
  .carousel-3d-container{
    height: 345px !important;
  }
</style>
