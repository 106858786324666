<!-- HomeBody -->

<template>
  <div class="body">
    <!-- <RedPackets/> -->
    <BodyBackground/>
	<div style="height:0.0005rem;"></div>
    <HomeBodyAlert />
    <HomeBodyShow />
    <HomeBodyTitleOne />
  </div>
</template>

<script>
import BodyBackground from '@/components/BodyBackground.vue'
import HomeBodyAlert from '@/components/HomeBodyAlert.vue'
import HomeBodyShow from '@/components/Home/HomeBodyShow.vue'
import HomeBodyTitleOne from '@/components/Home/HomeBodyTitleOne.vue'
// import RedPackets from '@/components/Home/RedPackets.vue'
export default {
  name: 'HomeBody',
  components: {
    // RedPackets,
    BodyBackground,
    HomeBodyAlert,
    HomeBodyShow,
    HomeBodyTitleOne,
    // HomeBodyTitleTwo
    
  },
  data() {
    return {}
  },
  created() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
</style>
