<!-- 充值 -->
<template>
  <div
    class="topUp"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
  >
    <div class="closebut" :style="VPointer()" @click="closebut">
      <img src="@/assets/images/userWidget/close.png" alt="" />
    </div>
    <div class="topUpBody">
      <div class="left">
        <div class="WeChat">
          <div
            class="aisle"
            v-for="item in playerList"
            :key="item.id"
            @click="selectAisle(item.id)"
            :style="VPointer()"
          >
            <img
              v-show="pay_type_id !== item.id"
              class="aisleImg"
              src="@/assets/images/topUpImg/passageway.png"
              alt=""
            />
            <img
              v-show="pay_type_id === item.id"
              class="aisleImg"
              src="@/assets/images/topUpImg/passageway2.png"
              alt=""
            />
            <div
              class="aisleText"
              :style="
                pay_type_id === item.id ? 'color: #eece3a' : 'color: #22fffc'
              "
            >
              {{ item.title }}
            </div>
            <img
              v-if="item.mode === 'weixin'"
              class="WeChatIco"
              src="@/assets/images/topUpImg/wechat.png"
              alt=""
            />
            <img
              v-else
              class="WeChatIco"
              src="@/assets/images/topUpImg/alipay.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="rightImg">
        <img src="@/assets/images/personalCenter/right.png" alt="" />
      </div>
      <div class="centre">
        <div class="centreTitle">
          如遇到任何问题请联系客服解决，竭诚为您服务
        </div>
        <div class="centreBody">
          <div class="recharge">
            <ul>
              <li
                :class="recharge_id === item.id ? 'liBack' : 'liBackS'"
                v-for="(item, i) in rechargeList"
                :key="item.id"
                :style="VPointer()"
                @click="
                  rechargeListApi(
                    item.pay_recharge_amount,
                    item.show_recharge_amount,
                    item.show_give_amount,
                    item.id,
                    i
                  )
                "
              >
                <div class="rechargeImg">
                  <img :src="item.image_url" alt="" />
                </div>
                <div class="rechargeText">{{ item.show_recharge_amount }}</div>
                <div class="giveAmount" v-if="item.show_give_amount > 0">
                  <img src="@/assets/images/topUpImg/Gift box.png" alt="" />
                  <div>送 {{ item.show_give_amount }}</div>
                </div>
              </li>
            </ul>
          </div>
          <div class="right">
            <div class="money">
              <div>
                实付金额: <span> {{ pay }}</span>
              </div>
              <div>
                到账金额:<span class="tow"> {{ show + showGiveAmount }} </span>
              </div>
            </div>
            <div class="agreement">
              <el-checkbox v-model="checked"></el-checkbox>
              本人已满18周岁且具备完全行为能力，充值即代表同意
              <span :style="VPointer()" @click="goHelp(3)">《用户协议》</span>
              、
              <span :style="VPointer()" @click="goHelp(2)">《隐私政策》</span>
              。盲盒娱乐请理性消费，切勿上头
            </div>
            <div class="atTopUp" :style="VPointer()" @click="rechargeApi"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="paymentCode" v-if="paymentCode">
      <div class="paymentCode-title">
        <img
          class="pay_type_img"
          :src="pay_type_img"
          alt=""
        />
		<p>{{activeName}}</p>
      </div>
      <div class="closebutCode" :style="VPointer()" @click="closebutCode">
        <img src="@/assets/images/userWidget/close.png" alt="" />
      </div>
      <div id="qrcode" ref="qrCodeUrl"></div>
      <div class="paymentCode-ticp">您的二维码将在 {{times}} 秒后失效</div>
    </div>
  </div>
</template>

<script>
import { get_player, get_recharge_list, recharge } from '@/api/index/TopUp'
import QRCode from 'qrcodejs2'
export default {
  name: 'topUp',
  data() {
    return {
      rechargeIndex: '',
      paymentCode: false, // 支付二维码
      modePayment: 1, // 选择通道
      checked: false, // 协议选择
      playerList: [], //充值通道列表
      rechargeList: [], //充值通道下可充值额度列表
      pay_type_id: null, //  选中充值通道列表id
      recharge_id: null, // 选中充值额度id
	  wechat_img:require('@/assets/images/topUpImg/wechat.png'),
	  alipay_img:require('@/assets/images/topUpImg/alipay.png'),
	  pay_type_img:'',
      pay: 0, // 实际支付金额
      show: 0, //	显示到账金额
      showGiveAmount: 0, //赠送金额
      code_url: '', // 充值二维码
      activeName:"",
      times:30,
      timer:null
    }
  },
  props: {
    visible: Boolean
  },
  watch: {
    visible(val) {
      if (val) {
        this.getPlayerApi()
        this.getRechargeListApi()
        this.getRechargeList()
      }
    }
  },
  beforeDestroy(){
    clearInterval(this.timer)
  },
  created() {},
  methods: {
    goHelp(e) {
      this.$router.push('/Helpcenter?id='+e)
      this.$parent.topUpRetrieve=false
 },
    // 获取充值通道列表
    getPlayerApi() {
      get_player().then((res) => {
        this.playerList = res.data
        this.pay_type_id = res.data[0].id
      })
    },
    // 选中充值通道
    selectAisle(id) {
      this.pay_type_id = id
      this.getRechargeListApi(id)
    },

    getRechargeListApi() {
      // 获取充值通道下可充值额度列表
      get_recharge_list({ pay_type_id: this.pay_type_id }).then((res) => {
        this.rechargeList = res.data
      })
    },
    // 默认选中充值额度
    getRechargeList() {
      get_recharge_list({ pay_type_id: this.pay_type_id }).then((res) => {
        this.pay = Number(res.data[0].pay_recharge_amount)
        this.show = Number(res.data[0].show_recharge_amount)
        this.recharge_id = res.data[0].id
      })
    },
    // 选择充值的额度
    rechargeListApi(pay, show, showGiveAmount, id, index) {
      this.pay = Number(pay)
      this.show = Number(show)
      this.showGiveAmount = Number(showGiveAmount)
      this.recharge_id = id
      this.rechargeIndex = index
    },
    // 充值
    rechargeApi() {
      if (this.checked) {
        recharge({
          pay_type_id: this.pay_type_id,
          recharge_id: this.recharge_id
        }).then((res) => {
          if (res.status === 1) {
			  this.activeName=this.playerList.filter(e=>e.id==this.pay_type_id)[0].title
			  if(this.playerList.filter(e=>e.id==this.pay_type_id)[0].mode == 'alipay'){
			  			  this.pay_type_img = this.alipay_img
			  }else if(this.playerList.filter(e=>e.id==this.pay_type_id)[0].mode == 'weixin'){
			  			  this.pay_type_img = this.wechat_img
			  }
            this.code_url = res.data.code_url
            this.times=30
            this.rechargeS()
          } else {
            this.$message.error(res.msg)
          }
        })
      } else {
        this.$message.error('请阅读以下协议并勾选同意')
      }
    },
    rechargeS() {
      this.paymentCode = true
      setTimeout(() => {
        this.getCoed()
        this.$nextTick(()=>{
          this.timer=setInterval(() => {
            this.times--
            if(this.times<=0){
              clearInterval(this.timer)
              this.closebutCode()
            }
          },1000);
        })
      }, 0)
    },
    //  生成支付二维码
    getCoed() {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        width: 200, // 宽
        height: 200, // 高
        text: this.code_url
      })
    },
    // 关闭二维码
    closebutCode() {
	  this.$store.dispatch('getPlayerApi')
      this.paymentCode = false
      clearInterval(this.timer)
      setTimeout(() => {
        this.$store.dispatch('getPlayerApi')
      }, 1000)
    },
    // 关闭
    closebut() {
		this.$store.dispatch('getPlayerApi')
      this.checked = false
	  this.paymentCode = false
      this.$emit('update:visible', false)
			clearInterval(this.timer)
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = '' //出现滚动条
      document.removeEventListener('touchmove', mo, false)
    }
  }
}
</script>
<style lang="scss" scoped>
// 引入css类
// @import '';
@import '@/styles/variables';
.paymentCode {
  position: fixed;
  text-align: center;
  padding: 10px;
  border-radius: 3px;
  background: #1ef0ed;
  margin-top: 0.3rem;
  border-radius: 0.1rem;
}
.pay_type_img{
  height: 0.3rem;
}
.paymentCode-ticp{
  text-align: center;
  color: #000;
  margin-top: 5px;
}
.paymentCode-title{
  text-align: center;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
  font-size: 20px;
}
.closebutCode {
  position: absolute;
  top: -5%;
  right: -5%;
  z-index: 2;
  img{

  width: 40px;
  height: 40px;
  }
}
::v-deep #qrcode {

  img{
    width: 300px;
  height: 300px;
   display: inline-block !important;
  }
}
.topUp {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .closebut {
    position: absolute;
    top: -3%;
    right: 0%;
    img {
      width: 70px;
    }
  }
  .topUpBody {
    width: 1200px;
    height: 600px;
    background: url('@/assets/images/fightImages/rectangle.png') no-repeat;
    background-size: 1200px 600px;
    padding: 20px 50px;
    display: flex;
    .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      margin-top: 50px;

      .WeChat {
        height: 500px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-evenly;
        .aisle {
          position: relative;
          height: 50px;
          .aisleImg {
            height: 50px;
          }
          .aisleText {
            position: absolute;
            top: 45%;
            left: 60%;
            transform: translate(-50%, -50%);
            color: #eece3a;
            width: 200px;
            font-weight: 700;
          }
          .WeChatIco {
            width: 25px;
            position: absolute;
            left: 15%;
            top: 20%;
          }
        }
      }
    }
    .rightImg {
      img {
        height: 550px;
      }
    }
    .centre {
      width: 850px;
      margin: 20px 0px 0 20px;
      .centreTitle {
        // margin-bottom: 30px;
      }
      .centreBody {
        height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .recharge {
          ul {
            width: 900px;
            display: flex;
            flex-wrap: wrap;
            overflow-x: auto;
            .liBack {
              width: 120px;
              height: 160px;
              background: url('@/assets/images/topUpImg/check.png')
                no-repeat !important;
              background-size: 120px !important ;
              position: relative;
              margin-right: 20px;
              margin-top: 20px;
            }
            .liBackS {
              width: 120px;
              height: 160px;
              background: url('@/assets/images/topUpImg/Recharge box.png') no-repeat !important;
              background-size: 120px !important ;
              position: relative;
              margin-right: 20px;
              margin-top: 20px;
            }
            li {
              width: 120px;
              height: 160px;
              // background: url('@/assets/images/topUpImg/Recharge box.png') no-repeat;
              // background-size: 120px;
              position: relative;
              margin-right: 20px;
              margin-top: 20px;
              .rechargeImg {
                position: absolute;
                top: 49%;
                left: 50%;
                transform: translate(-50%, -50%);
                img {
                  width: 55px;
                }
              }
              .rechargeText {
                position: absolute;
                top: 85%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 25px;
                font-weight: 700;
                color: #eece3a;
              }
              .giveAmount {
                position: absolute;
                width: 100%;
                top: 5%;
                left: -1%;
                & > img {
                  width: 87%;
                  height: 30px;
                }
                & > div {
                  color: $hoverText;
                  font-weight: 700;
                  position: absolute;
                  width: 100%;
                  line-height: 30px;
                  top: 0;
                  left: 0;
                }
              }
              &:nth-child(6) {
                margin-right: 0;
              }
              // &:nth-child(-n + 3) {
              //   margin-top: 0;
              // }
            }
          }
        }
        .right {
          // margin-top: 50px;
          margin-right: 50px;
          display: flex;
          flex-direction: column;
          align-items: center;
          // position: absolute;
          .money {
            display: flex;
            font-size: 18px;
            span {
              color: #eece3a;
              font-weight: 700;
            }
            div {
              &:nth-child(2) {
                margin-left: 100px;
              }
            }
          }
          .agreement {
            font-size: 14px;
            margin: 20px 0;
            width: 820px;
            span {
              color: $theme;
            }
          }
          .atTopUp {
            width: 170px;
            height: 50px;
            background: url('@/assets/images/topUpImg/立即充值拷贝.png')
              no-repeat ;
              background-size: 100% 100%;

          }
        }
      }
    }
  }
}
</style>
