<template>
  <div class="card-body">
    <div class="card-main">
      <div class="card-title">
        <span>斗转星移</span>
      </div>
      <div class="card-list-box">
        <ul class="card-list">
          <li class="card-item" v-for="(item,index) in  list.slice(0,3)" :key="index" :style="VPointer()" @click="$router.push({ path: 'card', query: { challenge_id: item.id } })" @mouseenter="mouse()">
            <div class="card-item-box">
              <img class="card-item-img-img_active" :src="item.img_active" alt="">
              <img class="card-item-img-img_main" :src="item.img_main" alt="">
            </div>
            <img class="card-item-dizuo noactive" src="@/assets/images/card/item-bg-tow.png" alt="">
            <img class="card-item-dizuo active" src="@/assets/images/card/item-bg-tow-active.png" alt="">
            <div class="card-item-bottom">
              <div class="card-item-price"><img src="@/assets/images/card/dzxy.png" alt=""> {{ item.price }}</div>
              <div class="card-item-title">{{ item.name }}</div>
<!--              <div class="card-item-des">{{ item.name }}</div>-->
            </div>
          </li>
        </ul>
        <ul class="card-list">
          <li class="card-item" v-for="(item,index) in  list.slice(3,7)" :key="index" :style="VPointer()" @click="$router.push({ path: 'card', query: { challenge_id: item.id } })">
            <div class="card-item-box">
              <img class="card-item-img-img_active" :src="item.img_active" alt="">
              <img class="card-item-img-img_main" :src="item.img_main" alt="">
            </div>
            <img class="card-item-dizuo noactive" src="@/assets/images/card/item-bg-tow.png" alt="">
            <img class="card-item-dizuo active" src="@/assets/images/card/item-bg-tow-active.png" alt="">
            <div class="card-item-bottom">
              <div class="card-item-price"><img src="@/assets/images/card/dzxy.png" alt=""> {{ item.price }}</div>
              <div class="card-item-title">{{ item.name }}</div>
            </div>
          </li>
        </ul>
        <ul class="card-list">
          <li class="card-item" v-for="(item,index) in  list.slice(7,10)" :key="index" :style="VPointer()" @click="$router.push({ path: 'card', query: { challenge_id: item.id } })">
            <div class="card-item-box">
              <img class="card-item-img-img_active" :src="item.img_active" alt="">
              <img class="card-item-img-img_main" :src="item.img_main" alt="">
            </div>
            <img class="card-item-dizuo noactive" src="@/assets/images/card/item-bg-tow.png" alt="">
            <img class="card-item-dizuo active" src="@/assets/images/card/item-bg-tow-active.png" alt="">
            <div class="card-item-bottom">
              <div class="card-item-price"><img src="@/assets/images/card/dzxy.png" alt=""> {{ item.price }}</div>
              <div class="card-item-title">{{ item.name }}</div>
            </div>
          </li>
        </ul>
        <img class="card-list-box-bg" src="@/assets/images/card/list-bg.png">
      </div>
    </div>
  </div>
</template>
<script>
import {get_challenge_list} from "@/api/index/card";

export default {
  data() {
    return {
      audio: new Audio('https://oss.n7buff.com/web/audio/dui.mp3'),
      list: []
    }
  },
  created() {
    this.getChallengeList()
  },
  methods:{
    // 获取关卡列表
    getChallengeList() {
      get_challenge_list({ type: 'get_challenge_list' }).then(res => {
        console.log(res)
        this.list = res.data
      })
    },
    mouse() {
      // console.log(1);
      // console.log(this.$route.name);
      if (this.$store.state.Audio) {
        //this.audio.play()
        this.audio.play().catch(() => {});
      }
    },
  }
}
</script>
<style scoped lang="scss">
.card-body{
  width: 100%;
  height: 100%;
  background: url("https://oss.n7buff.com/web/images/card/card-bg.png") no-repeat;
  background-size: cover;
  padding-top: 27px;
  overflow: hidden;
  .card-main{
    width: 1200px;
    margin: 0 auto;
  }
  .card-title{
    width: 100% ;
    height: 54px;
    background: url("@/assets/images/card/title.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    display: flex;
    line-height: 54px;
    justify-content: center;
    margin-bottom: 40px;
    span{
      font-size: 20px;
      font-weight: bold;
    }
  }
  .card-item{
    width: 105px;
    height: 108px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    &:hover{
      .card-item-box{
        animation: ress 2s linear infinite;
        background-image: url("@/assets/images/card/item-bg-active.png") !important;
      }
      .noactive{
        opacity: 0;
      }
      .active{
        opacity: 1;
      }
    }
    .active{
      opacity: 0;
    }
    img{
      width: 80%;
      margin: 0 auto;
    }
    .card-item-box{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: url("@/assets/images/card/item-bg.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      z-index: 2;
      .card-item-img-img_active{
        position: absolute;
        top: 20%;
        width: 55%;
        z-index: 1;
      }
      .card-item-img-img_main{
        position: absolute;
        top: 16%;
      }
    }
    .card-item-dizuo{
      position: absolute;
      width: 221px;
      height: 163px;
      bottom: -143px;
    }
    .card-item-bottom{
      position: absolute;
      bottom: -60px;
      z-index: 3;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .card-item-price{
        width: 90px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url("@/assets/images/card/price-bg.png") no-repeat;
        background-size: 100% 100%;
        img{
          width:15px;
          height: 15px;
          display: block;
          margin: 0;
        }
        color:#FF7B07 ;
        font-size: 10px;
      }
      .card-item-title{
        font-size: 16px;
        margin-top: 0px;
        color: #00FFFC;
      }
    }
  }

  @keyframes ress {
    0% {
      top: 0%;
    }
    50% {
      top: -10%;
    }
    100% {
      top: 0%;
    }
  }

  .card-list-box{
    position: relative;
    .card-list-box-bg{
      width: 100%;
      position: fixed;
      left: 0;
      bottom: -60px;
    }
  }
  .card-list{
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2;
    margin-bottom: 60px;
  }
  .card-list:nth-child(odd){
    .card-item{
      margin-right: 165px;
      &:first-of-type{
        margin-left: 256px;
      }
      &:last-of-type{
        margin-right:158px ;
      }
    }
  }
  .card-list:nth-child(even){
    .card-item{
      margin-right: 166px;
      &:first-of-type{
        margin-left: 97px;
      }
      &:last-of-type{
        margin-right: 0;
      }
    }
  }
}
</style>
