<!-- 创建房间 -->
<template>
	<div class="int" :visible="visible" @update:visible="$emit('update:visible', $event)">
		<div class="int-box">
			<div class="int-box-title">
				<div class="int-box-title-text">获取星币</div>
			</div>
			<div class="int-box-frame">
				<div class="int-box-frame-off" :style="VPointer()" @click="off"></div>
				<div class="int-box-frame-content">
					<!-- 下 -->
					<div class="int-box-frame-content-fool">
						<div class="MyBackpack">
							<div class="sort">
								<el-dropdown class="show" trigger="click" placement="bottom">
									<div class="el-dropdown-link" :style="VPointer()">
										{{ sortText.sort.sort }}<i class="el-icon-arrow-down el-icon--right"></i>
									</div>
									<el-dropdown-menu class="dropdownMenu appearance-menus" slot="dropdown">
										<!-- <el-dropdown-item :style="VPointer()">
			      <div @click="concealA(0)">默认排序</div>
			    </el-dropdown-item> -->
										<el-dropdown-item :style="VPointer()" v-for="(item, index) in sortText.conceal"
											:key="item.id">
											<div @click="concealA(index)">
												{{ item.conceal }}
											</div>
										</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>


							<div class="search">
								<input v-model="search_key" placeholder="请输入物品名称" />
								<div class="searchIco" :style="VPointer()" @click="searchStarSkins">
									<i class="el-icon-search"></i>
								</div>
							</div>
						</div>
						<div class="MyBackpackSelect">
							<div class="skins_total">总数:{{ this.total_rows }}</div>
							<div class="price_sum">总价值:{{ this.price_sum }}</div>
							<div class="checkAll">
								<el-checkbox v-model="checkAll" @change="checkAllCheck"></el-checkbox>
								全选
							</div>
							<div class="selected">已选:{{ checkList.length }}</div>

							<div class="recycle">
								<div :style="VPointer()" @click="recoverySkinsApi">兑换星币</div>
							</div>

						</div>
						<!-- 背包物品 -->
						<div class="BackpackItems">
							<ul :class="quantity > 13 ? 'roll' : ''">
								<li v-for="item in rowsList" :key="item.id" :style="[
			    {
			      'background-image': 'url(' + item.rarity_thumb + ')'
			    }
			  ]">
									<div @click.stop="checkedItem(item)" class="underway"
										v-if="item.state_name !== null">
										{{ item.state_name }}...
									</div>
									<div @click.stop="checkedItem(item)" class="subject">
										<img :src="item.image_url" alt="" />
									</div>

									<el-tooltip placement="bottom">
										<div @click.stop="checkedItem(item)" slot="content">{{ item.item_name }}</div>
										<div @click.stop="checkedItem(item)" class="subjectName">
											{{ item.item_name }}
										</div>
									</el-tooltip>
									<div class="fool">
										<div class="price">
											<img src="https://oss.n7buff.com/star_currency.png" alt="" /> {{ item.price }}
										</div>
										<el-checkbox-group class="pitch" v-model="checkList">
											<el-checkbox :label="item.id"> 选中</el-checkbox>
										</el-checkbox-group>
									</div>
								</li>
								<div class="loadMore">
									<el-pagination background hide-on-single-page layout="prev, pager, next"
										@current-change="handleCurrentChange" :page-size="page_size" :total="total_rows"
										prev-text="上一页" next-text="下一页">
									</el-pagination>
									<!-- <button class="btn" :style="VPointer()" @click="handleCurrentChange">上一页</button>
			  <button class="btn_num">{{page}}</button>
			  <button class="btn" :style="VPointer()" @click="handleCurrentChangeTwo">下一页</button> -->
								</div>
							</ul>
						</div>



					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		get_star_package_list,
		exchange_star_skins,
		get_token
	} from '@/api/index/personalCenter'
	export default {
		name: 'GainStar',
		data() {
			return {
				sort: false,
				sortText: {
					TS: '',
					sort: {
						sorts: 'time',
						sort: '按获取时间排序'
					},
					conceal: [{
							sorts: 'time',
							conceal: '按获取时间排序'
						},
						{
							sorts: 'high_price',
							conceal: '按价格由高到低排序'
						},
						{
							sorts: 'low_price',
							conceal: '按价格由低到高排序'
						}
					]
				},
				sorts: 'time', // 排序
				search_key: '', // 饰品名称关键词搜索
				price_sum: 0, //总价值
				page: 1, // 页码
				page_size: 8, // 每页请求数量
				total_rows: null, // 总数据量
				page_count: null, //总页数
				checkAll: false, // 全选
				quantity: 13,
				rowsList: [], //背包物品
				checkList: [], // 选中的id
				token: '' //token令牌
			}
		},
		props: {
			visible: Boolean,
			battleBox: Array
		},
		watch: {

			visible(val) {
			  if (val) {
			    this.getPackageListApi()
			  /*  this.intTime = setInterval(() => {
			      this.getPackageListApi()
			    }, 3000) */
			  }
			},

			/* content() {
				console.log("this.content",this.content)
				if (this.content === 'MyBackpack') {
					if (sessionStorage.getItem('n7_token')) {
						this.$store.dispatch('getPlayerApi')
					}
					this.rowsList = []
					this.player_skins_ids = []
					this.checkList = []
					this.getPackageListApi()
				}
			}, */
			checkList() {
				if (this.checkList.length === 8) {
					this.checkAll = true
				} else {
					this.checkAll = false
				}
			},
			checkAll() {
				if (this.checkAll) {
					this.checkList = []
					console.log(this.rowsList);
					this.rowsList.forEach((item) => {
						this.checkList.push(item.id)
					})
				}
			}
		},
		created() {
			this.getTokenApi()
			this.page = '1'
		},
		methods: {
			// 关闭
			off() {
				this.pitchOn = []
				this.battle_box_data = []
				this.total_box_price = 0.00
				this.$emit('update:visible', false)
				var mo = function(e) {
					e.preventDefault()
				}
				document.body.style.overflow = '' //出现滚动条
				document.removeEventListener('touchmove', mo, false)
			},
			checkedItem(e) {
				for (let i = 0; i < this.checkList.length; i++) {
					const item = this.checkList[i];
					if (item == e.id) {
						this.checkList.splice(i, 1)
						return
					}
				}
				this.checkList.push(e.id)
			},
			// 下拉菜单开关
			sortShow() {
				if (!this.sort) {
					this.sort = true
				} else {
					this.sort = false
				}
			},
			// 下拉菜单排序
			concealA(index) {
				this.sortText.sort.sort = this.sortText.conceal[index].conceal
				this.sorts = this.sortText.conceal[index].sorts
				//console.log(this.sorts)
				this.total_rows = 0
				this.page_size = 8
				this.getPackageListApi()
				// this.sortText.conceal[index].conceal = this.sortText.sort.sort
				// this.sortText.sort.sort = this.sortText.TS
				this.sort = false
			},
			searchStarSkins(){
				this.total_rows = 0
				this.page_size = 8
				this.checkAll = false
				this.checkList = []
				this.getPackageListApi()
			},
			// 我的背包
			getPackageListApi() {
				get_star_package_list({
					sort: this.sorts,
					page: this.page,
					page_size: this.page_size
				}).then((res) => {
					// console.log(res)
					this.total_rows = res.data.total_rows
					this.price_sum = res.data.price_sum
					this.rowsList = res.data.rows
					this.page_count = res.data.page_count
				})

			},
			handleCurrentChange(val) {
				this.page = val
				this.checkAll = false
				this.checkList = []
				this.getPackageListApi()
			},
			// 分页
			// handleCurrentChange() {
			//   console.log(this.page_count);
			//   this.page --
			//   if(this.page<1){
			//     this.page=1
			//   }

			//   this.getPackageListApi()
			// },
			handleCurrentChangeTwo() {
				//console.log(this.page_count);
				this.page++
				if (this.page > this.page_count) {
					this.page = this.page_count
				}
				this.getPackageListApi()
			},



			// 饰品兑换星币
			recoverySkinsApi() {
				// console.log(this.checkList)
				if (this.checkList.length === 0) {
					this.$message.error('请选择饰品')
				} else {
					//console.log(this.player_skins_ids)
					exchange_star_skins({
						player_skins_ids: this.checkList,
						token: this.token
					}).then((res) => {
						this.getTokenApi()
						if (res.status === 1) {
							this.$message({
								message: res.msg,
								type: 'success'
							})
							for (let i = this.rowsList.length - 1; i >= 0; i--) {
								if (this.rowsList[i].checked) {
									this.rowsList.splice(i, 1)
								}
							}
							this.checkList = [];
							this.checkAll = false
							this.$store.dispatch('getPlayerApi')
							this.getPackageListApi()
							this.checkList = []
						} else {
							this.$message.error(res.msg)
						}
					})
				}
			},

			onfocus() {
				console.log("zoule ")
				this.sort = true
			},
			onblur() {
				this.sort = false
			},
			// 获取token令牌
			getTokenApi() {
				get_token().then((res) => {
					this.token = res.data.toekn
					//console.log(res)
				})
			},
			checkAllCheck() {
				// console.log(this.checkAll)
				if (!this.checkAll) {
					this.checkList = []
				}
			}


		}
	}
</script>
<style lang="scss" scoped>
	// 引入css类
	@import '@/styles/GainStar.scss';
	@import '@/styles/variables.scss';
	.int-box-frame {
		width: 1050px;
		height: 560px;
		padding: 0px;
	}

	.pagination {
		margin-top: 20px;
	}

	::v-deep .el-pagination.is-background .btn-next {
		background-color: #1ef0ed;
		height: 30px;
		width: 80px;
		border-radius: 5px;
		margin: 0 10px;
		color: #000;
		font-size: 13px;
		cursor: url('@/assets/images/commom/hand-pointer (1).png'), auto;

	}

	::v-deep .el-pagination.is-background .btn-next:disabled {
		color: #9e9e9e;
		cursor: not-allowed !important;

	}
	.appearance-menus{
		z-index: 2040 !important;
	}
	::v-deep .el-pagination.is-background .btn-prev:disabled {
		color: #9e9e9e;
		cursor: not-allowed !important;
	}

	::v-deep .el-pager .more::before {
		cursor: url('@/assets/images/commom/hand-pointer (1).png'), auto;
	}

	::v-deep .el-pagination.is-background .btn-prev {
		background-color: #1ef0ed;
		height: 30px;
		font-size: 13px;
		width: 80px;
		border-radius: 5px;
		margin: 0 10px;
		color: #000;
		cursor: url('@/assets/images/commom/hand-pointer (1).png'), auto;
	}

	.skins_total {
		margin-right: 10px;
	}

	.price_sum {
		margin-right: 10px;
	}

	.el-dropdown-link {
		cursor: pointer;
		color: #fff;
		// width: 80px;
		margin: 0 !important;
	}

	.el-icon-arrow-down {
		font-size: 12px;
	}

	.el-dropdown-menu {
		// left: 730px !important;
		// top: 282px !important;
		width: 230px !important;
		background-color: #2b4f5300;
		border: 2px solid #FFA500;
		z-index: 2044 !important;
	}
	.el-checkbox__inner {
		background-color: #FFA500 !important;
		border-color:  #FFA500 !important;
	}
	.el-dropdown-menu__item {
		padding: 0 5px !important;
		margin: 0 !important;
		width: 226px;
		color: #fff;

		&:hover {
			background-color: #606266 !important;
			color: #fff !important;
		}
	}

	.el-dropdown-menu__item {
		text-align: center;
	}

	.el-popper {
		// top: 216px !important;
		margin-top: -1px !important;
		background-color: #272451;
		//z-index: 50 !important;
		// position: absolute !important;
		// top: 23% !important;
		// left: 10.4% !important;
	}


	// 引入css类
	// @import '';
	.int-box {
		padding: 40px 5px 0px 5px;

		.MyBackpack {
			display: flex;
			justify-content: space-between;
			width:100%;
			.sort {
				position: relative;
				.show {
					border: 2px #FFA500 solid;
					padding: 0 10px;
					margin: 0 10px;
					width: 230px;
					height: 30px;
					line-height: 27px;
					text-align: center;

				}
				.conceal {
					position: absolute;
					top: 240%;
					left: 50%;
					left: calc(50% - 10px);
					transform: translate(-50%, -50%);
					display: none;

					div {
						width: 230px;
						@extend .show;
					}
				}

				.concealShow {
					display: block;
					z-index: 1;
				}
			}
			.sort:hover{
				color: $theme;
			}

			.search {
				position: relative;
				padding-right: 10px;
				input {
					height: 30px;
					width: 200px;
					background: none;
					outline: none;
					border: none;
					border: 2px #FFA500 solid;
					padding: 0 5px;
					color: #fff;
				}

				.searchIco {
					width: 30px;
					height: 30px;
					background-color: #FFA500;
					position: absolute;
					top: 9px;
					right: 0;
					margin-right: 11px;
					i {
						position: absolute;
						top: 50%;
						right: -13%;
						transform: translate(-50%, -50%);
					}
				}
			}
		}

		.MyBackpackSelect {
			display: flex;
			justify-content: flex-start;

			margin-top: 10px;
			margin-left: 10px;
			color: #FFA500;

			.checkAll {
				margin-right: 40px;
			}
			.recycle {
				height: 30px;
				line-height: 30px;
				margin-left: auto;
				margin-right: 20px;
				div {
					border: 2px #FFA500 solid;
					padding: 0 10px;
					margin: 0 10px;
					line-height: 27px;
				}
			}
			.recycle:hover{
				color: #FFA500;
			}
		}

		.BackpackItems {
			height: 550px;

			ul {
				display: flex;
				flex-wrap: wrap;
				padding: 0 5px;

				li {
					width: 23%;
					height: 130px;
					margin: 30px 26px 0 0;
					padding: 0 25px;
					// background: url('@/assets/images/BoxShowPopup/框紫.png') no-repeat;
					background-image: no-repeat !important;
					background-size: 240px 130px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					position: relative;

					.underway {
						position: absolute;
						top: 5%;
						right: 5%;
						color: #FFA500;
					}

					.subject {

						// margin-bottom: 5px;
						img {
							width: 100px;
						}
					}

					.subjectName {
						margin-top: -40px;
						width: 200px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						font-size: 15px;
					}

					.fool {
						// margin-top: 5px;
						display: flex;
						align-items: center;
						justify-content: space-evenly;

						.price {
							display: flex;
							align-items: center;

							img {
								width: 20px;
								margin-right: 10px;
							}
						}

						.pitch {
							font-size: 14px;
						}
					}

					&:nth-child(4n) {
						margin-right: 0;
					}
				}

				.loadMore {
					margin-top: 30px;
					width: 1000px;

					.btn {
						background-color: #FFA500;
						height: 30px;
						width: 70px;
						border-radius: 5px;
						margin: 0 10px;
						color: #fff;
					}

					.btn_num {
						width: 30px;
						height: 30px;
						background-color: #FFA500;
						border-radius: 5px;
						color: #fff;
						font-weight: 700;
					}
				}
			}

			.roll {
				overflow-y: scroll;
				height: 550px;

				/* 滚动条整体部分 */
				&::-webkit-scrollbar {
					width: 10px;
					height: 10px;
					background-color: none;
					border-radius: 10px;
				}

				/* scroll轨道背景 */
				&::-webkit-scrollbar-track {
					// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
					border-radius: 10px;
					background-color: rgba(216, 216, 216, 0);
				}

				/* 滚动条中能上下移动的小块 */
				&::-webkit-scrollbar-thumb {
					border-radius: 10px;
					-webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
					background-color: rgb(119, 117, 117);
				}
			}
		}
	}
</style>
