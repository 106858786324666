<!-- 饰品商城 -->
<template>
	<div class="app OrnamentStore">
		<div class="s"></div>
		<!-- 菜单分类 -->
		<MenuItems @shopSkinListS="shopSkinListS" />
		<div class="integral">
			<div class="my_integral" v-if="this.$store.state.token">
				积分：{{$store.state.userInfo.integral ? $store.state.userInfo.integral : 0.00}}
			</div>
			<div class="integral_log"
			:style="VPointer()"
			 v-if="this.$store.state.token"
			 @click="$router.push('/MyIntegralLog')">
				[积分日志]
			</div>
		</div>
		<!-- 全部商品 -->
		<div class="StoreAllGoods">
			<ul v-if="shopSkinList.length">
				<li v-for="item in shopSkinList" :key="item.id">
					<div class="back">
						<img :src="item.shop_thumb" alt="" />
					</div>
					<div class="subject">
						<img :src="item.image_url" alt="" />
					</div>
					<div class="shadow">
						<img src="@/assets/images/showImages/shadow.png" alt="" />
					</div>

					<el-tooltip placement="bottom">
						<div slot="content">{{ item.item_name }}</div>
						<div class="nameText" :title="item.item_name">
							{{ item.item_name }}
						</div>
					</el-tooltip>
					<div class="price">
						<div class="img">
							<img src="https://oss.n7buff.com/n7_integral.png" alt="" />
						</div>
						<div class="priceText">{{ item.price }}</div>
					</div>
					<div class="exchange" :style="VPointer()"
						@click="ImmediateExchange(item.id,item.price,item.item_name)">
						立即兑换
					</div>
				</li>
			</ul>
			<div v-else class="noMore">
				<img src="@/assets/images/dataNone.png" style="width:200px;height:200px" />
				<div>没有更多了</div>
			</div>
		</div>
		<div class="loadMore" v-if="page_size < total_rows">
			<img :style="VPointer()" @click="loadMore" src="@/assets/images/LuckyOrnament/Load-more.png" alt="" />
		</div>
	</div>
</template>

<script>
	import MenuItems from '@/components/MenuItems.vue'
	import {
		get_shop_list,
		buy_skin
	} from '@/api/index/AccessoriessTore'
	export default {
		name: 'OrnamentStore',
		data() {
			return {
				imgurl: 'https://oss.n7buff.com/n7_integral.png',
				shopSkinList: [], // 商城饰品列表
				total_rows: null,
				page: 1,
				page_size: 15,
				skin_category_id: null, // 一级id
				skin_category_sub_id: null, // 二级id
				quality_id: null, // 类别id
				rarity_id: null, // 品质id
				exterior_id: null, // 外观id
				lucky_recomm: 0 // 是否推荐
			}
		},
		components: {
			MenuItems,
		},
		created() {
			if (sessionStorage.getItem('n7_token')) {
				this.$store.dispatch('getPlayerApi')
			}
			this.getSkinListApi()
		},
		methods: {
			// 子传父获取
			shopSkinListS(shopSkinList, parmas, total_rows) {
				this.nameAccessories = parmas.name
				this.skin_category_id = parmas.skin_category_id
				this.skin_category_sub_id = parmas.skin_category_sub_id
				this.quality_id = parmas.quality_id
				this.rarity_id = parmas.rarity_id
				this.exterior_id = parmas.exterior_id
				this.start_price = parmas.start_price
				this.end_price = parmas.end_price
				this.lucky_recomm = parmas.lucky_recomm
				this.total_rows = total_rows
				this.shopSkinList = shopSkinList
			},
			getSkinListApi() {
				get_shop_list({
					page: this.page,
					page_size: this.page_size,
					name: this.nameAccessories,
					skin_category_id: this.skin_category_id,
					skin_category_sub_id: this.skin_category_sub_id,
					quality_id: this.quality_id,
					rarity_id: this.rarity_id,
					exterior_id: this.exterior_id,
					lucky_recomm: this.lucky_recomm,
					start_price: parseInt(Number(this.start_price)),
					end_price: parseInt(Number(this.end_price)),
				}).then((res) => {
					this.total_rows = res.data.total_rows
					this.shopSkinList = res.data.rows
				})
			},
			// 立即兑换
			ImmediateExchange(id, item_price, item_name) {
				// 判断用户是否登录
				if (!sessionStorage.getItem('n7_token')) {
					this.$store.dispatch('setLogSignRetrieve', true)
					return
				}

				this.$confirm('是否消耗 ' + item_price + ' 兑换 ' + item_name + ' ?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						dangerouslyUseHTMLString: true
					})
					.then(() => {
						buy_skin({
							skin_id: id
						}).then((res) => {
							if (res.status === 1) {
								this.$store.dispatch('getPlayerApi')
								this.$message({
									message: '兑换成功',
									type: 'success'
								})
								this.getSkinListApi()
							} else {
								this.$message.error(res.msg)
							}
						})
					})
					.catch(() => {
						this.$message({
							type: 'info',
							message: '取消兑换'
						})
					})
			},
			// 加载更多
			loadMore() {
				this.page_size = this.page_size + 15
				this.getSkinListApi()
			},
		}
	}
</script>
<style lang="scss" scoped>
	// 引入css类
	@import '@/styles/OrnamentStore.scss';

	.noMore {
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		div {
			color: #999;
		}
	}

	.priceText {
		color: #FFFF00;
	}

	.integral {
		width: 100%;
		height: 0.3rem;
		line-height: 0.3rem;
		font-size: 0.1rem;
	}

	.my_integral {
		width: 49%;
		float: left;
		color: #FFFF00;
		padding-left:.1rem;
		text-align: left;
		font-size: 0.15rem;
	}

	.integral_log {
		width: 49%;
		float: left;
		padding-left:.1rem;
		text-align: right;
		color: #1EF0ED;
		font-size: 0.15rem;
	}


</style>
