import { setToken,setUserInfo,setUserVoice } from '@/utils/auth'
export default {
  // 登录 设置token以及 token结束时间
  mutationsToken(state,v){
		state.token = v
    setToken(v)
	},
  setnike_name(state,v){
    state.userInfo.nike_name=v
  },
  // 声音
  setAudio(state,v){
		state.Audio = v
    setUserVoice(v)
	},
    // 登录注册弹出层
  setLogSignRetrieve(state,v){
    //console.log(state,v);
		state.logSignRetrieve = v
	},
  // 用户信息
  setGet_player(state,v){
		state.userInfo = v
    setUserInfo(v)
	}
}
