import request from '@/api/request'
// 所有rool房-进行中/未开始
export const get_room_list = (data) => {
  return request({
    url: '/api/room/get_room_list',
    method: 'POST',
    data
  })
}

// rool房详情
export const get_roll_detail = (data) => {
  return request({
    url: '/api/player_room/get_roll_detail',
    method: 'POST',
    data
  })
}

// rool房详情-获奖名单
export const get_prize_winner = (data) => {
  return request({
    url: '/api/player_room/get_prize_winner',
    method: 'POST',
    data
  })
}

// 参与的rool房-进行中/已结束
export const get_join_room_list = (data) => {
  return request({
    url: '/api/player_room/get_join_room_list',
    method: 'POST',
    data
  })
}

// 加入rool房
export const join_room = (data) => {
  return request({
    url: '/api/player_room/join_room',
    method: 'POST',
    data
  })
}
// rool-玩法介绍
export const get_play_introduction = (data) => {
  return request({
    url: '/api/room/get_play_introduction',
    method: 'POST',
    data
  })
}