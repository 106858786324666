

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/elStyles.scss'
import axios from 'axios'
// import '@/styles/variables.scss';
import '@/styles/index.scss'
import 'amfe-flexible'
import SlideVerify from 'vue-monoplasty-slide-verify';
import Carousel3d from 'vue-carousel-3d';
Vue.use(Carousel3d)
Vue.use(SlideVerify);
// import './assets/rem/rem.js'
import vueFilter from '@/assets/js/VFilter.js' //公共过滤器
import {
	VPointer,
	BodyPointer
} from '@/utils/style.js' //指针样式
import {
	Log_pop_up,
	GetUserInfo,
	GetPersonalData,
	ToolTips,
	GetTextByCode,
	getammo,
	Set_whole_verification,
	closeopenedRed,
	Award_winning,
	setNotice,
	SetRes_Sm_show,
	setRechargeverification,
	setRecharge,
	setPassRed,
	setTransportMask,
	setTaskwallShow,
	setloadShow,
	truncation,
	setBinodalData
} from '@/assets/js/fashion.js'
import VueClipboard from 'vue-clipboard2' //复制
// import 'swiper/css/swiper.css'
// import VueWechatTitle from 'vue-wechat-title'//配置页面meta信息



import "babel-polyfill";
// import './assets/font/font.css'
Vue.config.productionTip = false


import {
	CountDown
} from 'vant' //vant倒计时
import {
	NoticeBar
} from 'vant' //vant通知栏
import {
	Swipe,
	SwipeItem
} from 'vant' //vantSwiper
import {
	Overlay
} from 'vant' //vant遮罩层
import {
	Checkbox,
	CheckboxGroup
} from 'vant' //vant复选框
import {
	Uploader
} from 'vant' //vant上传
import {
	Loading
} from 'vant' //vant加载icon


router.afterEach(() => {
  window.scrollTo(0,0);
});



Vue.use(CountDown)
Vue.use(NoticeBar)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(VueClipboard)
Vue.use(ElementUI)
Vue.use(Overlay)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Uploader)
Vue.use(Loading)
// Vue.use(VueWechatTitle)
Vue.prototype.VPointer = VPointer
Vue.prototype.BodyPointer = BodyPointer
Vue.prototype.Log_pop_up = Log_pop_up
Vue.prototype.GetUserInfo = GetUserInfo
Vue.prototype.GetPersonalData = GetPersonalData
Vue.prototype.ToolTips = ToolTips
Vue.prototype.GetTextByCode = GetTextByCode
Vue.prototype.Set_whole_verification = Set_whole_verification
Vue.prototype.closeopenedRed = closeopenedRed
Vue.prototype.Award_winning = Award_winning
Vue.prototype.SetRes_Sm_show = SetRes_Sm_show
Vue.prototype.setRechargeverification = setRechargeverification
Vue.prototype.setRecharge = setRecharge
Vue.prototype.setNotice = setNotice
Vue.prototype.setPassRed = setPassRed
Vue.prototype.getammo = getammo
Vue.prototype.setTransportMask = setTransportMask
Vue.prototype.setTaskwallShow = setTaskwallShow
Vue.prototype.setloadShow = setloadShow
Vue.prototype.$axios = axios
Vue.prototype.truncation = truncation
Vue.prototype.setBinodalData = setBinodalData


for (let key in vueFilter) { //公共过滤器
	Vue.filter(key, vueFilter[key])
}
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')


