<template>
  <el-dialog
    :visible.sync="closeOpenPopShow"
    custom-class="open-pop-body"
    :append-to-body="true"
    :style="BodyPointer()"
    :close-on-click-modal="false"
    @close="popClose"
  >
    <div class="open-pop-main">
      <div class="open-pop-title">是否进行挑战？</div>
      <div class="open-pop-des">
        <span>您需要消耗</span>
        <span> <img src="@/assets/icons/dzxy.png" alt=""> {{ price }}</span>
      </div>
      <div class="open-bottom">
        <div class="open-btn quxiao" @click="popClose" :style="VPointer()">取消</div>
        <div class="open-btn queding" @click="isOPen" :style="VPointer()">确定</div>
      </div>
    </div>

  </el-dialog>
</template>
<script>
export default {
  props:{
    closeOpenPopShow:{
      type: Boolean,
      default: true
    },
    openNum:{
      type: Number,
      default: 0
    },
    price:{
      type: String,
      default: '0'
    }
  },
  methods:{
    popClose() {
      this.$emit('close')
    },
    isOPen() {
      this.$emit('determine', this.openNum)
    }
  }
}
</script>

<style>
.v-modal{
  opacity: 0.8 !important;
}
</style>
<style scoped lang="scss">

::v-deep .open-pop-body{
  width: 428px;
  height: 237px;
  background: url("@/assets/images/card/open-pop-bg.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 30vh !important;
  .el-dialog__body{
    padding-top: 10px;
    padding-bottom: 0;
  }
  .open-pop-title{
    font-size: 27px;
    color: #00FFFC;
    text-align: center;
    margin-bottom: 26px;
  }
  .open-pop-des{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:16px ;
    color: #FFFFFF;
    img{
      width:21px ;
      height: 21px;
      display: block;
      margin-left: 21px;
      margin-right: 1px;
    }
    span:last-of-type{
      display: flex;
      align-items: center;
      color: #FF7B07;
    }
  }
  .open-bottom{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    .open-btn{
      width: 120px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #FFFFFF;
      cursor: pointer;
    }
    .quxiao{
      background: url("@/assets/images/card/quxiao-bg.png") no-repeat;
      background-size: 100% 100%;
    }
    .queding{
      background: url("@/assets/images/card/queding-bg.png") no-repeat;
      background-size: 100% 100%;
      margin-left: 59px;
    }
  }
}
</style>
