import request from '@/api/request'
// 获取关卡模式列表
export const get_challenge_list = (data) => {
  return request({
    url: '/api/challenge/get_challenge_list',
    method: 'POST',
    data
  })
}
// 获取关卡模式详情
export const get_challenge_detail = (data) => {
  return request({
    url: '/api/challenge/get_challenge_detail',
    method: 'POST',
    data
  })
}
// 开启关卡
export const open_challenge = (data) => {
  return request({
    url: '/api/challenge/open_challenge',
    method: 'POST',
    data
  })
}
// 开启2、3、4、5关卡
export const process_pass = (data) => {
  return request({
    url: '/api/challenge/process_pass',
    method: 'POST',
    data
  })
}
// 放入背包1、2、3、4关卡装备
export const receive_pass_skin = (data) => {
  return request({
    url: '/api/challenge/receive_pass_skin',
    method: 'POST',
    data
  })
}
// 获取步骤详情
export const get_open_challenge_detail = (data) => {
  return request({
    url: '/api/challenge/get_open_challenge_detail',
    method: 'POST',
    data
  })
}
// 进入下一关

export const next_step = (data) => {
  return request({
    url: '/api/challenge/next_step',
    method: 'POST',
    data
  })
}
//  玩法介绍
export const get_play_introduction = (data) => {
  return request({
    url: '/api/challenge/get_play_introduction',
    method: 'POST',
    data
  })
}
