<!-- 站内信息 -->
<template>
  <div class="stationInformation">
    <div class="station-title">
      <div class="DeleteRead" :style="VPointer()" @click="pageBack">返回</div>
    </div>
        <ul>
      <li
      >
        <div class="player-title">
          <div class="player-name">
          </div>
          <div class="player-time">{{ data.update_time }}</div>
        </div>
        <div class="player-content" v-html="data.content"></div>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  player_mail,
  get_player_mail,
  del_player_mail
} from '@/api/index/personalCenter'
export default {
  data() {
    return {
      data:{}
    }
  },
  watch: {
    content() {
      if (this.content === 'StationInformationDetail') {
			 if (sessionStorage.getItem('n7_token')) {
			 	this.$store.dispatch('getPlayerApi')
			 }
        get_player_mail({
          mail_id: this.id
        }).then((res) => {
			 this.$store.dispatch('getPlayerApi')
          //console.log(res)
          if (res.status === 1) {
            this.data=res.data
          }
        })
      }
    }
  },
  props: ['id','content'],

  methods: {
    pageBack() {
      this.$emit('pageBack')
    },
  }
}
</script>
<style lang="scss" scoped>
// 引入css类
// @import '';
.pagination {
  margin-top: 20px;
}
::v-deep .el-pagination.is-background .btn-next {
  background-color: #1ef0ed;
  height: 30px;
  width: 80px;
  border-radius: 5px;
  margin: 0 10px;
  color: #000;
  font-size: 13px;
  cursor: url('@/assets/images/commom/hand-pointer (1).png'), auto;
}
::v-deep .el-pagination.is-background .btn-next:disabled {
  color: #9e9e9e;
  cursor: not-allowed !important;
}
::v-deep .el-pagination.is-background .btn-prev:disabled {
  color: #9e9e9e;
  cursor: not-allowed !important;
}
::v-deep .el-pager .more::before {
  cursor: url('@/assets/images/commom/hand-pointer (1).png'), auto;
}
::v-deep .el-pagination.is-background .btn-prev {
  background-color: #1ef0ed;
  height: 30px;
  font-size: 13px;
  width: 80px;
  border-radius: 5px;
  margin: 0 10px;
  color: #000;
  cursor: url('@/assets/images/commom/hand-pointer (1).png'), auto;
}
@import '@/styles/variables.scss';
.loadMore {
  margin-top: -10px;
  width: 1000px;
  .btn {
    background-color: #1ef0ed;
    height: 30px;
    width: 70px;
    border-radius: 5px;
    margin: 0 10px;
    color: #fff;
  }
  .btn_num {
    width: 30px;
    height: 30px;
    background-color: #1ef0ed;
    border-radius: 5px;
    color: #fff;
    font-weight: 700;
  }
}
.stationInformation {
  height: 620px;
  position: relative;
  padding: 20px;
  .station-title {
    display: flex;
    justify-content: space-between;
    .DeleteRead {
      border: 2px $theme solid;
      padding: 2px 10px;
      &:hover {
        color: $theme;
      }
    }
  }
  ul {
    // background-color: blue;
    height: 570px;
    overflow-y: auto;
    padding-bottom: 20px;
    li {
      margin-top: 20px;
      padding: 40px 20px;
      width: 1000px;
      // height: 150px;
      background: url('@/assets/images/personalCenter/站内信息back.png')
        no-repeat;
      background-size: 1000px 100%;
      .player-title {
        display: flex;
        justify-content: space-between;
        .hint {
          width: 10px;
          height: 10px;
          border-radius: 50px;
          background-color: red;
          display: inline-block;
        }
      }
      .player-content {
        margin-top: 10px;
      }
    }
  }
  ul::-webkit-scrollbar {
    width: 8px;
  }
  ul::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px #1ef0ed;
    background: rgba(0, 0, 0, 0.2);
  }
  ul::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px #1ef0ec65;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
  .pagination {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
</style>
