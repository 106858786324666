<!-- HomeHead -->
<template>
	<div class="head">
		<div :class="[{ introduced: this.$store.state.logSignRetrieve }, { introduced: topUpRetrieve }]"></div>
		<div class="list">
			<div class="head-logo" :style="VPointer()" @click="logo"><img src="@/assets/images/logo.png" alt="" /></div>
			<!-- SC:GO盲盒 -->
			<div class="head-bar" :style="VPointer()" :class="homeHead === 'home' ? 'head-back' : ''" @click="logo">
				<img src="@/assets/icons/盲盒.png" alt="" />
				CS:GO盲盒
			</div>
			<img src="@/assets/images/顶部菜单栏分割线.png" alt="" />
			<!-- 免费皮肤 -->
			<div class="head-bar" :style="VPointer()" :class="homeHead === 'FreeSkin' ? 'head-back' : ''" @click="FreeSkin">
				<img src="@/assets/icons/Roll房.png" alt="" />
				ROLL 房
			</div>
			<img src="@/assets/images/顶部菜单栏分割线.png" alt="" />
			<!-- 盲盒对战 -->
			<div class="head-bar" :style="VPointer()" :class="homeHead === 'BlindBoxFight' ? 'head-back' : ''" @click="BlindBoxFight">
				<img src="@/assets/icons/盲盒对战.png" alt="" />
				盲盒对战
			</div>
			<img src="@/assets/images/顶部菜单栏分割线.png" alt="" />
			<!-- 幸运饰品 -->
			<div v-if="false" class="head-bar" :style="VPointer()" :class="homeHead === 'LuckyOrnament' ? 'head-back' : ''" @click="LuckyOrnament">
				<img src="@/assets/icons/幸运饰品.png" alt="" />
				幸运饰品
			</div>
			<img src="@/assets/images/顶部菜单栏分割线.png" alt="" />
      <!--   等级箱子   -->
      <div  class="head-bar" :style="VPointer()" :class="homeHead === 'GradeBox' || homeHead === 'CdkBox' ? 'head-back' : ''" @click="gradeBox">
        <img src="@/assets/icons/等级箱子.png" alt="" />
        免费箱子
      </div>
      <img src="@/assets/images/顶部菜单栏分割线.png" alt="" />
			<!-- 饰品商城 -->
			<div class="head-bar" :style="VPointer()" :class="homeHead === 'OrnamentStore' ? 'head-back' : ''" @click="OrnamentStore">
				<img src="@/assets/icons/饰品商城.png" alt="" />
				饰品商城
			</div>

			<img src="@/assets/images/顶部菜单栏分割线.png" alt="" />
			<!-- 饰品商城 -->
			<div class="head-bar star-color" :style="VPointer()" :class="homeHead === 'StarStore' ? 'head-back' : ''" @click="StarStore">
				<img src="https://oss.n7buff.com/star_store.gif" alt="" />
				星空商城
			</div>
      <img src="@/assets/images/顶部菜单栏分割线.png" alt="" />
      <!-- 饰品商城 -->
<!--      <div class="head-bar star-color" :style="VPointer()" :class="homeHead === 'cardList' ? 'head-back' : ''" @click="clickCardList">-->
<!--        <img src="@/assets/icons/dzxy.png" alt="" />-->
<!--        斗转星移-->
<!--      </div>-->
			<div class="head-bar" :style="VPointer()" @click="down"><img src="@/assets/images/down.png" alt="" /></div>
		</div>

		<div class="log">
			<div class="log-methods">
				<div class="head-log" v-if="!this.$store.state.token">
					<div class="head-log-reg" @click="sigIn" :style="VPointer()">注册</div>
					<div class="head-log-in" @click="logIn" :style="VPointer()">登录</div>
				</div>
				<!-- <div class="head-log-down"  :style="VPointer()">下载</div> -->
			</div>

			<div v-if="this.$store.state.token" class="head-user">
				<div class="user-topUp" :style="VPointer()" @click="topUp"><img src="@/assets/images/fightImages/Recharge.png" alt="" /></div>
				<div class="user-centre" :style="VPointer()" @click="personalCenter">
					<div class="user-img">
						<div class="nums" v-if="$store.state.userInfo.no_read_num > 0">{{ $store.state.userInfo.no_read_num }}</div>
						<img v-if="!hed_imgurl" class="head-img" :src="$store.state.userInfo.head_img" alt="" />
						<img v-else class="head-img" :src="hed_imgurl" alt="" />
            <img class="headPortraitAround" :src="$store.state.userInfo.grade_img" alt="">
					</div>
					<div class="user-details">
						<div class="user-name">{{ $store.state.userInfo.nike_name }}</div>
						<div class="user-price">
							<img src="@/assets/images/n7_money.png" alt="" />
							<div>{{ $store.state.userInfo.account_balance }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 弹框 -->
		<TopUp class="logIn" v-show="topUpRetrieve" :visible.sync="topUpRetrieve" />
		<LogIn class="logIn" v-show="this.$store.state.logSignRetrieve" :homeLogin="LogIn" />
	</div>
</template>

<script>
import LogIn from '@/views/User/logIn';
import TopUp from '@/views/User/topUp.vue';
import { set } from 'vue';
export default {
	name: 'HomeHead',
	data() {
		return {
			homeHead: this.$route.name,
			audio: new Audio('https://oss.n7buff.com/web/audio/mens.mp3'), //过渡声音
			logSignRetrieve: false, // 登录注册弹出层
			topUpRetrieve: false, // 充值弹出层
			LogIn: 'logIn',
			RetrievePassword: false,
			hed_imgurl: '',
			timer: null
		};
	},
	components: {
		LogIn,
		TopUp
	},
	watch: {
		//  '$route'(){
		//   console.log(this.homeHead);
		//   }
	},
	created() {
		// console.log(this.homeHead);
		setInterval(res => {
			//this.hed_imgurl = localStorage.getItem('img');
		}, 1000);
	},
	methods: {
		logo() {
			if (this.$store.state.Audio) {
				this.audio.play().catch(() => {});
			}
			this.$router.push('/');
			this.homeHead = this.$route.name;
		},
		// 免费皮肤跳转
		FreeSkin() {
			if (this.$store.state.Audio) {
				this.audio.play().catch(() => {});
			}
			this.$router.push('/FreeSkin');
			this.homeHead = this.$route.name;
		},
		// 盲盒对战跳转
		BlindBoxFight() {
			if (this.$store.state.Audio) {
				this.audio.play().catch(() => {});
			}
			this.$router.push('/BlindBoxFight');
			this.homeHead = this.$route.name;
		},

		// 幸运饰品跳转
		LuckyOrnament() {
			if (this.$store.state.Audio) {
				this.audio.play().catch(() => {});
			}
			this.$router.push('/LuckyOrnament');
			this.homeHead = this.$route.name;
		},
    // 等级箱子
    gradeBox() {
      if (this.$store.state.Audio) {
        this.audio.play().catch(() => {});
      }
      this.$router.push('/GradeBox');
      this.homeHead = this.$route.name;
    },
		// 饰品商城跳转
		OrnamentStore() {
			if (this.$store.state.Audio) {
				this.audio.play().catch(() => {});
			}
			this.$router.push('/OrnamentStore');
			this.homeHead = this.$route.name;
		},
		// 星空商城跳转
		StarStore() {
			if (this.$store.state.Audio) {
				this.audio.play().catch(() => {});
			}
			this.$router.push('/StarStore');
			this.homeHead = this.$route.name;
		},
    clickCardList() {
      if (this.$store.state.Audio) {
        this.audio.play().catch(() => {});
      }
      this.$router.push('/cardList');
      this.homeHead = this.$route.name;
    },
		// 登录
		logIn() {
			this.$store.dispatch('setLogSignRetrieve', true);
			this.LogIn = 'logIn';
			var mo = function(e) {
				e.preventDefault();
			};
			document.body.style.overflow = 'hidden';
			document.addEventListener('touchmove', mo, false); //禁止页面滑动
		},
		// 注册
		sigIn() {
			this.$store.dispatch('setLogSignRetrieve', true);
			this.LogIn = 'sigIn';
			var mo = function(e) {
				e.preventDefault();
			};
			document.body.style.overflow = 'hidden';
			document.addEventListener('touchmove', mo, false); //禁止页面滑动
		},
		// 个人中心
		personalCenter() {
			this.$router.push('/personalCenter?name=personalCenter');
			this.homeHead = null;
		},
		// 充值
		topUp() {
			this.topUpRetrieve = true;
			var mo = function(e) {
				e.preventDefault();
			};
			document.body.style.overflow = 'hidden';
			document.addEventListener('touchmove', mo, false); //禁止页面滑动
		},
		// 下载
		down() {
			console.log('跳转下载页');
			//return;
			this.$router.push('/Download');
		}
	}
};
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.head {
	// background: url('@/assets/images/Bottom menu.png') no-repeat;
	color: #ffffff;
	background-color: #212126;
	height: 50px;
	// position: fixed;
	// display: flex;
	// justify-content: space-around;
	// align-items: center;
	.introduced {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.8);
		// z-index: 10;
		transition: all 0.15s linear;
	}
	.list {
		display: flex;
		justify-content: space-around;
		align-items: center;
		width: 1300px;
	}
	.list > img {
		width: 2px;
		height: 43px;
	}
	&-back {
		background-color: rgba($color: #2b4f53, $alpha: 1);
	}
	&-logo {
		margin: 0 20px;
		img {
			height: 40px;
			margin-top: 5px;
		}
	}
	&-bar {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 50px;
		font-size: 14px;
		font-weight: 700;
		img {
			margin-right: 20px;
			width: 30px;
		}
	}

	&-bar:hover {
		color: #7debec;
	}
	.log-methods {
		display: flex;
		align-items: center;
		font-size: 12px;
		.head-log {
			display: flex;
			align-items: center;
			font-size: 12px;
			img {
				width: 80px;
			}

			// 注册按钮
			.head-log-reg {
				border: 2px #7debec solid;
				border-radius: 5px;
				padding: 3px 15px;
				margin-top: 13px;
				// width: 80px;
				// height: 32px;
				// line-height: 30px;
				background-size: 80px;
				margin-right: 20px;
				&:hover {
					// @extend .head-log-reg;
					background-color: #7debec;
					transition: all 0.2s linear;
				}
			}
			// 登录按钮
			.head-log-in {
				border: 2px #7debec solid;
				border-radius: 5px;
				padding: 3px 15px;
				margin-top: 13px;
				// width: 80px;
				// height: 32px;
				// line-height: 20px;
				background-size: 80px;
				margin-right: 20px;
				&:hover {
					// @extend .head-log-reg;
					background-color: #7debec;
					transition: all 0.2s linear;
				}
			}
		}
		// 下载按钮
		.head-log-down {
			border: 2px #7debec solid;
			border-radius: 5px;
			padding: 3px 15px;
			margin-top: 13px;
			// width: 80px;
			// height: 32px;
			// line-height: 20px;
			background-size: 80px;
			margin-right: 20px;
			&:hover {
				// @extend .head-log-reg;
				background-color: #7debec;
				transition: all 0.2s linear;
			}
		}
	}

	.logIn {
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translate(-50%, 5%);
		z-index: 9999;
	}
	.head-user {
		display: flex;
		align-items: center;
		.user-topUp {
			margin: 0 10px;
			img {
				width: 80px;
			}
		}
		.user-centre {
			display: flex;
			align-items: center;
			margin: 0 10px;
			// padding-top: 5px;
			.user-img {
				position: relative;

				.nums {
					position: absolute;
					top: 0;
					right: -14px;
					width: 24px;
					height: 24px;
					background: #e55e58;
					border-radius: 50%;
					text-align: center;
					line-height: 24px;
					transform: scale(0.7);
          z-index: 99;
				}
				.head-img {
					margin-top: 6px;
					width: 40px;
					height: 40px;
				}
        .headPortraitAround{
          width: 44px;
          height: 44px;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }
			}
			.user-details {
				font-size: 12px;
				margin: 0 10px;
				.user-price {
					color: #1ef0ed;
					display: flex;
					align-items: center;
					img {
						width: 15px;
						margin-right: 5px;
					}
				}
			}
		}
	}
	.log {
		// float: right;
		// margin-top: -37px;
		position: absolute;
		right: 0;
		// top: 13px;
		top: 0;
		// bottom: 0;
		// margin: auto;
	}
}
.star-color{
	color:#FFA500;
	font-size: 16px;
	font-weight: 700;
}
</style>
