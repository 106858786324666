import request from '@/api/request'
// 获取饰品列表
export const get_skin_list = (data) => {
  return request({
    url: '/api/lucky/get_skin_list',
    method: 'POST',
    data
  })
}

// 获取饰品详情
export const get_skin_detail = (data) => {
  return request({
    url: '/api/lucky/get_skin_detail',
    method: 'POST',
    data
  })
}

// 开奖
export const opne_luncky_skin = (data) => {
  return request({
    url: '/api/lucky/opne_luncky_skin',
    method: 'POST',
    data
  })
}

// 幸运饰品-玩法介绍
export const get_play_introduction = (data) => {
  return request({
    url: '/api/lucky/get_play_introduction',
    method: 'POST',
    data
  })
}
