<!-- 登录 -->
<template>
  <div>
    <form class="elForm">
      <div class="cellNumber">
        <input
          type="text"
          placeholder="请输入手机号"
          v-model="logInForm.username"
          oninput="value=value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')"
        />
        <img src="@/assets/images/userWidget/手机.png" alt="" />
      </div>
       <div
            class="password getcode"
          >
            <input
              type="text"
              placeholder="请输入验证码"
              v-model="logInForm.code"
              oninput="value=value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')"
            />
            <el-button
              round
              size="mini"
              class="b"
              :loading="sigInLoading"
              :disabled="sigInDisabled"
              :style="VPointer()"
              @click="openCode"
              ><span v-if="!sigGetCode">获取验证码</span>
              <span v-else>{{ sigInCountDown }}后再次获取</span>
            </el-button>
          </div>
      <div class="password">
        <input
          type="password"
          autocomplete="off"
          placeholder="请输入不超过16位的密码"
          v-model="logInForm.password"
          oninput="value=value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')"
        />
        <img class="a" src="@/assets/images/userWidget/锁.png" alt="" />
      </div>
      <div class="password">
        <input
          type="password"
          autocomplete="off"
          placeholder="请再次输入密码"
          v-model="logInForm.password_again"
        />
        <img
          class="a"
          src="@/assets/images/userWidget/锁.png"
          alt=""
          oninput="value=value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')"
        />
      </div>
      <div class="password">
        <input
          type="text"
          placeholder="请输入昵称"
          v-model="logInForm.nike_name"
        />
        <img class="a" src="@/assets/images/userWidget/昵称.png" alt="" />
      </div>
      <div class="password">
        <input
          type="text"
          placeholder="请输入邀请码"
          v-model="logInForm.invite_code"
          oninput="value=value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')"
        />
        <img class="a" src="@/assets/images/userWidget/邀请码.png" alt="" />
      </div>
      <div style="color: red" v-if="checkedHint">请勾选用户协议和政策</div>
      <div class="enterSig" :style="VPointer()" @click="postRegisterApi">
        注 册
      </div>
    </form>

    <div class="footers">
      <div class="agreement">
        <input type="checkbox" id="checked" v-model="checked" :style="VPointer()" />
        <label for="checked" :style="VPointer()">
          我已经满18岁，并阅读同意
          <span :style="VPointer()" @click="goHelp(3)">《用户协议》</span>
          和
          <span :style="VPointer()" @click="goHelp(2)">《隐私政策》</span>
          ，承诺理性消费。
        </label>

      </div>
      <!-- <div><img src="@/assets/images/userWidget/steam.png" alt="" /></div>
        <div>steam一键登录</div> -->
    </div>

    <!-- 滑动验证弹窗 -->
    <Vcode :show="isShow" @success="onSuccess" @close="onClose" />
  </div>
</template>

<script>
import Vcode from 'vue-puzzle-vcode'

import { get_player } from '@/api/index/personalCenter'
import {
  postLogin,
  postRegister,
  postRegisterGet_code,
  postLoginGet_code,
  postReset_password
} from '@/api/index/logIn'
import { isMobile, isSensitiveWord } from '@/utils/validate'
export default {
  components: {
    Vcode
  },
  name: 'logIn',
  data() {
    return {
      checked: false, // 用户协议
      checkedHint: false, // 用户协议提示
      isShow: false,
      // 注册获取验证码
      sigGetCode: false, // 获取验证码倒计时
      sigInCountDown: null, // 验证码倒计时
      sigInTimer: null, //设置计时器,
      sigInLoading: false,
      sigInDisabled: false,
      // 登录注册
      logInForm: {
        username: '', //账号
        password: '', //密码
        password_again: '', // 二次密码
        code: '', // 验证码
        nike_name: '', // 昵称
        invite_code: '' // 邀请码
      }
    }
  },
  created() {
		if(this.$route.query.code){
			this.logInForm.invite_code = this.$route.query.code
		}

	},
  methods: {
    goHelp(e) {
      this.$router.push('/Helpcenter?id='+e)
      this.$store.dispatch('setLogSignRetrieve', false)
    },
    onSuccess() {
     this.sigInGetCodeApi()
      this.isShow = false
    },
    openCode() {
      if (this.logInForm.username) {
        this.isShow = true
      }
    },
    onClose() {},
    // 获取个人余额信息
    async get_playerApi() {
      const res = await get_player()
      //console.log(res)
    },

    // 获取注册短信验证码
    sigInGetCodeApi() {
      if (isMobile(this.logInForm.username)) {
        this.sigInLoading = true
        postRegisterGet_code({
          username: this.logInForm.username
        }).then((res) => {
          if (res.status === 1) {
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.sigInDisabled = true
            //console.log(res)
            this.sigInLoading = false
            let TIME_COUNT = 60
            this.sigInCountDown = TIME_COUNT
            this.sigGetCode = true
            this.sigInTimer = setInterval(() => {
              if (
                this.sigInCountDown > 0 &&
                this.sigInCountDown <= TIME_COUNT
              ) {
                this.sigInCountDown--
              } else {
                clearInterval(this.sigInTimer)
                this.sigInTimer = null
                this.sigGetCode = false
                this.sigInDisabled = false
              }
            }, 1000)
          } else {
            this.sigInLoading = false
            this.$message({
              message: res.msg,
              type: 'error'
            })
          }
        })
      } else {
        this.$message({
          message: '请正确输入手机号',
          type: 'error'
        })
      }
    },
    // 立即注册
    postRegisterApi() {
      if (isSensitiveWord(this.logInForm.nike_name)) {
        if (this.checked) {
          postRegister({
            username: this.logInForm.username,
            code: this.logInForm.code,
            password: this.logInForm.password,
            nike_name: this.logInForm.nike_name,
            invite_code: this.logInForm.invite_code
          }).then((res) => {
            //console.log(res)
            if (res.status === 1) {
              this.$message({
                message: res.msg,
                type: 'success'
              })
              this.$emit('regSuc')
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              })
            }
          })
        } else {
          // this.$message({
          //   message: '请勾选用户协议和政策',
          //   type: 'error'
          // })
          this.checkedHint = true
          setTimeout(() => {
            this.checkedHint = false
          }, 5000)
        }
      } else {
        this.$message({
          message: '禁止输入敏感字符',
          type: 'error'
        })
      }
    },
    // 关闭
    closes() {
      this.$store.dispatch('setLogSignRetrieve', false)
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = '' //出现滚动条
      document.removeEventListener('touchmove', mo, false)
      // Object.assign(this.$data, this.$options.data()) // 重置data
    },


  }
}
</script>
<style lang="scss" scoped>
// 引入css类
@import '@/styles/variables';
@import '@/styles/reg';
</style>
