

const vFilter = {
	//小背景
	smallBack:function(v){
		return 'background-image: url(' + localStorage.getItem('commom') + eval('(' + v + ')').base + eval('(' + v + ')').smallBack + ');'
	},
	
	//swiper背景
	back:function(v){
		return 'background-image: url(' + localStorage.getItem('commom') + eval('(' + v + ')').base + eval('(' + v + ')').back + ');'
	},
	
	//正面logo
	positiveLogo:function(v){
		return 'background-image: url(' + localStorage.getItem('commom') + eval('(' + v + ')').base + eval('(' + v + ')').positiveLogo + ');'
	},
	
	//反面logo
	negativeLogo:function(v){
		return 'background-image: url(' + localStorage.getItem('commom') + eval('(' + v + ')').base + eval('(' + v + ')').negativeLogo + ');'
	},
	
	//完整背景
	wholeBack:function(v){
		return 'background-image: url(' + localStorage.getItem('commom') + eval('(' + v + ')').base + eval('(' + v + ')').wholeBack + ');'
	},
	
	//开箱背景
	OpenBack:function(v){
		return 'background-image: url('+ v + ');'
	},
	//开箱背景中秋
	IregularBack:function(v){
		// console.log(v,'vvvvvv')
			return 'background-image: url(' + localStorage.getItem('commom') + eval('(' + v + ')').base + eval('(' + v + ')').irregularBack + ');'
	},
	//商城背景
	mallBack:function(v){
		return 'background-image: url(' + localStorage.getItem('commom') + eval('(' + v + ')').base + eval('(' + v + ')').mallBack + ');'
	},
	
	
	//汰换默认背景
	primaryBack:function(v){
		if(v){
			return 'background-image: url(' + localStorage.getItem('commom') + eval('(' + v + ')').base + eval('(' + v + ')').primaryBack + ');'
		}else{
			return 'background-image: url(http://95-csgo.oss-cn-beijing.aliyuncs.com/back/present/synthesis.png)'
		}
	},
	
	
	//汰换选中背景
	meansBack:function(v){
		if(v){
			return 'background-image: url(' + localStorage.getItem('commom') + eval('(' + v + ')').base + eval('(' + v + ')').meansBack + ');'
		}else{
			return 'background-image: url(http://95-csgo.oss-cn-beijing.aliyuncs.com/back/present/synthesis.png)'
		}
	},
	
	//对战房间背景
	battle_back:function(v){
		let Price = Number(v)
		if(Price <= 10){
			return 'background-image: url(' + require('@/assets/images/Battle/blue.png') + ');'
		}else if( Price > 10 && Price <= 100 ){
			return 'background-image: url(' + require('@/assets/images/Battle/rose.png') + ');'
		}else if( Price > 100 && Price <= 500){
			return 'background-image: url(' + require('@/assets/images/Battle/red.png') + ');'
		}else{
			return 'background-image: url(' + require('@/assets/images/Battle/yellow.png') + ');'
		}
	},
	
	//对战箱内饰品背景
	innerBack:function(v){
		return 'background-image: url(' + localStorage.getItem('commom') + eval('(' + v + ')').base + eval('(' + v + ')').inner + ');'
	},
	
	//对战进行光晕背景
	haloBack:function(v){
		return 'background-image: url(' + localStorage.getItem('commom') + eval('(' + v + ')').base + eval('(' + v + ')').halo + ');'
	},
		
	//颜色
	colorCode:function(v){
		return 'color:' + eval('(' + v + ')').colorCode
	},

	//小颜色
	otherColor:function(v){
		return 'color:' + eval('(' + v + ')').otherColor
	},
	
	// img
	Commom_Img:function(v){
		return eval('(' + v + ')').iconUrl
	},
	
	
	//背包礼品
	giftBack:function(type){
		switch (type) {//汰换
			case 1:
				return 'background-image: url(http://95-csgo.oss-cn-beijing.aliyuncs.com/back/present/Replacement.png)'
				break;
			case 2://幸运
				return 'background-image: url(http://95-csgo.oss-cn-beijing.aliyuncs.com/back/present/lucky.png)'
				break;
			case 4://实物
				return 'background-image: url(http://95-csgo.oss-cn-beijing.aliyuncs.com/back/present/material.png)'
				break;
		}
	},
	
	//抽奖转盘展示
/* 	spreadBack:function(item){
		if(item.superTypeId == 5){
			return 'background-image: url(' + localStorage.getItem('commom') + eval('(' + item.imageData + ')').base + eval('(' + item.imageData + ')').spreadBack + ');'
		}else{
			if(item.superTypeId == 2){
					return 'background-image: url(http://95-csgo.oss-cn-beijing.aliyuncs.com/back/present/luckySpread.png)'
			}else if(item.superTypeId == 4){
					return 'background-image: url(' + require('@/assets/images/ceremony/contain/default.png') + ')'
			}else{
					return 'background-image: url(http://95-csgo.oss-cn-beijing.aliyuncs.com/back/present/currency.png)'
			}
		}
	}, */
	
	
	
	//是否溢出显示
	IsDisabled:function(v){
		if(v.length > 10){
			return false
		}else{
			return true
		}
	},
	
	
	
	//定时箱子倒计时
	//倒计时过滤
	TimeFilter(v) {
		if (v.timingType == 1) {//处理小时
			//当时服务器时间戳
			let Time = v.time
			//加上一小时的时间戳
			let endTime = Time + 3600000
			//分解
			let date = new Date(endTime)
			let yy = date.getFullYear()
			let mm = date .getMonth() + 1
			let dd = date.getDate()
			let hh = date.getHours()
			//分秒置为0
			let dateTime = yy + '/' + mm + '/' + dd + ' ' + hh + ':' + '00' + ':' + '00'
			//相减并返回
			return new Date(dateTime).getTime() - Time
		} else if(v.timingType == 2){//处理天
			//当前服务器时间戳
			let Time = v.time
			//转时间
			let date = new Date(Time)
			//天+1
			let endtime = date.setDate(date.getDate()+1)
			//转时间
			let endDate = new Date(endtime)
			//分解
			let yy = endDate.getFullYear()
			let mm = endDate .getMonth() + 1
			let dd = endDate.getDate()
			//时分秒置为0
			let dateTime = yy + '/' + mm + '/' + dd + ' ' + '00' + ':' + '00' + ':' + '00'
			//相减并返回
			return new Date(dateTime).getTime() - Time

		}else if (v.timingType == 3){//处理周
			//当前服务器时间戳
			let Time = v.time
			//转时间
			let date = new Date(Time)
			//当前星期x
			let Day = date.getDay()
			//距离本周日差(7为0反之正常)
			let difference = 0 - Day + 7 == 7 ? 0 : 0 - Day + 7
			//当前时间+距离本周日差+1(隔日0点)
			let endtime = date.setDate(date.getDate()+difference+1)
			//转时间
			let endDate = new Date(endtime)
			//分解
			let yy = endDate.getFullYear()
			let mm = endDate .getMonth() + 1
			let dd = endDate.getDate()
			//时分秒置为0
			let dateTime = yy + '/' + mm + '/' + dd + ' ' + '00' + ':' + '00' + ':' + '00'
			//相减并返回
			return new Date(dateTime).getTime() - Time
		}
	},
	
	
	//roll_genus
/* 	roll_genus_screen: function(v) {
		if (v == 2) {
			return require('@/assets/images/Roll/anchor.png')
		} else {
			return require('@/assets/images/Roll/official.png')
		}
	}, */

	//rollDetails_genus
/* 	rollDetails_genus_screen: function(v) {
		if (v == 2) {
			return require('@/assets/images/roll_details/anchor.png')
		} else {
			return require('@/assets/images/roll_details/official.png')
		}
	}, */

	//定时箱子
	timing_name: function(v) {
		switch (v) {
			case 0:
				return '每小时赠送'
				break;
			case 1:
				return '每日赠送'
				break;
			case 2:
				return '每周赠送'
				break;
		}
	},
	
	
	//违禁词
 Prohibited_words:function(v){
	 if(!localStorage.getItem('Prohibited')){
		 return
	 }
	 let Prohibited=["卡","马扁","戈壁","装13","绿茶婊","日了狗","丢你老母","淫","点击获取","免费领取","JB","经痛","性","乳","信用卡","生殖器","傻狗","Z网","d2","备案","110","死全家","暴毙","律师函","傻b","习","玖伍","鸡巴","sm","nmsl","孝子","孤儿","jj","导管","烧杯","烧鸡","伞兵","打款","多少钱","打钱","转账","汇款","交易","杀","男人","站","sb","开导","尸","病毒 ","新冠","虐待","疫情","恒河","浮尸","hellokitty","内裤","jiji","国家 ","最","报警","金牌","顶级","推广号","死妈","推广","习近平","傻逼","傻屌","主席","国母","法轮功","亏","官方","贷款","毒品","海洛因","暴力","qq","屄","屌","淫","抖音","虎牙","你妈逼","操你妈","爹","爷","爸","95","刷卡","九","五","赌","骗","⑨","妈","娘","母亲","父亲","张越","叫爸爸","咪咪","屌爆了","太阳","周恩来","赌博","毛泽东","装逼","蛋疼","白莲花","刷","逼","吃翔","点击","秒杀","疯抢","退钱","松","下面","鸡","诈骗","欺诈","报案","立案","退钱","截","贷","死","日你","草你","肏","操你","艹","我草","我操","支付宝","举报","搜","9","5","党","主播","推广","66skin","86skin","87skin","86","85skin","egg","蛋网","dog","投诉","市监局","违法","滨州","消费者","死全家","播","全家","工作人员","du","台","中华人民共和国","vllc","微信","vx","zfb","屁眼","腚眼","党","政"];
	 //let Prohibited = eval('(' + localStorage.getItem('Prohibited') + ')')
		if(v){
			if(Prohibited){
				for(let i = 0; i < Prohibited.length; i++){
					if(v.indexOf(Prohibited[i]) != -1){				
						let text = eval("/" + Prohibited[i] + "/g") //转正则
						v = v.replace(text, '*'.repeat(Prohibited[i].length))
					}
				}
				return v
			}else{
				return v
			}
			
		}
		
	}

}

export default vFilter

