<!-- 我的日志 -->
<template>
  <div class="MyLog">
    <!-- 账户明细 -->
    <div v-show="pitchOn === 'recharge'" class="rechargeRecord">
      <div class="select">
        <div class="main">
          <div class="all" @click="main" :style="VPointer()">
            {{ recordPull[TS].mainName }} <i class="el-icon-arrow-down"></i>
          </div>
          <ul v-show="mainConceal">
            <li
              v-for="(item, index) in recordPull"
              :key="item.id"
              @click="mainLi(index, item.change_type)"
              :style="VPointer()"
            >
              {{ item.mainName }}
            </li>
          </ul>
        </div>
        <!-- 排序 -->
        <div class="assistant">
          <div
            class="all"
            v-if="sort === 'desc'"
            :style="VPointer()"
            @click="sorts"
          >
            正序
          </div>
          <div
            class="all"
            v-if="sort === 'asc'"
            :style="VPointer()"
            @click="sorts"
          >
            倒序
          </div>

        </div>
		<!-- 返回 -->
		  <div class="assistant-right">
				<div
				  class="returnRoute"
				  :style="VPointer()"
				  @click="returnRoute"
				>
				  返回
				</div>
		  </div>

      </div>

      <div class="detail sticky-table">
        <table border="1" cellspacing="0" width="100%">
          <tr class="formH">
            <th>日 &nbsp; 期</th>
            <th>类 &nbsp; 型</th>
            <th>描 &nbsp; 述</th>
            <th>金 &nbsp; 额</th>
          </tr>
          <tr class="formB" v-for="item in rowsList" :key="item.id">
            <td>{{ item.update_time }}</td>
            <td>{{ item.change_type === 1 ? '收入' : '支出' }}</td>
            <td>{{ item.type_name }}</td>
            <td>{{ item.star }}</td>
          </tr>
        </table>
      </div>
    </div>
    <!-- 饰品记录 -->
    <div v-show="pitchOn === 'ornament'" class="ornament">
      <div class="detail sticky-table">
        <table border="1" cellspacing="0" width="100%">
          <tr class="formH">
            <th>日 &nbsp; 期</th>
            <th>类 &nbsp; 型</th>
            <th>描 &nbsp; 述</th>
            <th>数 &nbsp; 量</th>
            <th>余 &nbsp; 额</th>
          </tr>
          <tr class="formB" v-for="item in forms" :key="item.id">
            <td v-for="item in 5" :key="item.id">100</td>
          </tr>
        </table>
      </div>
    </div>
    <!-- 礼品记录 -->
    <div v-show="pitchOn === 'present'" class="present">
      <div class="detail sticky-table">
        <table border="1" cellspacing="0" width="100%">
          <tr class="formH">
            <th>日 &nbsp; 期</th>
            <th>类 &nbsp; 型</th>
            <th>描 &nbsp; 述</th>
            <th>数 &nbsp; 量</th>
            <th>余 &nbsp; 额</th>
          </tr>
          <tr class="formB" v-for="item in forms" :key="item.id">
            <td v-for="item in 5" :key="item.id">100</td>
          </tr>
        </table>
      </div>
    </div>
    <el-pagination
      background
      layout="prev, pager, next"
      @current-change="handleCurrentChange"
      :page-size="page_size"
      :total="total_rows"
      prev-text="上一页"
      next-text="下一页"

    >
    </el-pagination>
    <!-- <div class="loadMore">
      <button class="btn" :style="VPointer()" @click="handleCurrentChange">
        上一页
      </button>
      <button class="btn_num">{{page}}</button>
      <button class="btn" :style="VPointer()" @click="handleCurrentChangeTwo">
        下一页
      </button>
    </div> -->
  </div>
</template>

<script>
import { get_player_star_history } from '@/api/index/personalCenter'
export default {
  name: 'MyIntegralLog',
  data() {
    return {
      pitchOn: 'recharge',
      mainConceal: false,
      assistantConceal: false,
      TS: 0,
      TST: 0,
      page_count: null, //总页数
      recordPull: [
        {
          mainName: '全部',
          change_type: '0'
        },
        {
          mainName: '支出',
          change_type: '2'
        },
        {
          mainName: '收入',
          change_type: '1'
        },
        /* {
          mainName: '用户充值'
        } */
      ],
      assistants: [
        {
          assistant: [{ assistantName: '全部' }]
        },
        {
          assistant: [
            { assistantName: '全部', type: '0' },
            { assistantName: '兑换饰品', type: '-1' },
            /* { assistantName: '平台消费', type: '-5' } */
          ]
        },
        {
          assistant: [
            { assistantName: '全部', type: '0' },
            { assistantName: '饰品兑换', type: '1' },
          ]
        },
        {
          assistant: [{ assistantName: '充值' }]
        }
      ],
      rowsList: [],
      forms: 17,
      sort: 'desc', // 排序
      page: 1, // 页码
      page_size: 15, // 每页数量
      change_type: '0', // 收入支出
      type: '0', // 类型
      total_rows: null // 总数据量
    }
  },
  created() {
	  this.getListApi()
	},
  props: {
    content: String
  },
  watch: {
    content() {
	  if (sessionStorage.getItem('n7_token')) {
		this.$store.dispatch('getPlayerApi')
	  }
	this.getListApi()
    }
  },
  methods: {
    // 获取个人明细
    getListApi() {
      get_player_star_history({
        sort: this.sort,
        page: this.page,
        page_size: this.page_size,
        change_type: this.change_type,
        type: this.type
      }).then((res) => {
        // console.log(res)
        this.rowsList = res.data.rows
        this.total_rows = res.data.total_rows
        this.page_count=res.data.page_count
      })
    },
    // 分页
    handleCurrentChange(val) {
      this.page = val
      this.getListApi()
    },
    // handleCurrentChange() {
    //   this.page--
    //   if (this.page < 1) {
    //     this.page = 1
    //   }

    //   this.getListApi()
    // },
    handleCurrentChangeTwo() {
      this.page++
      if (this.page > this.page_count) {
        this.page = this.page_count
      }
      this.getListApi()
    },
    returnRoute(){
		if (this.$route.query.source) {
		  this.$router.push('/StarStore')
		} else {
		  this.$emit('clear')
		  this.$router.push('/StarStore')
		}
	},

    sorts() {
      if (this.sort === 'desc') {
        this.sort = 'asc'
        this.getListApi()
      } else {
        this.sort = 'desc'
        this.getListApi()
      }
    },
    // rechargeRecord() {
    //   this.pitchOn = 'recharge'
    // },
    // ornamentRecord() {
    //   this.pitchOn = 'ornament'
    // },
    // presentRecord() {
    //   this.pitchOn = 'present'
    // },
    main() {
      if (this.mainConceal) {
        this.mainConceal = false
        this.assistantConceal = false
      } else {
        this.mainConceal = true
        this.assistantConceal = false
      }
    },
    assis() {
      if (this.assistantConceal) {
        this.assistantConceal = false
        this.mainConceal = false
      } else {
        this.assistantConceal = true
        this.mainConceal = false
      }
    },
    // 收入或支出
    mainLi(index, change_type) {
      this.change_type = change_type
      this.TS = index
      this.TST = 0
      this.type = '0'
      this.mainConceal = false
      this.assistantConceal = false
      this.getListApi()
    },
    // 其他类型
    assistantLi(index, type) {
      this.type = type
      this.TST = index
      this.mainConceal = false
      this.assistantConceal = false
      this.getListApi()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
// 引入css类
// @import '';
.pagination{
  margin-top: 20px;
}
::v-deep .el-pagination.is-background .btn-next{
   background-color: #1ef0ed;
    height: 30px;
    width: 80px;
    border-radius: 5px;
    margin: 0 10px;
    color: #000;
    font-size: 13px;
  cursor: url('@/assets/images/commom/hand-pointer (1).png'), auto;

}
::v-deep .el-pagination.is-background .btn-next:disabled{
color: #9e9e9e;
cursor: not-allowed !important;

}
::v-deep .el-pagination.is-background .btn-prev:disabled{
color: #9e9e9e;
cursor: not-allowed !important;
}
::v-deep .el-pager .more::before {
  cursor: url('@/assets/images/commom/hand-pointer (1).png'), auto;
}
::v-deep .el-pagination.is-background .btn-prev{
   background-color: #1ef0ed;
    height: 30px;
    font-size: 13px;
    width: 80px;
    border-radius: 5px;
    margin: 0 10px;
    color: #000;
  cursor: url('@/assets/images/commom/hand-pointer (1).png'), auto;

}
.loadMore {
  margin-top: -10px;
  width: 1000px;
  .btn {
    background-color: #FFA500;
    height: 30px;
    width: 70px;
    border-radius: 5px;
    margin: 0 10px;
    color: #fff;
  }
  .btn_num {
    width: 30px;
    height: 30px;
    background-color: #FFA500;
    border-radius: 5px;
    color: #fff;
    font-weight: 700;
  }
}
.MyLog {
   padding-top: 5%;
   width: 100%;
   min-height: 5rem;
  .MyLogRecord {

    display: flex;
    div {
      width: 130px;
      border: 2px #FFA500  solid;
      padding: 0 10px;
      margin: 0 10px;
      line-height: 27px;
    }
    .pitchOn {
      background-color: #FFA500;
      color: $hoverText;
    }
  }
  .rechargeRecord {
	     width: 80%;
		 margin-left: 10%;
    // 选择
    .select {
      display: flex;
      margin-top: 10px;
      .main {
        .all {
          width: 130px;
          border: 2px #FFA500 solid;
          padding: 0 10px;
          margin: 0 10px;
          line-height: 36px;
        }

        ul {
          position: absolute;
          z-index: 999;
          li {
            @extend .all;
            background-color: #272451;
          }
        }
      }
      .assistant {
        .all {
          width: 130px;
          border: 2px #FFA500 solid;
          padding: 0 10px;
          margin: 0 10px;
          line-height: 36px;
        }
        ul {
          position: absolute;
          z-index: 999;
          li {
            @extend .all;
            background-color: #272451;
          }
        }
      }
	  .assistant-right {
		  margin-left: auto;
	  		.returnRoute{

	  			width: 130px;
	  			border: 2px #FFA500 solid;
	  			padding: 0 10px;
	  			margin: 0 10px;
				margin-right:30px;
	  			line-height: 36px;
	  		}
	  }
    }
    // 表格
    .detail {
      margin: 10px;
      margin-top: 9px;
      padding-right: 9px;
      overflow-y: scroll;
      min-height: 510px;
	  height: 810px;
      /* 滚动条整体部分 */
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background-color: none;
        border-radius: 10px;
      }
      /* scroll轨道背景 */
      &::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: rgba(216, 216, 216, 0);
      }
      /* 滚动条中能上下移动的小块 */
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
        background-color: rgb(119, 117, 117);
      }
      .formH {
        th {
          height: 50px;
		  font-size: 20px !important;
          border: 1px #FFA500 solid;
        }
      }
      .formB {
        td {
          height: 50px;
		  font-size: 18px !important;
		  //border: 1px #FFA500 solid;
          &:nth-child(5) {
            color: #FFA500;
          }
        }
      }
    }

    .sticky-table th {
      position: sticky;
      top: 0; /* 首行永远固定在头部  */
      //background-color: $hoverText; /*设置表头背景色*/
    }

    .sticky-table th:first-child {
      z-index: 2; /*表头的首列要在上面*/
    }
  }
  .ornament {
    .detail {
      margin: 10px;
      margin-top: 9px;
      padding-right: 9px;
      overflow-y: scroll;
      height: 550px;
      /* 滚动条整体部分 */
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background-color: none;
        border-radius: 10px;
      }
      /* scroll轨道背景 */
      &::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: rgba(216, 216, 216, 0);
      }
      /* 滚动条中能上下移动的小块 */
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
        background-color: rgb(119, 117, 117);
      }
      .formH {
        th {
          height: 50px;
          border-top: 1px #FFA500 solid;
        }

      }
      .formB {
        td {
          height: 30px;
		  font-size: 0.3rem;
		  border-top: 1px #FFA500 solid;
          &:nth-child(5) {
            color: $theme;
          }
        }
      }
    }
  }
  .present {
    @extend .ornament;
  }
}
</style>
