import request from '@/api/request'
// 获取充值通道列表
export const get_player = (data) => {
	return request({
		url: '/api/recharge/get_ready_recharge',
		method: 'POST',
		data
	})
}

// 获取充值通道下可充值额度列表
export const get_recharge_list = (data) => {
	return request({
		url: '/api/recharge/get_recharge_list',
		method: 'POST',
		data
	})
}

// 充值
export const recharge = (data) => {
	return request({
		url: '/api/pay/recharge',
		method: 'POST',
		data
	})
}
//获取红包领取记录
export const get_receiving_records = (data) => {
	return request({
		url: '/api/red_code/get_receiving_records',
		method: 'POST',
		data
	})
}
// 获取下载地址
export const get_down_app_url = (data) => {
	return request({
		url: '/api/index/get_down_app_url',
		method: 'POST',
		data
	})
}
