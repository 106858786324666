<!-- 创建房间 -->
<template>
  <div
    class="int"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
  >
    <div class="int-box">
      <div class="int-box-title">
        <div class="int-box-title-text">创建房间</div>
      </div>
      <div class="int-box-frame">
        <div class="int-box-frame-off" :style="VPointer()" @click="off"></div>
        <div class="int-box-frame-content">
          <div class="int-box-frame-content-head">
            <div
              class="int-box-frame-content-head-towRoom"
              :style="VPointer()"
              :class="{ pitchOnRoom: roomTypeIs === 'grand' }"
              @click="roomType('grand')"
            >
              欧皇
            </div>
<!--            <div-->
<!--              class="int-box-frame-content-head-threeRoom"-->
<!--              :style="VPointer()"-->
<!--              :class="{ pitchOnRoom: roomTypeIs === 'nonEmirate' }"-->
<!--              @click="roomType('nonEmirate')"-->
<!--            >-->
<!--              非酋-->
<!--            </div>-->
            <div
              class="int-box-frame-content-head-threeRoom"
              :style="VPointer()"
              :class="{ pitchOnRoom: roomTypeIs === 'teamUp' }"
              @click="roomType('teamUp')"
            >
              组队
            </div>
          </div>
          <div class="int-box-frame-content-head" style="margin-top: 0.1rem">
            <div
              class="int-box-frame-content-head-towRoom"
              :style="VPointer()"
              :class="{ pitchOnRoom: pitchOnRoom === 'pitchOnTowRoom' }"
              @click="towRoom"
              v-if="roomTypeIs !== 'teamUp'"
            >
              双人房间
            </div>
            <div
              class="int-box-frame-content-head-threeRoom"
              :style="VPointer()"
              :class="{ pitchOnRoom: pitchOnRoom === 'pitchOnThreeRoom' }"
              @click="threeRoom"
              v-if="roomTypeIs !== 'teamUp'"
            >
              三人房间
            </div>
            <div
              class="int-box-frame-content-head-fourRoom"
              :style="VPointer()"
              :class="{ pitchOnRoom: pitchOnRoom === 'pitchOnFourRoom' }"
              @click="fourRoom"
            >
              四人房间
            </div>
          </div>
          <!-- 中间 -->
          <div class="int-box-frame-content-body">
            <div class="int-box-frame-content-body-head">已选中</div>
            <div class="int-box-frame-content-body-body">
              <ul class="int-box-frame-content-body-body-ul">
                <li
                  class="int-box-frame-content-body-body-ul-li"
                  v-for="(item, index) in addData"
                  :key="index"
                >
                  <div class="int-box-frame-content-body-body-ul-li-back">
                    <div class="int-box-frame-content-body-body-ul-li-back-box">
                      <img :src="item.img_main" alt=""/>
                    </div>
                    <div
                      class="int-box-frame-content-body-body-ul-li-back-main"
                    >
                      <div class="nums">{{ item.addAndSubtractCount }}</div>
                      <img :src="item.img_active" alt=""/>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="int-box-frame-content-body-fool">
              <div class="int-box-frame-content-body-fool-bout">
                <div class="int-box-frame-content-body-fool-bout-count">
                  回合数: {{ pitchOn.length }}/6
                </div>
                <div class="int-box-frame-content-body-fool-bout-kind">
                  盲盒种类: {{ battle_box_data.length }}/3
                </div>
              </div>
              <div class="int-box-frame-content-body-fool-value">
                盲盒价值:{{ total_box_price.toFixed(2) }}
              </div>
            </div>
          </div>
          <!-- 下 -->
          <div class="int-box-frame-content-fool">
            <ul class="int-box-frame-content-fool-ul">
              <li
                class="int-box-frame-content-fool-ul-li"
                v-for="(item, index) in battleBoxList"
                :key="index"
              >
                <!-- 加减 -->
                <div class="int-box-frame-content-fool-ul-li-addAndSubtract">
                  <div
                    class="
                      int-box-frame-content-fool-ul-li-addAndSubtract-subtract
                    "
                    :style="VPointer()"
                    @click="subtract(item, index)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-dash-lg"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"
                      />
                    </svg>
                  </div>
                  <div
                    class="
                      int-box-frame-content-fool-ul-li-addAndSubtract-count
                    "
                  >
                    {{ item.addAndSubtractCount }}
                  </div>
                  <div
                    class="int-box-frame-content-fool-ul-li-addAndSubtract-add"
                    :style="VPointer()"
                    @click="add(item.id, item, index)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus-lg"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                      />
                    </svg>
                  </div>
                </div>
                <!-- 主物 -->
                <div class="int-box-frame-content-fool-ul-li-back">
                  <div class="int-box-frame-content-fool-ul-li-back-box">
                    <img :src="item.img_main" alt=""/>
                  </div>
                  <div class="int-box-frame-content-fool-ul-li-back-main">
                    <img :src="item.img_active" alt=""/>
                  </div>
                </div>
                <!-- 名字 -->
                <div class="int-box-frame-content-fool-ul-li-name">
                  {{ item.name }}
                </div>
                <!-- 价格 -->
                <div class="int-box-frame-content-fool-ul-li-price">
                  <img
                    class="int-box-frame-content-fool-ul-li-price-img"
                    src="@/assets/images/n7_money.png"
                    alt=""
                  />
                  <div class="int-box-frame-content-fool-ul-li-price-text">
                    {{ item.price }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="immediately" :style="VPointer()" @click="immediately">
        立即创建
      </div>
    </div>
  </div>
</template>

<script>
import {create_battle_room} from '@/api/index/BlindBattle'

export default {
  name: 'CreateRoom',
  data() {
    return {
      pitchOnRoom: 'pitchOnTowRoom', // 选中房间
      addAndSubtractCount: 1,
      battleBoxList: [],
      battle_box_data: [], // 选中参数
      pitchOn: [], // 选中
      mode: '2', // 房间人数
      numberRounds: '', // 回合数
      total_box_price: 0.00,
      addData: [],
      roomTypeIs: 'grand',
      battle_type: '1'
    }
  },
  props: {
    visible: Boolean,
    battleBox: Array
  },
  watch: {
    visible(val) {
      if (val) {
        this.battleBoxList = this.battleBox
        for (let i = 0; i < this.battleBoxList.length; i++) {
          this.battleBoxList[i].addAndSubtractCount = 0
        }
      }
    },
    battle_box_data: {
      deep: true,
      //监视对象
      handler() {
        //console.log('a修改了')
      }
    }
  },
  created() {
    this.addData = []
  },
  methods: {
    // 关闭
    off() {
      this.pitchOn = []
      this.battle_box_data = []
      this.total_box_price = 0.00
      this.$emit('update:visible', false)
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = '' //出现滚动条
      document.removeEventListener('touchmove', mo, false)
    },
    // 选择房间类型
    roomType(type) {
      this.roomTypeIs = type
      switch (type){
        case 'grand' :
          this.pitchOnRoom = 'pitchOnTowRoom'
          this.battle_type = '1'
          this.mode = '2'
          break;
        case 'nonEmirate' :
          this.pitchOnRoom = 'pitchOnTowRoom'
          this.battle_type = '2'
          this.mode = '2'
          break;
        case 'teamUp' :
          this.pitchOnRoom = 'pitchOnFourRoom'
          this.battle_type = '3'
          this.mode = '4'
          break;
      }
    },
    // 选中双人房
    towRoom() {
      this.pitchOnRoom = 'pitchOnTowRoom'
      this.mode = '2'
    },
    // 选中三人房
    threeRoom() {
      this.pitchOnRoom = 'pitchOnThreeRoom'
      this.mode = '3'
    },
    // 选中四人房
    fourRoom() {
      this.pitchOnRoom = 'pitchOnFourRoom'
      this.mode = '4'
    },
    // 减
    subtract(item, i) {
      if (item.addAndSubtractCount > 0) {
        this.pitchOn.splice(this.pitchOn.indexOf(item), 1),
          this.battleBoxList[i].addAndSubtractCount--
        let obj = {}
        let peon = this.pitchOn.reduce((cur, next) => {
          obj[next.id] ? '' : (obj[next.id] = true && cur.push(next))
          return cur
        }, [])
        this.addData = peon
        for (let j = 0; j < this.battle_box_data.length; j++) {
          if (
            this.battle_box_data[j].battle_box_count > 1 &&
            item.id === this.battle_box_data[j].id
          ) {
            this.battle_box_data[j].battle_box_count--
            this.calculation_price()
            return this.battle_box_data
          }
          if (
            this.battle_box_data[j].battle_box_count == 1 &&
            item.id === this.battle_box_data[j].id
          ) {
            this.battle_box_data.splice(j, 1)
          }
        }
        this.calculation_price()
      } else {
        this.$message.error('最少选中1个饰品')
      }
    },
    // 加
    add(id, item, index) {
      if (
        this.pitchOn.length < 6 &&
        (this.battle_box_data.length < 3 || item.addAndSubtractCount > 0)
      ) {
        this.pitchOn.push(item)
        let obj = {}
        let peon = this.pitchOn.reduce((cur, next) => {
          obj[next.id] ? '' : (obj[next.id] = true && cur.push(next))
          return cur
        }, [])
        this.addData = peon
        if (!this.battle_box_data.length == 0) {
          this.battleBoxList[index].addAndSubtractCount++
          for (let i = 0; i < this.battle_box_data.length; i++) {
            if (item.id === this.battle_box_data[i].id) {
              //console.log(this.battle_box_data)
              this.battle_box_data[i].battle_box_count++
              this.calculation_price()
              return this.battle_box_data
            }
          }
          if (!this.battle_box_data.includes(item.id)) {
            //console.log(this.battle_box_data)
            this.battle_box_data.push({
              key: index,
              id: id,
              battle_box_count: this.addAndSubtractCount
            })
            this.calculation_price()
            return this.battle_box_data

          }
        } else {
          //console.log(this.battle_box_data)
          this.battleBoxList[index].addAndSubtractCount++,
            this.battle_box_data.push({
              key: index,
              id: id,
              battle_box_count: this.addAndSubtractCount
            })
          this.calculation_price()
        }

      } else {
        this.$message.error('最多选中6个饰品或3个种类')
      }
    },
    // 立即创建
    immediately() {
      create_battle_room({
        battle_box_data: this.battle_box_data,
        mode: this.mode,
        battle_type: this.battle_type
      }).then((res) => {
        if (res.status == 1) {
          this.$store.dispatch('getPlayerApi')
          this.$message({
            message: '创建成功',
            type: 'success',
            duration: 1000

          })
          setTimeout(() => {
            this.$router.push('/battleDetail?id=' + res.data.battle_room_id)
          }, 500)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    calculation_price() {
      let calculation_prices = 0
      //console.log("this.battle_box_data[i].battle_box_count "+this.battle_box_data[0].battle_box_count);
      if (this.battle_box_data.length > 0) {
        for (let i = 0; i < this.battle_box_data.length; i++) {
          calculation_prices += (this.battle_box_data[i].battle_box_count * this.battleBoxList[this.battle_box_data[i].key].price)
        }
        this.total_box_price = calculation_prices;
      } else {
        this.total_box_price = 0.00
      }
    }
  }
}
</script>
<style lang="scss" scoped>
// 引入css类
@import '@/styles/BlindBoxFightShow.scss';
@import '@/styles/variables';

.int-box-frame {
  width: 600px;
  height: 560px;
  background-size: 600px;
  padding: 40px;
}

.int-box-frame-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  &-head {
    width: 100%;
    color: $theme;
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px $theme solid;
    padding-bottom: 10px;

    .pitchOnRoom {
      background: $theme;
      color: #000;
    }

    & div {
      width: 100px;
      padding: 2px 10px;
      border: 2px $theme solid;
      border-radius: 5px;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 14px;
    padding: 15px 0;
    border-bottom: 1px $theme solid;
    padding-bottom: 10px;

    &-head {
      text-align: left;
    }

    &-body {
      &-ul {
        display: flex;
        justify-content: center;
        height: 36px;

        &-li {
          margin-right: 10px;

          &:nth-child(1) {
            margin-left: 0;
          }

          &-back {
            width: 80px;
            height: 80px;
            position: relative;
            top: -22px;

            &-box {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              & > img {
                width: 80px;
              }
            }

            &-main {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              & > img {
                width: 80px;
              }
            }
          }
        }
      }
    }

    &-fool {
      width: 100%;
      display: flex;
      justify-content: space-between;

      &-bout {
        display: flex;
      }

      &-value {
        display: flex;
      }
    }
  }

  &-fool {
    width: 100%;

    &-ul {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      overflow-y: scroll;
      padding-bottom: 10px;
      height: 290px;
      /* 滚动条整体部分 */
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background-color: none;
        border-radius: 10px;
      }

      /* scroll轨道背景 */
      &::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: rgba(216, 216, 216, 0);
      }

      /* 滚动条中能上下移动的小块 */
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
        background-color: rgb(119, 117, 117);
      }

      &-li {
        margin-right: 2.5%;

        &:nth-child(4n) {
          margin-right: 0px;
        }

        margin-top: 10px;
        width: 23%;
        height: 150px;
        border: 1.5px $theme solid;
        border-radius: 5px;
        flex-direction: column;
        align-items: center;
        display: flex;
        justify-content: space-around;

        &-addAndSubtract {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          margin-top: 5px;

          &-count {
            background-color: $theme;
            color: #000;
            padding: 0px 20px;
            margin: 0 5px;
            border-radius: 50px;
          }
        }

        // 主物
        &-back {
          position: relative;
          margin-bottom: -5px;

          &-box {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            & > img {
              width: 70px;
            }
          }

          &-main {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            & > img {
              width: 50px;
            }
          }
        }

        // 名字
        &-name {
          font-size: 13px;
        }

        // 价格
        &-price {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          &-img {
            width: 20px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.immediately {
  border: 2px $theme solid;
  display: inline-block;
  border-radius: 5px;
  padding: 5px 20px;

  &:hover {
    color: $hoverText;
    background: $theme;
  }
}

.nums {
  position: absolute;
  width: 20px;
  height: 20px;
  background: #ffc400;
  border-radius: 50%;
  color: #000;
  text-align: center;
  line-height: 20px;
  right: 0;
  font-weight: bold;
}
</style>
