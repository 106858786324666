<template>
	<!-- <div id="app" :style="BodyPointer()" > -->
	<div id="app"
	:style="BodyPointer()"
	:class="current_route != 'BoxShow' ? 'Background_starlight4' : 'Background_starlight'"
	@touchmove.prevent>
		<div class="home-head" v-if="$route.name !== 'Card' && $route.name !== 'CardList'">
			<HomeHead />
		</div>
		<router-view />
		<div class="home-foot" v-if="$route.name !== 'Card' && $route.name !== 'CardList'">
			<FunctionBar />
			<HomeFoot />
      <invitePop></invitePop>
		</div>
	</div>
</template>
<script>
	// import BodyBackground from '@/components/BodyBackground.vue'
	import HomeHead from '@/views/HomeHead.vue'
	import HomeFoot from '@/views/HomeFoot.vue'
	import FunctionBar from '@/views/functionBar.vue'
	import invitePop from '@/components/Battle/invitePop.vue'
	export default {
		data() {
			return {
				blackAndWhite: false,
				current_route:'',
			}
		},
		components: {
			HomeHead,
			HomeFoot,
			FunctionBar,
      invitePop
		},
		watch: {
			'$route'(){
        this.current_route = this.$route.name
			}
		},
		created() {
			this.current_route = this.$route.name
			if (this._isMobile()) {
				this.$router.onReady(() => {
					if (this.$route.query.code) {
						window.location.href = 'http://m.n7buff.com/#/?code=' + this.$route.query.code
					} else {
						window.location.href = 'http://m.n7buff.com/'
					}
				})
			}
		},
		methods: {
			//判断访问设备
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
					)
				if (flag == 'iPad,iPad') {
					flag = false
				}
				return flag
			},
		},
	}
</script>
<style lang="scss">
	@import '@/styles/variables.scss';

	.tool {
		border: 0 !important;
		background: transparent !important;
	}

	.loadingInstance .el-loading-spinner .path {
		stroke: #1ef0ed !important;
	}
body,html{
  height: 100%;
}
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #fff;
		font-size: 16px;
    height: 100%;
	}
	.Background_starlight4{
		background: url('https://oss.n7buff.com/web/Background_starlight4.png') no-repeat;
		background-size: 1600px !important;
	}
	.Background_starlight{
		background: url('https://oss.n7buff.com/web/images/Background_starlight.png') no-repeat;
		background-size: 1600px !important;
	}
	.home-head {
		width: 100%;
		z-index: 80;
		position: fixed;
		top: 0;
	}

	.home-body {
		//margin-top: 140px;
	}

	// html {
	//   filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
	//   -webkit-filter: grayscale(90%);
	// }
</style>
<style>
	.el-message {
		max-width: 2.75rem;
		width: 2.75rem;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: .15rem 0 !important;
		top: .75rem !important;
		font-size: .18rem !important;

	}

	.el-message--success .el-icon-success {
		display: none;
	}

	.el-message-box {
		position: relative;
		/* position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10000; */
		width: 3.5rem !important;
		height: 2.5rem !important;
		background-color: rgba(255, 255, 255, 0);
		background-image: url(https://oss.n7buff.com/web/images/bgs.png);
		background-color: rgba(255, 255, 255, 0) !important;
		background-size: 100% 100%;
		border: none !important;
		/* font-size: 0.4rem !important; */

	}

	.el-button--default {
		background: rgb(181, 181, 181);
	}

	.el-message-box__title {
		width: 100%;
		font-size: .18rem !important;
		height: .4rem;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff !important;
		margin-top: -0.1rem;
		/* border: 1px solid red; */
	}

	.el-message-box__message {
		margin-top: 0.2rem !important;
		/* background-color: red; */
		height: 1rem;
		font-size: 0.2rem;
		color: #fff;
		/* border: 1px solid red; */

	}

	.el-message-box__content {
		height: 1rem !important;
	}

	.el-message-box__message p {
		line-height: 0.5rem !important;
		text-align: center;
	}

	.el-message-box__message p img {
		width: 1rem;
	}

	.el-message-box__status::before {
		padding-left: 0.5rem !important;
		/* width: 1rem !important; */
	}

	.el-message-box__btns {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-content: center;
		padding: 0 0.25rem !important;
		position: absolute;
		bottom: 0.2rem;
		right: 0;
	}

	.el-message-box__btns .el-button {
		/* padding: 0.1rem; */
		width: 1rem;
		height: 0.4rem;
		border: none;
		border-radius: 0.1rem;
		margin: 0 0.1rem;
	}

	.el-button--small span {
		font-size: 0.2rem !important;
	}

	.el-icon-close:before {
		display: none;
		content: '\e6db';
		color: #fff;
		position: absolute;
		top: 0.05rem;
		right: 0.05rem;
		background-color: #2724514a;
		border-radius: 25%;
		padding: 0.05rem;
		font-size: 0.25rem;
	}

	.index_title {
		font-size: 0.25rem;
		font-weight: 700;
		background-image: url('@/assets/images/showImages/n7open.png');
		background-size: 100%;
		margin: 0.15rem 0;
	}
</style>
