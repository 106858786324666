<template>
  <div>
    <el-dialog
      custom-class="online-list"
      :visible="dialogVisible"
      >
      <div slot="title" class="list-title">在线列表</div>
      <div class="list-content">
        <div class="close"></div>
        <div class="shuaxin">
          <img src="https://oss.n7buff.com/web/images/Battle/shuaxin.png" alt="">
        </div>
        <div class="list-ul">
          <div class="list-li" v-for="index in 10" :key="index">
            <div class="list-l">
              <img class="list-li-l-tx" src="" alt="">
              <span class="list-li-l-name">张三</span>
            </div>
            <div class="list-r">
              <div class="list-r-btn">邀请</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script >
export  default {
    props:{
      dialogVisible:{
        type: Boolean,
        default: false
      }
    }
}
</script>
<style scoped lang="scss">
::v-deep .online-list{
  width: 437px;
  height: 375px;
  background: url("https://oss.n7buff.com/web/images/Battle/online-list-bg.png") no-repeat;
  background-size: 100% 100%;
  .el-dialog__header{
    padding-top: 10px;
  }
  .list-title{
    font-size:18px ;
    color: #FFFFFF;
    text-shadow: 0px 0px 6px #00FFF7;
    font-weight: bold;
  }
  .list-content{
    width: 293px;
    margin: 0 auto;
    .list-ul{
      width: 100%;
      height: 220px;
      overflow: auto;
      margin-top: 10px;
      .list-li{
        padding-left: 8px;
        padding-bottom: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #22FFFC;
        margin-bottom: 14px;
        .list-l{
          display: flex;
          align-items: center;
        }
        .list-li-l-tx{
          width: 25px;
          height: 25px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 12px;
        }
      }
      .list-r-btn{
        width: 73px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #22FFFC;
        font-size: 13px;
        background: url("https://oss.n7buff.com/web/images/Battle/online-btn.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .close{
    width: 41px;
    height: 41px;
    background: url("https://oss.n7buff.com/web/images/Battle/colse.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 10px;
    top: 30px;
    cursor: pointer;
  }
  .shuaxin{
    width: 23px;
    height: 23px;
    position: absolute;
    left: 28px;
    top: 60px;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
</style>
