<!-- 登录 -->
<template>
  <div class="">
    <div class="login">
      <div class="close" @click="closes" :style="VPointer()">
        <img src="@/assets/images/fightImages/close.png" alt="" />
      </div>
      <div class="login-box">
        <div
          :class="[
            { 'login-but': this.login === 'logIn' },
            { 'sigin-but': this.login === 'sigIn' }
          ]"
          v-if="login !== 'retrievePassword'"
        >
          <div class="login-log" :style="VPointer()" @click="logins">
            登录
          </div>
          <div class="login-sig" :style="VPointer()" @click="sigins">
            注册
          </div>
        </div>
        <!-- 注册组件 -->
        <div class="comsigIn" v-if="this.login === 'sigIn'">
          <register @regSuc="regSuc" />
        </div>
        <div class="retrievePasswordTitle" v-if="login === 'retrievePassword'">
          <img src="@/assets/images/userWidget/找回密码.png" alt="" />
        </div>
        <!--  -->
        <form class="elForm loginFrom" :class="this.login === 'retrievePassword' ? 'retrieveFrom':''" v-if="this.login !== 'sigIn'">
          <div class="zh" v-if="this.login === 'logIn'">
            <div
              class="passwordLogin"
              :class="!this.cellVerification ? 't' : ''"
              :style="VPointer()"
              @click="passwordLogin"
            >
              密码登录
            </div>
            <div
              class="phoneLogin"
              :class="this.cellVerification ? 't' : ''"
              :style="VPointer()"
              @click="phoneLogin"
            >
              手机验证码登录
            </div>

            <!-- <div
              v-if="this.login === 'sigIn'"
              class="phoneLogin"
              :class="this.EmailBack ? 't' : ''"
              :style="VPointer()"
              @click="EBack"
            >
              邮箱注册
            </div> -->
            <!-- <div
              v-if="this.login === 'sigIn'"
              class="passwordLogin"
              :class="!this.EmailBack ? 't' : ''"
              :style="VPointer()"
              @click="cellSig"
            >
              手机号注册
            </div> -->
            <!-- <div
              v-if="this.login === 'retrievePassword'"
              class="phoneLogin"
              :class="this.EmailBack ? 't' : ''"
              :style="VPointer()"
              @click="EBack"
            >
              邮箱验证码找回
            </div>
            <div
              v-if="this.login === 'retrievePassword'"
              class="passwordLogin"
              :class="!this.EmailBack ? 't' : ''"
              :style="VPointer()"
              @click="cellBack"
            >
              手机验证码找回
            </div> -->
          </div>
          <div
            class="cellNumber"
            v-if="
              !this.EmailBack &&
              (this.login === 'logIn' || this.login === 'retrievePassword')
            "
          >
            <input
              type="text"
              placeholder="请输入手机号"
              v-model="logInForm.username"
              oninput="value=value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')"
            />
            <img src="@/assets/images/userWidget/手机.png" alt="" />
          </div>
          <div class="cellNumber" v-if="this.EmailBack">
            <input
              type="text"
              placeholder="请输入邮箱"
              oninput="value=value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')"
            />
            <img src="@/assets/images/userWidget/邮箱.png" alt="" />
          </div>
          <!-- 登录密码 -->
          <div
            class="password"
            v-if="!cellVerification && this.login === 'logIn'"
          >
            <input
              type="password"
              placeholder="请输入密码"
              autocomplete="off"
              v-model="logInForm.password"
              oninput="value=value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')"
            />
            <img class="a" src="@/assets/images/userWidget/锁.png" alt="" />
          </div>
          <!-- 获取登录验证码 -->
          <div
            class="password getcode"
            v-if="cellVerification && this.login === 'logIn'"
          >
            <input
              v-model="logInForm.code"
              type="text"
              placeholder="请输入验证码"
              oninput="value=value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')"
            />
<!--            <img class="a" src="@/assets/images/userWidget/验证码.png" alt="" />-->
            <el-button
              round
              size="mini"
              class="b"
              :loading="logInLoading"
              :disabled="logInDisabled"
              :style="VPointer()"
              @click="openCode"
              ><span v-if="!logGetCode">获取验证码</span>
              <span v-else>{{ logInCountDown }}后再次获取</span>
            </el-button>
          </div>
          <!-- 获取找回密码手机验证码 -->
          <div class="password getcode" v-if="this.login === 'retrievePassword'">
            <input
              type="text"
              placeholder="请输入验证码"
              v-model="logInForm.code"
              oninput="value=value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')"
            />
            <el-button
              round
              size="mini"
              class="b"
              :loading="retrieveInLoading"
              :disabled="retrieveInDisabled"
              :style="VPointer()"
              @click="openCode"
              ><span v-if="!retrieveGetCode">获取验证码</span>
              <span v-else>{{ retrieveInCountDown }}后再次获取</span>
            </el-button>
          </div>
          <div class="password" v-if="this.login === 'retrievePassword'">
            <input
              type="password"
              placeholder="请输入不超过16位的密码"
              autocomplete="off"
              v-model="logInForm.password"
              oninput="value=value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')"
            />
            <img class="a" src="@/assets/images/userWidget/锁.png" alt="" />
          </div>
          <div
            class="password password2"
            v-if="this.login === 'retrievePassword'"
          >
            <input
              type="password"
              placeholder="请再次输入密码"
              autocomplete="off"
              v-model="logInForm.password_again"
            />
            <img
              class="a"
              src="@/assets/images/userWidget/锁.png"
              alt=""
              oninput="value=value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')"
            />
          </div>
          <!-- 忘记密码 -->
          <div class="pwdw" v-if="this.login === 'logIn'">
            <span :style="VPointer()" @click="retrieveP">忘记密码?</span>
          </div>
          <div style="color: red" v-if="checkedHint && this.login != 'sigIn'">请勾选用户协议和政策</div>
          <div v-if="this.login === 'logIn'" class="enterLog" :style="VPointer()" @click="LogNow">
            立即登录
          </div>
          <div v-if="this.login === 'retrievePassword'" class="enterSig">
            <div class="goLoginImg" :style="VPointer()" @click="Reset_passwordApi">确 定</div>
            <div class="goLogin" :style="VPointer()" @click="goLogin">
              去登录
            </div>
          </div>
          <div v-if="false" :style="VPointer()" class="login-text">
            <span @click="passwordLogin" v-if="this.cellVerification">密码登录</span>
            <span @click="phoneLogin" v-if="!this.cellVerification">验证码登录</span>
          </div>
        </form>
        <!-- 隐私政策 -->
        <div class="yinsi" v-if="this.login != 'sigIn'">
          <div class="agreement">
            <input type="checkbox" id="checked" v-model="checked" :style="VPointer()" />
            <label for="checked" :style="VPointer()">
              我已经满18岁，并阅读同意
              <span :style="VPointer()" @click="goHelp(3)">《用户协议》</span>
              和
              <span :style="VPointer()" @click="goHelp(2)">《隐私政策》</span>
              ，承诺理性消费。
            </label>

          </div>
        </div>
      </div>
    </div>
    <!-- 滑动验证弹窗 -->
    <Vcode :show="isShow" @success="onSuccess" @close="onClose" />
  </div>
</template>

<script>
import Vcode from 'vue-puzzle-vcode'
import register from './register.vue'

import { get_player } from '@/api/index/personalCenter'
import {
  postLogin,
  postRegister,
  postRegisterGet_code,
  postLoginGet_code,
  postReset_password,
  get_login_code,
  logincode_login
} from '@/api/index/logIn'
import { isMobile, isSensitiveWord } from '@/utils/validate'
export default {
  components: {
    Vcode,
    register
  },
  name: 'logIn',
  data() {
    return {
      cellVerification: false, // 手机验证码登录
      EmailBack: false, // 邮箱验证码找回
      login: 'sigIn', // 显示登录或注册
      checked: false, // 用户协议
      checkedHint: false, // 用户协议提示
      isShow: false,
      // 登录获取验证码
      logGetCode: false, // 获取验证码倒计时
      logInCountDown: null, // 验证码倒计时
      logInTimer: null, //设置计时器,
      logInLoading: false,
      logInDisabled: false,
      // 注册获取验证码
      sigGetCode: false, // 获取验证码倒计时
      sigInCountDown: null, // 验证码倒计时
      sigInTimer: null, //设置计时器,
      sigInLoading: false,
      sigInDisabled: false,
      // 获取找回密码手机验证码
      retrieveGetCode: false, // 获取验证码倒计时
      retrieveInCountDown: null, // 验证码倒计时
      retrieveInTimer: null, //设置计时器,
      retrieveInLoading: false,
      retrieveInDisabled: false,
      // 登录注册
      logInForm: {
        username: '', //账号
        password: '', //密码
        password_again: '', // 二次密码
        code: '', // 验证码
        nike_name: '', // 昵称
        invite_code: '' // 邀请码
      }
    }
  },
  props: {
    homeLogin: String
  },
  watch: {
    '$store.state.logSignRetrieve'() {
      this.login = this.homeLogin
    }
  },
  created() {
		if(this.$route.query.code){
			this.logInForm.invite_code = this.$route.query.code
		}
	},
  methods: {
    goHelp(e) {
      this.$router.push('/Helpcenter?id='+e)
      this.closes()
    },
    //注册成功
    regSuc() {
      this.login = 'logIn'
    },
    onSuccess() {
      if (this.cellVerification && this.login === 'logIn') {
        // 手机验证码登录
        this.logInGetCodeApi()
      } else if (this.cellVerification && this.login === 'sigIn') {
        this.sigInGetCodeApi()
      } else if (this.login === 'retrievePassword') {
        this.retrieveInGetCodeApi()
      }
      this.isShow = false
    },
    openCode() {
      if (this.logInForm.username) {
        this.isShow = true
      }
    },
    onClose() {},
    // 获取个人余额信息
    async get_playerApi() {
      const res = await get_player()
      //console.log(res)
    },
    // 获取登录短信验证码
    logInGetCodeApi() {
      this.logInLoading = true
      get_login_code({
        username: this.logInForm.username
      }).then((res) => {
        //console.log(res.status)
        if (res.status === 1) {
          this.logInDisabled = true
          //console.log(res)
          this.logInLoading = false
          let TIME_COUNT = 60
          this.logInCountDown = TIME_COUNT
          this.logGetCode = true
          this.logInTimer = setInterval(() => {
            if (this.logInCountDown > 0 && this.logInCountDown <= TIME_COUNT) {
              this.logInCountDown--
            } else {
              clearInterval(this.sigInTimer)
              this.sigInTimer = null
              this.logInTimer = null
              this.logGetCode = false
              this.logInDisabled = false
            }
          }, 1000)
        } else {
          this.$message.error(res.msg)
          this.logInLoading = false
        }
      })
    },
    // 获取注册短信验证码
    sigInGetCodeApi() {
      if (isMobile(this.logInForm.username)) {
        this.sigInLoading = true
        postRegisterGet_code({
          username: this.logInForm.username
        }).then((res) => {
          if (res.status === 1) {
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.sigInDisabled = true
            //console.log(res)
            this.sigInLoading = false
            let TIME_COUNT = 60
            this.sigInCountDown = TIME_COUNT
            this.sigGetCode = true
            this.sigInTimer = setInterval(() => {
              if (
                this.sigInCountDown > 0 &&
                this.sigInCountDown <= TIME_COUNT
              ) {
                this.sigInCountDown--
              } else {
                clearInterval(this.sigInTimer)
                this.sigInTimer = null
                this.sigGetCode = false
                this.sigInDisabled = false
              }
            }, 1000)
          } else {
            this.sigInLoading = false
            this.$message({
              message: res.msg,
              type: 'error'
            })
          }
        })
      } else {
        this.$message({
          message: '请正确输入手机号',
          type: 'error'
        })
      }
    },
    // 获取找回密码手机验证码
    retrieveInGetCodeApi() {
      if (isMobile(this.logInForm.username)) {
        this.retrieveInLoading = true
        postLoginGet_code({
          username: this.logInForm.username
        }).then((res) => {
          if (res.status === 1) {
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.retrieveInDisabled = true
            //console.log(res)
            this.retrieveInLoading = false
            let TIME_COUNT = 60
            this.retrieveInCountDown = TIME_COUNT
            this.retrieveGetCode = true
            this.retrieveInTimer = setInterval(() => {
              if (
                this.retrieveInCountDown > 0 &&
                this.retrieveInCountDown <= TIME_COUNT
              ) {
                this.retrieveInCountDown--
              } else {
                clearInterval(this.retrieveInTimer)
                this.retrieveInTimer = null
                this.retrieveGetCode = false
                this.retrieveInDisabled = false
              }
            }, 1000)
          } else {
            this.retrieveInLoading = false
            this.$message({
              message: res.msg,
              type: 'error'
            })
          }
        })
      } else {
        this.$message({
          message: '请正确输入手机号',
          type: 'error'
        })
      }
    },
    // 立即登录
    LogNow() {
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        loading.$el.style.fontSize = "0.18rem"
      if (this.checked) {
        // 调用登录接口
        if (this.cellVerification) {
          //console.log('验证码登录')
          logincode_login({
            username: this.logInForm.username,
            code: this.logInForm.code
          }).then((res) => {
            if (res.status === 1) {

              if (sessionStorage.getItem('n7_token')) {
                this.$store.dispatch('getPlayerApi')
              }
              this.$message({
                message: res.msg,
                type: 'success'
              })
              loading.close()
              this.$store.dispatch('codeLogin',res.data.token)
              this.closes()
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              })
              loading.close()
            }
          })
        } else {
          postLogin({
            username: this.logInForm.username,
            password: this.logInForm.password
          }).then((res) => {
            if (sessionStorage.getItem('n7_token')) {
              this.$store.dispatch('getPlayerApi')
            }
            //console.log(res)
            this.$store.dispatch('login',res)
            if (res.status === 1) {
              this.$message({
                message: res.msg,
                type: 'success'
              })
              loading.close()
              this.closes()
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              })
              loading.close()
            }
          })
        }
      } else {
        this.checkedHint = true
        loading.close()
        setTimeout(() => {
          this.checkedHint = false
        }, 5000)
      }
    },
    // 立即找回密码
    Reset_passwordApi() {
      postReset_password({
        username: this.logInForm.username,
        code: this.logInForm.code,
        password: this.logInForm.password,
        password_again: this.logInForm.password_again
      }).then((res) => {
        //console.log(res)
        if (res.status === 1) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },

    // 关闭
    closes() {
      this.$store.dispatch('setLogSignRetrieve', false)
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = '' //出现滚动条
      document.removeEventListener('touchmove', mo, false)
      // Object.assign(this.$data, this.$options.data()) // 重置data
    },
    // 手机验证码登录
    phoneLogin() {
      this.cellVerification = true
    },
    // 密码登录
    passwordLogin() {
      this.cellVerification = false
    },
    // 切换登录
    logins() {
      this.login = 'logIn'
    },
    // 切换注册
    sigins() {
      this.login = 'sigIn'
    },
    // 手机号注册
    cellSig() {
      this.EmailBack = false
    },
    // 忘记密码
    retrieveP() {
      this.login = 'retrievePassword'
    },
    // 邮箱验证码找回
    EBack() {
      this.EmailBack = true
    },
    // 手机验证码找回
    cellBack() {
      this.EmailBack = false
    },
    // 去登录
    goLogin() {
      this.login = 'logIn'
    }
  }
}
</script>
<style lang="scss" scoped>
// 引入css类
@import '@/styles/variables';
@import '@/styles/login';
</style>
