<!-- 开箱 箱内物品 -->
<template>
  <div class="InBox">
    <div class="InBox-title">
      <img
        class="InBox-title-img"
        src="@/assets/images/showImages/title.png"
        alt=""
      />
      <img
        class="InBox-title-text"
        src="@/assets/images/showImages/box-in.png"
        alt=""
      />
    </div>
    <div class="InBox-res">
      <ul class="InBox-res-ul">
        <li class="InBox-res-ul-li" v-for="item in skinsList" :key="item.id">
          <img class="InBox-res-ul-li-back" :src="item.rarity_thumb" alt="" />
          <img class="InBox-res-ul-li-main" :src="item.image_url" alt="" />
          <img
            class="InBox-res-ul-li-shadow"
            src="@/assets/images/showImages/shadow.png"
            alt=""
          />

          <el-tooltip placement="bottom">
            <div slot="content">{{ item.item_name }}</div>
            <div class="InBox-res-ul-li-name InBox-res-ul-li-li">
              {{ item.item_name }}
            </div>
          </el-tooltip>
          <div class="InBox-res-ul-li-currency InBox-res-ul-li-li">
            <div class="InBox-res-ul-li-currency-yuan">
              <img src="@/assets/images/n7_money.png" alt="" />
              <div>{{ item.price }}</div>
            </div>
            <div>{{ item.probability }}%</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BoxShowBodyIn',
  data() {
    return {}
  },
  props: { skinsList: Array },
  created() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
// 引入css类
@import '@/styles/BoxShowBodyIn.scss';
</style>
