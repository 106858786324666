<!-- 我的日志 -->
<template>
	<div class="MyLog">
		<div class="MyLogRecord">
			<div class="accountDetails" :class="[{ pitchOn: pitchOn === 'recharge' }]" @click="rechargeRecord" :style="VPointer()">账户明细</div>
			<div class="ornamentRecord" :class="[{ pitchOn: pitchOn === 'ornament' }]" @click="ornamentRecord" :style="VPointer()">饰品记录</div>
			<!--    <div
        class="presentRecord"
        :class="[{ pitchOn: pitchOn === 'present' }]"
        @click="presentRecord"
        :style="VPointer()"
      >
        礼品记录
      </div> -->
		</div>
		<div class="assistant-sorta" v-show="pitchOn === 'ornament'" style="margin-left:80%;">
			<div class="all" v-if="JWsort === 'desc'" :style="VPointer()" style="margin-top:0.05rem;" @click="jwsorts">正序</div>
			<div class="all" v-if="JWsort === 'asc'" :style="VPointer()"  style="margin-top:0.05rem;" @click="jwsorts">倒序</div>
		</div>
		<!-- 账户明细 -->
		<div v-show="pitchOn === 'recharge'" class="rechargeRecord">
			<div class="select">
				<div class="main">
					<div class="all" @click="main" :style="VPointer()">
						{{ recordPull[TS].mainName }}
						<i class="el-icon-arrow-down"></i>
					</div>
					<ul v-show="mainConceal">
						<li v-for="(item, index) in recordPull" :key="item.id" @click="mainLi(index, item.change_type)" :style="VPointer()">{{ item.mainName }}</li>
					</ul>
				</div>
				<div class="assistant">
					<div class="all" @click="assis" :style="VPointer()">
						{{ assistants[TS].assistant[TST].assistantName }}
						<i class="el-icon-arrow-down"></i>
					</div>
					<ul v-show="assistantConceal">
						<li v-for="(item, index) in assistants[TS].assistant" :key="item.id" @click="assistantLi(index, item.type)" :style="VPointer()">
							{{ item.assistantName }}
						</li>
					</ul>
				</div>
				<!-- 排序 -->
				<div class="assistant-sort" v-show="pitchOn === 'recharge'" style="margin-left:52%;">
					<div class="all" v-if="sort === 'desc'" :style="VPointer()" @click="sorts">正序</div>
					<div class="all" v-if="sort === 'asc'" :style="VPointer()" @click="sorts">倒序</div>
				</div>
			</div>

			<div class="detail sticky-table">
				<table border="1" cellspacing="0" width="100%">
					<tr class="formH">
						<th>日 &nbsp; 期</th>
						<th>类 &nbsp; 型</th>
						<th>描 &nbsp; 述</th>
						<th>金 &nbsp; 额</th>
					</tr>
					<tr class="formB" v-for="item in rowsList" :key="item.id">
						<td>{{ item.update_time }}</td>
						<td>{{ item.change_type === 1 ? '收入' : '支出' }}</td>
						<td>{{ item.type_name }}</td>
						<td>{{ item.amount }}</td>
					</tr>
				</table>
			</div>
		</div>
		<!-- 饰品记录 -->
		<div v-show="pitchOn === 'ornament'" class="ornament">
			<div class="detail sticky-table">
				<table border="1" cellspacing="0" width="100%">
					<tr class="formH">
						<th>饰品名称</th>
						<th>来 &nbsp;源</th>
						<th>状 &nbsp;态</th>
						<th>时 &nbsp; 间</th>
					</tr>
					<tr class="formB" v-for="(item, index) in ornamentsData" :key="item.id">
						<td>{{ item.item_name }}</td>
						<td>{{ item.way_name }}</td>
						<td>{{ item.state_name }}</td>
						<td>{{ item.update_time }}</td>
					</tr>
				</table>
			</div>
		</div>
		<!-- 礼品记录 -->
		<!-- 		<div v-show="pitchOn === 'present'" class="present">
			<div class="detail sticky-table">
				<table border="1" cellspacing="0" width="100%">
					<tr class="formH">
						<th>日 &nbsp; 期</th>
						<th>类 &nbsp; 型</th>
						<th>描 &nbsp; 述</th>
						<th>数 &nbsp; 量</th>
						<th>余 &nbsp; 额</th>
					</tr>
					<tr class="formB" v-for="item in forms" :key="item.id"><td v-for="item in 5" :key="item.id">100</td></tr>
				</table>
			</div>
		</div> -->
		<el-pagination
			v-show="pitchOn === 'recharge'"
			background
			layout="prev, pager, next"
			@current-change="handleCurrentChange"
			:page-size="page_size"
			:total="total_rows"
			prev-text="上一页"
			next-text="下一页"
		></el-pagination>
		<el-pagination
			v-show="pitchOn === 'ornament'"
			background
			layout="prev, pager, next"
			@current-change="handleCurrentChanges"
			:page-size="JWpage_size"
			:total="JWtotal_rows"
			prev-text="上一页"
			next-text="下一页"
		></el-pagination>
	</div>
</template>

<script>
import { get_list, jewe_lry_name } from '@/api/index/personalCenter';
export default {
	name: 'MyLog',
	data() {
		return {
			pitchOn: 'recharge', //默认状态
			mainConceal: false,
			assistantConceal: false,
			TS: 0,
			TST: 0,
			page_count: null, //总页数
			recordPull: [
				{
					mainName: '全部',
					change_type: '0'
				},
				{
					mainName: '支出',
					change_type: '2'
				},
				{
					mainName: '收入',
					change_type: '1'
				}
				/* {
          mainName: '用户充值'
        } */
			],
			assistants: [
				{
					assistant: [{ assistantName: '全部' }]
				},
				{
					assistant: [
						{ assistantName: '全部', type: '0' },
						{ assistantName: '普通盲盒', type: '-1' },
						{ assistantName: '盲盒对战', type: '-2' },
						{ assistantName: '幸运饰品', type: '-3' },
						{ assistantName: '商城消费', type: '-4' }
						/* { assistantName: '平台消费', type: '-5' } */
					]
				},
				{
					assistant: [
						{ assistantName: '全部', type: '0' },
						{ assistantName: '饰品回收', type: '1' },
						/* { assistantName: '消费', type: '2' }, */
						{ assistantName: '充值', type: '3' },
						{ assistantName: '对战获得', type: '4' },
						{ assistantName: '对战平分', type: '5' },
						/* { assistantName: '后台充值', type: '6' }, */
						{ assistantName: '幸运饰品', type: '7' },
						{ assistantName: '对战撤回', type: '8' },
						{ assistantName: '推广分红', type: '9' },
						{ assistantName: '口令红包', type: '10' }
					]
				},
				{
					assistant: [{ assistantName: '充值' }]
				}
			],
			rowsList: [], //账户明细
			forms: 17,
			sort: 'desc', // 排序
			page: 1, // 页码
			page_size: 15, // 每页数量
			change_type: '0', // 收入支出
			type: '0', // 类型
			total_rows: null, // 总数据量
			ornamentsData: [], //饰品记录
			JWpage: 1, // 页码
			JWpage_size: 15, // 每页数量
			JWtotal_rows: null, // 总数据量
			JWpage_count: null, //总页数
			JWsort: 'desc'
		};
	},
	created() {
		// this.getJewelry();
	},
	props: {
		content: String
	},
	watch: {
		content() {
			if (this.content === 'MyLog') {
				if (sessionStorage.getItem('n7_token')) {
					this.$store.dispatch('getPlayerApi');
				}
				this.getListApi();
				//this.getJewelry();
			}
		}
	},
	methods: {
		// 获取个人明细
		getListApi() {
			get_list({
				sort: this.sort,
				page: this.page,
				page_size: this.page_size,
				change_type: this.change_type,
				type: this.type
			}).then(res => {
				console.log(res);
				this.rowsList = res.data.rows;
				this.total_rows = res.data.total_rows;
				this.page_count = res.data.page_count;
			});
		},
		getJewelry() {
			jewe_lry_name({
				sort: this.JWsort,
				page: this.JWpage,
				page_size: this.JWpage_size
			}).then(res => {
				console.log('饰品记录', res);
				this.ornamentsData = res.data.rows;
				console.log(this.ornamentsData);
				this.JWtotal_rows = res.data.total_rows;
				this.JWpage_count = res.data.page_count;
			});
		},
		// 分页
		handleCurrentChange(val) {
			console.log('分页', val);
			if (this.rowsList) this.page = val;

			this.getListApi();
		},
		handleCurrentChanges(val) {
			console.log('分页2', val);
			if (this.ornamentsData) this.JWpage = val;
			this.getJewelry();
		},
		// handleCurrentChange() {
		//   this.page--
		//   if (this.page < 1) {
		//     this.page = 1
		//   }

		//   this.getListApi()
		// },
		// handleCurrentChangeTwo() {
		// 	this.page++;
		// 	if (this.page > this.page_count) {
		// 		this.page = this.page_count;
		// 	}
		// 	this.getListApi();
		// },

		sorts() {
			if (this.sort === 'desc') {
				this.sort = 'asc';
			} else {
				this.sort = 'desc';
			}
			this.total_rows = 0;
			this.page = 1;
			this.page_size = 15;
			this.getListApi();
		},
		jwsorts() {
			// this.ornamentsData = [];
			if (this.JWsort === 'desc') {
				//console.log(this.JWsort);
				this.JWsort = 'asc';
			} else {
				this.JWsort = 'desc';
			}
			this.JWpage = 1;
			this.JWpage_size = 15;
			this.JWtotal_rows = 0;
			this.getJewelry();
		},
		// 状态切换
		rechargeRecord() {
			this.pitchOn = 'recharge';
			this.getListApi();
		},
		ornamentRecord() {
			this.pitchOn = 'ornament';
			this.getJewelry();
		},
		// presentRecord() {
		//   this.pitchOn = 'present'
		// },
		main() {
			if (this.mainConceal) {
				this.mainConceal = false;
				this.assistantConceal = false;
			} else {
				this.mainConceal = true;
				this.assistantConceal = false;
			}
		},
		assis() {
			if (this.assistantConceal) {
				this.assistantConceal = false;
				this.mainConceal = false;
			} else {
				this.assistantConceal = true;
				this.mainConceal = false;
			}
		},
		// 收入或支出
		mainLi(index, change_type) {
			this.change_type = change_type;
			this.TS = index;
			this.TST = 0;
			this.type = '0';
			this.mainConceal = false;
			this.assistantConceal = false;
			this.getListApi();
		},
		// 其他类型
		assistantLi(index, type) {
			this.type = type;
			this.TST = index;
			this.mainConceal = false;
			this.assistantConceal = false;
			this.getListApi();
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
// 引入css类
// @import '';
.pagination {
	margin-top: 20px;
}
::v-deep .el-pagination.is-background .btn-next {
	background-color: #1ef0ed;
	height: 30px;
	width: 80px;
	border-radius: 5px;
	margin: 0 10px;
	color: #000;
	font-size: 13px;
	cursor: url('@/assets/images/commom/hand-pointer (1).png'), auto;
}
::v-deep .el-pagination.is-background .btn-next:disabled {
	color: #9e9e9e;
	cursor: not-allowed !important;
}
::v-deep .el-pagination.is-background .btn-prev:disabled {
	color: #9e9e9e;
	cursor: not-allowed !important;
}
::v-deep .el-pager .more::before {
	cursor: url('@/assets/images/commom/hand-pointer (1).png'), auto;
}
::v-deep .el-pagination.is-background .btn-prev {
	background-color: #1ef0ed;
	height: 30px;
	font-size: 13px;
	width: 80px;
	border-radius: 5px;
	margin: 0 10px;
	color: #000;
	cursor: url('@/assets/images/commom/hand-pointer (1).png'), auto;
}
.loadMore {
	margin-top: -10px;
	width: 1000px;
	.btn {
		background-color: #1ef0ed;
		height: 30px;
		width: 70px;
		border-radius: 5px;
		margin: 0 10px;
		color: #fff;
	}
	.btn_num {
		width: 30px;
		height: 30px;
		background-color: #1ef0ed;
		border-radius: 5px;
		color: #fff;
		font-weight: 700;
	}
}
.pitchOn{
	color:#000 !important;
}
.all {
	width: 130px;
	border: 2px $theme solid;
	padding: 0 10px;
	margin: 0 10px;
	line-height: 27px;
}
.MyLog {
	padding-top: 20px;
	position: relative;
	.MyLogRecord {
		display: flex;

		div {
			width: 130px;
			border: 2px $theme solid;
			padding: 0 10px;
			margin: 0 10px;
			line-height: 27px;
		}
		.pitchOn {
			color: #fff;
			background-color: $theme;
			// color: $hoverText;
		}
	}

	.rechargeRecord {
		// position: relative;
		// 选择
		.select {
			display: flex;
			margin-top: 10px;
			.main {
				.all {
					width: 130px;
					border: 2px $theme solid;
					padding: 0 10px;
					margin: 0 10px;
					line-height: 27px;
				}
				ul {
					position: absolute;
					z-index: 999;
					li {
						@extend .all;
						background-color: $hoverText;
					}
				}
			}
			.assistant {
				.all {
					width: 130px;
					border: 2px $theme solid;
					padding: 0 10px;
					margin: 0 10px;
					line-height: 27px;
				}
				ul {
					position: absolute;
					z-index: 999;
					li {
						@extend .all;
						background-color: $hoverText;
					}
				}
			}
		}
		// 表格
		.detail {
			margin: 10px;
			margin-top: 9px;
			padding-right: 9px;
			overflow-y: scroll;
			height: 510px;
			/* 滚动条整体部分 */
			&::-webkit-scrollbar {
				width: 10px;
				height: 10px;
				background-color: none;
				border-radius: 10px;
			}
			/* scroll轨道背景 */
			&::-webkit-scrollbar-track {
				// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
				border-radius: 10px;
				background-color: rgba(216, 216, 216, 0);
			}
			/* 滚动条中能上下移动的小块 */
			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				-webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
				background-color: rgb(119, 117, 117);
			}
			.formH {
				th {
					height: 50px;
					border-top: 1px #808080 solid;
				}
			}
			.formB {
				td {
					height: 40px;
					&:nth-child(5) {
						color: $theme;
					}
				}
			}
		}

		.sticky-table th {
			position: sticky;
			top: 0; /* 首行永远固定在头部  */
			//background-color: $hoverText; /*设置表头背景色*/
		}

		.sticky-table th:first-child {
			z-index: 2; /*表头的首列要在上面*/
		}
	}
	.ornament {
		.detail {
			margin: 10px;
			margin-top: 9px;
			padding-right: 9px;
			overflow-y: scroll;
			height: 550px;
			/* 滚动条整体部分 */
			&::-webkit-scrollbar {
				width: 10px;
				height: 10px;
				background-color: none;
				border-radius: 10px;
			}
			/* scroll轨道背景 */
			&::-webkit-scrollbar-track {
				// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
				border-radius: 10px;
				background-color: rgba(216, 216, 216, 0);
			}
			/* 滚动条中能上下移动的小块 */
			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				-webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
				background-color: rgb(119, 117, 117);
			}
			.formH {
				th {
					height: 50px;
					border-top: 1px #808080 solid;
				}
			}
			.formB {
				td {
					height: 40px;
					&:nth-child(5) {
						color: $theme;
					}
				}
			}
		}
		.sticky-table th {
			position: sticky;
			top: 0; /* 首行永远固定在头部  */
			//background-color: $hoverText; /*设置表头背景色*/
		}

		.sticky-table th:first-child {
			z-index: 2; /*表头的首列要在上面*/
		}
	}
	.present {
		@extend .ornament;
	}
	// 倒序
	.assistant-sort {
		width: 100%;
		margin-right: 5.75rem;
		.all {
			width: 130px;
			border: 2px $theme solid;
			padding: 0 10px;
			margin: 0 10px;
			line-height: 27px;
		}
		ul {
			position: absolute;
			z-index: 999;
			li {
				@extend .all;
				background-color: $hoverText;
			}
		}
	}
}
</style>
