<!-- BodyBackground -->
<template>
  <div class="body">
 <!-- <img
      class="body-BodyBackground"
      src="https://oss.n7buff.com/web/images/Background_starlight.png"
      alt=""
    /> -->
	<img
	  class="body-BodyBackground"
	  src="https://oss.n7buff.com/web/Background_starlight4.png"
	  alt=""
	/>
  </div>
</template>

<script>
export default {
  name: 'BodyBackground',
  data() {
    return {}
  },
  created() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.body {
  // z-index: -2;
  position: relative;
  &-BodyBackground {
    margin-top: 300px;
    width: 100%;
    position: absolute;
    top: -10%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
