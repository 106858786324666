import request from '@/api/request'
// 获取饰品分类
export const get_skin_types = (data) => {
  return request({
    url: '/api/skin_category/get_skin_types',
    method: 'POST',
    data
  })
}

// 获取饰品列表
export const get_star_shop_list = (data) => {
  return request({
    url: '/api/star_shop/get_skin_list',
    method: 'POST',
    data
  })
}

// 购买饰品
export const buy_star_skin = (data) => {
  return request({
    url: '/api/star_shop/buy_skin',
    method: 'POST',
    data
  })
}
//获取积分商城玩法介绍
export const get_play_introduction = (data) => {
  return request({
    url: '/api/star_shop/get_play_introduction',
    method: 'POST',
    data
  })
}
