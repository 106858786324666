<template>
  <el-dialog
    custom-class="inv-pop"
    :visible="dialogVisible"
  >
    <div slot="title" class="inv-title">邀请对战</div>
    <div class="inv-content">
      <div class="inv-user">
        <img src="" alt="">
        <span class="inv-user-name">王富哪个贵</span>
      </div>
      <div class="inv-info">邀请您组队 <span>欧皇对战</span></div>
      <div class="inv-bottom">
        <div class="inv-btn">拒绝</div>
        <div class="inv-btn jieshou">接受</div>
      </div>
    </div>
  </el-dialog>
</template>
<script >
export default {
  props:{
    dialogVisible:{
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep .inv-pop{
  width: 335px;
  height: 234px;
  background: url("https://oss.n7buff.com/web/images/Battle/yaoqing-bg.png") no-repeat;
  background-size: 100% 100%;
  .inv-title{
    width: 277px;
    margin: 0 auto;
    font-size: 14px;
    color: #E5F9FF;
    font-weight: bold;
    text-shadow: 0px 0px 6px #00FFF7;
    text-align: left;
    padding-left: 40px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    position: relative;
    padding-bottom: 12px;
    &:after{
      content: "";
      width: 35px;
      height: 2px;
      background: #06F7FF;
      position: absolute;
      left: 50px;
      bottom: 0;
    }
  }
  .inv-user{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    img{
      width:33px ;
      height: 33px;
      border-radius: 50%;
      overflow: hidden;
    }
    .inv-user-name{
      font-size: 14px;
      color: #FFFFFF;
      margin-left: 10px;
    }
  }
  .inv-info{
    font-size: 14px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      color: #00FFFC;
      font-size: 16px;
      margin-left: 10px;
    }
  }
  .inv-bottom{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .inv-btn{
      width:91px;
      height: 31px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      color: #FFFFFF;
      cursor: pointer;
      background: url("https://oss.n7buff.com/web/images/Battle/inv-btn.png") no-repeat;
      background-size: 100% 100%;
      &:last-of-type{
        margin-left: 32px;
      }
    }
    .jieshou{
      background: url("https://oss.n7buff.com/web/images/Battle/inv-btn-jieshou.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
