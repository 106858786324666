import request from '@/api/request'
// 开启CDK盲盒
export const open_cdk_box = (data) => {
  return request({
    url: '/api/cdk_box/open_cdk_box',
    method: 'POST',
    data
  })
}
// CDK盲盒箱内饰品
export const get_cdk_box_data = (data) => {
  return request({
    url: '/api/cdk_box/get_cdk_box_data',
    method: 'POST',
    data
  })
}
// CDK盲盒列表
export const get_cdk_box_list = (data) => {
  return request({
    url: '/api/cdk_box/get_cdk_box_list',
    method: 'POST',
    data
  })
}
//  CDK盲盒玩法介绍
export const get_play_introduction = (data) => {
  return request({
    url: '/api/cdk_box/get_play_introduction',
    method: 'POST',
    data
  })
}
