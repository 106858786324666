const VPointer = () => {
	return 'cursor: url('+ require("@/assets/images/commom/hand-pointer (1).png") + '), auto;'
}
const BodyPointer = () => {
	return 'cursor: url(' + require('@/assets/images/commom/Mouse-Pointer 2 (1).png') + '), auto;'
}
export {
	VPointer,
	BodyPointer
}
