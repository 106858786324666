<template>
  <div id="battleDetail">
    <!-- 操作及盲盒显示区域 -->
    <navigation
      :battleBoxList="battleBoxList"
      :battleBoxSumPrice="battleBoxSumPrice"
      :roomStatus="roomStatus"
      :innerStatus="innerStatus"
      :start="start"
      :battle_type="battle_type"
      @Refresh_room="Refresh_room"
      @tocopyRoom="tocopyRoom"
    ></navigation>
    <!-- 战场房间区域 -->
    <div class="rooms" v-if="battle_type != 3">
      <!-- 战场模式class  Double/Three/Four-->
      <RoomF
        :start="start"
        :battleBoxSumPrice="battleBoxSumPrice"
        :class="[
          { Double: playerList.length == 2 },
          { Three: playerList.length == 3 },
          { Four: playerList.length == 4 }
        ]"
        @moveData="moveData"
        v-for="(item, index) in playerList"
        :key="index"
        :bill_data="item"
        :roomStatus="roomStatus"
        @join_Room="Refresh_room"
        :roomCount="roomCount"
        :battleBoxList="battleBoxList"
        :Draw_prize="Draw_prize"
        @slideEnd="slideEnd"
        @complete="complete"
      ></RoomF>
    </div>
    <div class="rooms" v-if="battle_type == 3">
      <!-- 战场模式class  Double/Three/Four-->
      <div class="rooms-buld">
        <TeamUp
          :start="start"
          :ranks="'buld'"
          :battleBoxSumPrice="battleBoxSumPrice"
          @moveData="moveData"
          v-for="(item, index) in playerList.slice(0,2)"
          :key="index"
          :bill_data="item"
          :roomStatus="roomStatus"
          @join_Room="Refresh_room"
          :roomCount="roomCount"
          :battleBoxList="battleBoxList"
          :Draw_prize="Draw_prize"
          @slideEnd="slideEnd"
          @complete="complete"
        ></TeamUp>
      </div>
      <div class="rooms-red">
        <TeamUp
          :start="start"
          :ranks="'red'"
          :battleBoxSumPrice="battleBoxSumPrice"
          @moveData="moveData"
          v-for="(item, index) in playerList.slice(2,4)"
          :key="index"
          :bill_data="item"
          :roomStatus="roomStatus"
          @join_Room="Refresh_room"
          :roomCount="roomCount"
          :battleBoxList="battleBoxList"
          :Draw_prize="Draw_prize"
          @slideEnd="slideEnd"
          @complete="complete"
        ></TeamUp>
      </div>
    </div>
    <!--  在线列表  -->
    <onlineList></onlineList>
  </div>
</template>

<script>
//import { RoomMsg } from '@/network/api.js'
import navigation from '@/components/Battle/navigation.vue'
import RoomF from '@/components/Battle/room.vue'
import TeamUp from '@/components/Battle/teamUp.vue'
import onlineList from './components/onlineList.vue'
import {
  get_battle_box_data,
  get_battle_room_detail,
  create_battle_room,
  join_battle_room,
  sign_out_battle_room
} from '@/api/index/BlindBattle'
export default {
  name: 'battleDetail',
  data() {
    return {
      start: {
        manage: false,
        round: -1
      },
      handle: [], //所有子组件动画前置处理完毕
      moveDataS: [], //所有子组件过渡类名
      endSlide: [], //所有子组件过渡结束
      audio: new Audio('https://oss.n7buff.com/web/audio/battle.mp3'), //过渡声音

      /*数据相关*/
      battleBoxList: [], //盲盒列表
      battleBoxSumPrice: 0, //房间总价
      roomStatus: 0, //房间状态
      innerStatus: false, //是否已加入
      mode: 2, //几人战场
      battle_type: 1, // 1欧皇 2非酋 3组队
      playerList: [], //人员列表
      timer: null, //定时器
      roomCount: -1, //轮数
      Draw_prize: false //是否开奖
    }
  },
  components: {
    navigation,
    RoomF,
    TeamUp,
    onlineList
  },
  created() {
    //console.log(this.roomStatus);
    this.getRoomMsg()
  },

  mounted() {
    document.addEventListener('visibilitychange', this.handleVisiable)
  },
  methods: {
    //房间详情
    getRoomMsg() {
      get_battle_room_detail({ battle_room_id: this.$route.query.id })
        .then((res) => {
          if (res.status == 1) {
            this.battleBoxList = res.data.battle_box_list
            this.battleBoxSumPrice = res.data.battle_box_sum_price
            this.roomStatus = res.data.room_status
            this.innerStatus = res.data.inner_status
            res.data.player_list.forEach((item,index) => {
              item.index = index
            })

            this.playerList = res.data.player_list
            // console.log(this.playerList)
            this.$forceUpdate()
            this.mode = res.data.mode
            this.battle_type = res.data.battle_type
            if (res.data.player_list[0].skin_info == null) {
              if (this.timer == null) {
                this.setRoomMsg()
              }
            } else {
              if (this.roomStatus == 2) {
                let change_player_list = this.playerList
                for (
                  let box_i = 0;
                  box_i < this.battleBoxList.length;
                  box_i++
                ) {
                  let check_data = []
                  for (let i = 0; i < change_player_list.length; i++) {
                    // 随机从1到10中取一个整数
                    check_data[i] = Math.floor(Math.random() * (9 - 1)) + 1
                  }
                  check_data[
                    Math.floor(Math.random() * change_player_list.length)
                  ] = 9
                  for (let i = 0; i < change_player_list.length; i++) {
                    let change_player_detail = change_player_list[i]
                    if (change_player_detail['check_data']) {
                      change_player_detail['check_data'].push(check_data[i])
                    } else {
                      change_player_detail['check_data'] = []
                      change_player_detail['check_data'][box_i] = check_data[i]
                    }
                    change_player_list[i]['check_data'] =
                      change_player_detail['check_data']
                  }
                }
                this.playerList = change_player_list
                if (!this.start.manage) {
                  clearInterval(this.timer)
                  this.timer = null
                  this.roomCount = res.data.step
                } else {
                  this.roomCount = res.data.step
                }
              } else {
                this.start.round = res.data.battle_box_list.length - 1
              }
            }
          } else {
            this.$router.push('/BlindBoxFight')
            //this.ToolTips('房间已过期')
            this.$message.error('房间已过期')
          }
        })
        .catch(() => {
          this.$message.error('系统出错')
        })
    },

    //定时器
    setRoomMsg() {
      this.timer = setInterval(() => {
        get_battle_room_detail({ battle_room_id: this.$route.query.id }).then(
          (res) => {
            this.battleBoxList = res.data.battle_box_list
            this.battleBoxSumPrice = res.data.battle_box_sum_price
            this.roomStatus = res.data.room_status
            this.innerStatus = res.data.inner_status
            res.data.player_list.forEach((item,index) => {
              item.index = index
            })
            this.playerList = res.data.player_list
            this.$forceUpdate()
            if (res.data.player_list[0].skin_info != null) {
              if (this.roomStatus == 2) {
                let change_player_list = this.playerList
                for (
                  let box_i = 0;
                  box_i < this.battleBoxList.length;
                  box_i++
                ) {
                  let check_data = []
                  for (let i = 0; i < change_player_list.length; i++) {
                    // 随机从1到10中取一个整数
                    check_data[i] = Math.floor(Math.random() * (9 - 1)) + 1
                  }
                  check_data[
                    Math.floor(Math.random() * change_player_list.length)
                  ] = 9
                  for (let i = 0; i < change_player_list.length; i++) {
                    let change_player_detail = change_player_list[i]
                    if (change_player_detail['check_data']) {
                      change_player_detail['check_data'].push(check_data[i])
                    } else {
                      change_player_detail['check_data'] = []
                      change_player_detail['check_data'][box_i] = check_data[i]
                    }
                    change_player_list[i]['check_data'] =
                      change_player_detail['check_data']
                  }
                }
                this.playerList = change_player_list
              }
              clearInterval(this.timer)
              this.timer = null
              this.roomCount = res.data.step
            }
          }
        )
      }, 3000)
    },

    //窗口激活(浏览器单线程)
    handleVisiable(e) {
      if (e.target.visibilityState === 'visible') {
        clearInterval(this.timer)
        this.timer = null
        Object.assign(this.$data, this.$options.data())
        this.getRoomMsg()
      }
    },
    //刷新战场(子组件加入，退出所用)
    Refresh_room() {
      this.getRoomMsg()
      //获取个人数据
      //this.GetPersonalData()
    },

    //全部动画组件处理完毕(开始动画)
    complete() {
      this.handle.push('start')

      if (this.handle.length == this.mode) {
        setTimeout(() => {
          this.tostart()
        }, 500)
      }
    },

    //接收所有子组件本次过渡时间
    moveData(res) {
      this.moveDataS.push(res)
      if (this.moveDataS.length == this.mode) {
        this.audio.play()
      }
    },

    //接受所有子组件动画结束后
    slideEnd() {
      this.endSlide.push('end')
      if (this.endSlide.length == this.mode) {
        // 全部结束后 => 下一轮/展示结果
        if (this.roomCount + 1 < this.battleBoxList.length) {
          this.handle = []
          this.moveDataS = []
          this.endSlide = []
          setTimeout(() => {
            this.roomCount += 1
          }, 500)
        } else {
          setTimeout(() => {
            this.Draw_prize = true
            //获取个人数据
            //this.GetPersonalData()
          }, 1000)
        }
      }
    },

    //开始战场
    tostart() {
      this.start.manage = true
      this.start.round = this.roomCount
    },

    //创建相同的战场
    tocopyRoom(e) {
      clearInterval(this.timer)
      this.timer = null
      Object.assign(this.$data, this.$options.data())
      // setTimeout(() => {
        // this.$router.replace('/battleDetail?id=' + e)
        this.getRoomMsg()
      // }, 500)
    }
  },

  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.handleVisiable)
    clearInterval(this.timer)
    this.timer = null
  }
}
</script>
<style lang="scss">
#battleDetail {
  width: 1400px;
  margin: 50px auto 40px;
  padding: 20px 30px 24px;
  background: rgba($color: #000000, $alpha: 0.2);
  overflow: hidden;
  color: #fff;
  box-sizing: border-box;
  .rooms {
    margin-top: 25px;
    display: flex;
    justify-content: center;

    #room {
      &.Double {
        margin: 0 80px;
      }

      &.Three {
        margin: 0 56px;
      }

      &.Four {
        margin: 0 11px;
      }
    }
  }
}
.rooms-red,.rooms-buld{
  display: flex;
}
.rooms-red{
  margin-left: 20px;
  #room{
    margin-right: 5px !important;
    .room_container .room_data{
      //border-bottom: 2px solid red;
      .user_data{
        border-top: 2px solid red;
      }
    }
    .conduct{
      background: url("@/assets/images/fightImages/bottom-right.png") no-repeat center 140px !important;
      background-size: contain !important;
    }
    .headPortrait-round.active{
      border-color: red !important;
    }
  }
}
.rooms-buld{
  #room{
    margin-right: 5px !important;

  }
}
// @import "@/assets/media/media.scss";
</style>
