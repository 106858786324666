<!-- 开箱弹出动画 -->
<template>
  <div
    class="BoxShowPopup"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
  >
  <!-- 标题盒子 -->
    <div class="oneOn">
      <!-- <img src="@/assets/images/BoxShowPopup/一发入魂.png" alt="" /> -->
			<p class="get" >获得奖励</p>
    </div>
    <!-- 关闭按钮 -->
    <div
      class="close"
      @click="closes"
      :style="VPointer()"
    >
      <img src="@/assets/images/BoxShowPopup/close.png" alt="" />
    </div>

		<!-- 中奖结果 -->
    <div :class="resultList.length > 4 ? 'winningResults1' : 'winningResults'" >
      <div class="goods">
        <ul>
          <li v-for="(item, index) in resultList" :key="index">
            <div
              class="item"
              :style="'background-image: url(' + item.rarity_thumb + ');'"
            >
              <div class="main">
                <img :src="item.image_url" alt="" />
              </div>
              <div class="name" :title="item.item_name">
                {{ item.item_name }}
              </div>
              <div class="price">
                <img src="../../assets/images/n7_money.png" alt="" />
                <div>{{ item.price }}</div>
              </div>
            </div>
            <div class="take">
              <div class="putIn" :style="VPointer()" @click="toPack(index)">
                放入背包
              </div>
              <div
                class="recycle"
                :style="VPointer()"
                @click="recoverySkinsApi(item.player_skins_id, index)"
              >
                回收 {{ item.price }}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="all">
        <div class="allPutIn" :style="VPointer()" @click="closes">
          全部放入背包
        </div>
        <div
          class="allRecycle"
          :style="VPointer()"
          @click="recoverySkinsAllApi"
        >
          一键回收 {{ priceAllOn }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get_box_data } from '@/api/index/boxCorrelation'
import { recovery_skins, get_token } from '@/api/index/personalCenter'
export default {
  name: 'BoxShowPopup',
  data() {
    return {
      Height: 133,
      translateY: 0, //偏移值
      results: false, // 中奖结果
      soundStyleArr: [
        'animation_box soundone',
        'animation_box soundtwo',
        'animation_box soundthree',
        'animation_box soundfour',
        'animation_box soundfive',
        'animation_box soundsix',
        'animation_box soundseven',
        'animation_box soundeight',
        'animation_box soundnine',
        'animation_box soundten'
      ],
      userList: [],
      closesTime: 0,
      winningTimer: 'winningTimer',
      recoverySkins: [], // 抽中物品
      get_token: '', // 令牌
      priceAll: 0, // 总价格
      priceAllOn: 0 // 总价格四舍五入后
    }
  },
  props: {
    visible: Boolean,
    count: Number,
    resultList: Array, //结果
    show_prize: true // 动画开关
  },
  watch: {
    async visible(val) {
	  //console.log("resultList+++++++",this.resultList);
      await this.priceAlls()
    },
    showAnimations(val) {
      if (val) {
       // window.location.reload()
      }
    },
    count(){
      /* console.log(this.count);
      this.getBoxDataApi() */
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 全部价格
    priceAlls() {
  		this.priceAll = 0
      for (let i = 0; i < this.resultList.length; i++) {
        this.priceAll += Number(this.resultList[i].price)
      }
	  //console.log("this.priceAll.toFixed(2)",this.priceAll)
      let priceAllOn = this.priceAll.toFixed(2)
      this.priceAllOn = priceAllOn
    },
    // 单个放入背包
    toPack(index) {
      if (this.resultList.length > 1) {
        // eslint-disable-next-line vue/no-mutating-props
        this.resultList.splice(index, 1)
        this.recoverySkins.splice(index, 1)
        this.priceAlls()
      } else {
        this.closes()
      }
    },
    // 单个回收
    async recoverySkinsApi(id, index) {
      const token = await get_token()
      this.get_token = token.data.toekn
      const res = await recovery_skins({
        player_skins_ids: Array(id),
        token: this.get_token
      })
      if (res.status == 1) {
        this.$message({
          message: res.msg,
          type: 'success'
        })

        if (this.resultList.length > 1) {
          // eslint-disable-next-line vue/no-mutating-props
          this.resultList.splice(index, 1)
          this.recoverySkins.splice(index, 1)
          this.$store.dispatch('getPlayerApi')
          this.priceAlls()
        } else {
          this.$store.dispatch('getPlayerApi')
          this.closes()
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    // 全部回收
    async recoverySkinsAllApi() {

      const token = await get_token()
      this.get_token = token.data.toekn
      const res = await recovery_skins({
        player_skins_ids: this.resultList.map(e=>e.player_skins_id),
        token: this.get_token
      })
      if (res.status === 1) {
        this.$message({
          message: res.msg,
          type: 'success'
        })
        this.$store.dispatch('getPlayerApi')
        this.closes()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 弹窗关闭
    closes() {
      this.closesTime = 0
      this.translateY = 0
      this.results = false
      this.priceAll = 0
      this.recoverySkins = []
      clearTimeout(this.winningTimer)
      this.$emit('update:visible', false)
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = '' //出现滚动条
      document.removeEventListener('touchmove', mo, false)
    },
    // 中奖结果
    winningResults() {
      this.results = true
    }
  }
}
</script>
<style lang="scss" scoped>
// 引入css类
@import '@/styles/variables.scss';
.BoxShowPopup {
  background: url('https://oss.n7buff.com/web/images/BoxShowPopup/background.png') no-repeat;
  background-size: 1200px;
  width: 1200px;
  height: 100%;
  position: relative;
  .oneOn {
    margin-top: 0;
	.get {
	  font-size: 0.3rem;
	  font-weight: 700;
	  letter-spacing:0.1rem
	}
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
  }
  // 单个
  .single-box {
    .single {
      position: relative;
      .centre {
        // background-color: red;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 40px;
        margin-top: 10%;
        .left {
          margin-top: 50px;
          img {
            width: 250px;
          }
        }
        .animation {
          //  background-color: red;
          // position: relative;
          .animationDiv {
            // background-color: pink;
            height: 240px;
          }
          .animationImg {
            width: 350px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 121;
          }
          .frame {
            position: absolute;
            top: 50%;
            left: 49.7%;
            transform: translate(-50%, -50%);
            z-index: 120;
            width: 220px;
            height: 200px;
            overflow: hidden;
            .animation_out {
              // background-color: red;
              width: 244px;
              height: 137px;
              transform: translateX(-2px);
              display: flex;
              .animation_box {
                width: 100%;
                height: 100%;
                flex: 1;
                transition: 6s cubic-bezier(0.2, 0.29, 0.15, 0.91);

                &.soundone {
                  transition: 9s cubic-bezier(0.2, 0.29, 0.15, 0.91);
                }

                &.soundtwo {
                  transition: 9s cubic-bezier(0.2, 0.3, 0.14, 0.92);
                }

                &.soundthree {
                  transition: 9s cubic-bezier(0.2, 0.31, 0.15, 1);
                }

                &.soundfour {
                  transition: 9s cubic-bezier(0.2, 0.32, 0.14, 0.89);
                }

                &.soundfive {
                  transition: 9s cubic-bezier(0.2, 0.33, 0.15, 0.9);
                }

                &.soundsix {
                  transition: 9s cubic-bezier(0.2, 0.32, 0.14, 0.98);
                }

                &.soundseven {
                  transition: 9s cubic-bezier(0.2, 0.31, 0.15, 0.95);
                }

                &.soundeight {
                  transition: 9s cubic-bezier(0.2, 0.3, 0.14, 0.96);
                }

                &.soundnine {
                  transition: 9s cubic-bezier(0.2, 0.29, 0.14, 0.92);
                }

                &.soundten {
                  transition: 9s cubic-bezier(0.2, 0.3, 0.15, 0.97);
                }

                // &.active {
                //   // transition: none !important;
                // }
                .animation_itemBox {
                  position: absolute;
                  top: 70%;
                  left: 46%;
                  transform: translate(-50%, -50%);
                  // overflow: hidden;
                  width: 220px;
                  height: 200px;
                  .animation_item {
                    .back {
                      position: relative;
                      height: 137px;
                      img {
                        width: 220px;
                        height: 135px;
                      }
                      .mainBody {
                        width: 160px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .right {
          @extend .left;
        }
      }
      .light {
        position: absolute;
        top: 90%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        img {
          width: 400px;
        }
      }
    }
  }
  // 多个
  .multi-box {
    display: flex;
    justify-content: space-evenly;
    .single {
      margin-top: 10%;
      .centre {
        .animation {
          .animationImg {
            width: 218px;
          }
          .frame {
            height: 160px;
            .animation_out {
              height: 102px;
              .animation_box {
                .animation_itemBox {
                  top: 98%;
                  .animation_item {
                    .back {
                      position: relative;
                      height: 102px;
                      img {
                        width: 150px;
                        height: 97px;
                      }
                      .mainBody {
                        width: 120px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .light {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        img {
          width: 200px;
        }
      }
    }
  }
  // 中奖结果
  .winningResults {
    .goods {
      ul {
        margin-top: 150px;
        padding: 20px 70px;
        display: flex;
        justify-content: space-evenly;
        li {
          width: 240px;
          height: 153px;
        }
        width: 100%;
        .item {
          // background: url('../../../assets/images/边框青.png') no-repeat;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 100%;
          height: 100%;
          background-size: 240px 153px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          .main {
            img {
              width: 120px;
              //margin-top: 13px;
            }
          }

          .name {
            font-size: 13px;
            width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 2px;
            position: absolute;
            bottom: 30px;
            z-index: 3;
          }
          .price {
            font-size: 13px;
            display: flex;
            margin-top: 3px;
            position: absolute;
            bottom: 12px;
            img {
              width: 15px;
              height: 15px;
              margin-right: 10px;
            }
          }
        }
        // .item > div {
        //   // margin: ;
        // }

        .take {
          display: flex;
          justify-content: center;
          .putIn {
            font-size: 14px;
            border: 2px $theme solid;
            border-radius: 5px;
            padding: 2px 10px;
            margin: 0 5px;
            &:hover {
              background-color: $theme;
              color: $hoverText;
            }
          }
          .recycle {
            @extend .putIn;
          }
        }
      }
    }
    .all {
      display: flex;
      justify-content: center;
      margin-top: 50px;
      .allPutIn {
        border: 2px $theme solid;
        border-radius: 5px;
        padding: 10px 40px;
        &:hover {
          background-color: $theme;
          color: $hoverText;
        }
      }
      .allRecycle {
        @extend .allPutIn;
        margin-left: 200px;
      }
    }
  }
  .winningResults1 {
    .goods {
      ul {
        width: 100%;
        margin-top: 20px;
        padding: 20px 70px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        li {
          width: 260px;
          height: 153px;
          margin: 0 20px;
          margin-top: 40px;
        }
        .item {
          // background: url('../../../assets/images/边框青.png') no-repeat;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 100%;
          height: 100%;
          background-size: 240px 153px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          .main {
            img {
              width: 120px;
              //margin-top: 13px;
            }
          }

          .name {
            font-size: 13px;
            width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 2px;
            position: absolute;
            bottom: 30px;
            z-index: 3;
          }
          .price {
            font-size: 13px;
            display: flex;
            margin-top: 3px;
            position: absolute;
            bottom: 12px;
            img {
              width: 15px;
              height: 15px;
              margin-right: 10px;
            }
          }
        }
        // .item > div {
        //   // margin: ;
        // }

        .take {
          display: flex;
          justify-content: center;
          .putIn {
            font-size: 14px;
            border: 2px $theme solid;
            border-radius: 5px;
            padding: 2px 10px;
            margin: 0 5px;
            &:hover {
              background-color: $theme;
              color: $hoverText;
            }
          }
          .recycle {
            @extend .putIn;
          }
        }
      }
    }
    .all {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      .allPutIn {
        border: 2px $theme solid;
        border-radius: 5px;
        padding: 10px 40px;
        &:hover {
          background-color: $theme;
          color: $hoverText;
        }
      }
      .allRecycle {
        @extend .allPutIn;
        margin-left: 200px;
      }
    }
  }
}
</style>
