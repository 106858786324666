import Vue from 'vue'
import store from '@/store' // 引入vuex store实例
import VueRouter from 'vue-router'
import HomeView from '../views/home/index.vue'
import BoxShow from '@/views/boxShow/index.vue'
// import numberRolling from '@/components/DrawThem.vue'
import BlindBoxFight from '@/views/BlindBoxFight/index.vue'
import FreeSkin from '@/views/FreeSkin/index.vue'
// import LuckyOrnament from '@/views/LuckyOrnament/index.vue'
import OrnamentStore from '@/views/OrnamentStore/index.vue'
import MyIntegralLog from '@/views/OrnamentStore/components/MyIntegralLog.vue'
import MyStarLog from '@/views/StarStore/components/MyIntegralLog.vue'
import StarStore from '@/views/StarStore/index.vue'
import GoRoom from '@/components/FreeSkin/GoRoom.vue'
// import toPlay from '@/views/BlindBoxFight//components/BeganToPlay.vue'
import battleDetail from '@/views/Battle/battleDetail.vue'
import OrnamentOpen from '@/views/LuckyOrnament/components/OrnamentOpen.vue'
import personalCenter from '@/views/personalCenter/index.vue'
import Helpcenter from '@/views/HelpCenter/index.vue'
import Download from '@/views/download.vue'
import GradeBox from '@/views/Grade/index.vue'
import CdkBox from '@/views/cdk/index.vue'
import Card from '@/views/Card/home.vue'
import CardList from '@/views/Card/cardList.vue'
Vue.use(VueRouter)
const routes = [
	// 首页
	{
		path: '/',
		name: 'home',
		component: HomeView
	},
	// 首页抽奖
	{
		path: '/BoxShow',
		name: 'BoxShow',
		component: BoxShow,
		children: []
	},
	// ROLL房
	{
		path: '/FreeSkin',
		name: 'FreeSkin',
		component: FreeSkin,
		children: []
	},
	// ROLL房 进入房间
	{
		path: '/GoRoom',
		name: 'GoRoom',
		component: GoRoom
	},
	// 盲盒对战
	{
		path: '/BlindBoxFight',
		name: 'BlindBoxFight',
		component: BlindBoxFight,
		children: [
			// {
			//   path:'toPlay',
			//   name:'BlindBoxFight',
			//   component:battleDetail
			// }
		]
	},
	// 我的积分日志
	{
		path: '/MyIntegralLog',
		name: 'MyIntegralLog',
		component: MyIntegralLog
	},
	// 我的星空日志
	{
		path: '/MyStarLog',
		name: 'MyStarLog',
		component: MyStarLog
	},
	// 盲盒对战抽奖
	{
		path: '/battleDetail',
		name: 'battleDetail',
		component: battleDetail,
	},
	// 幸运饰品
	// {
	// 	path: '/LuckyOrnament',
	// 	name: 'LuckyOrnament',
	// 	component: LuckyOrnament,
	// },
	// 幸运饰品抽奖
	{
		path: '/OrnamentOpen',
		name: 'OrnamentOpen',
		component: OrnamentOpen
	},
	// 饰品商城
	{
		path: '/OrnamentStore',
		name: 'OrnamentStore',
		component: OrnamentStore
	},
	// 星空商城
	{
		path: '/StarStore',
		name: 'StarStore',
		component: StarStore
	},
	// 个人中心
	{
		path: '/personalCenter',
		name: 'personalCenter',
		component: personalCenter
	},
	{
		path: '/Helpcenter',
		name: 'Helpcenter',
		component: Helpcenter
	},
	{
		path: '/Download',
		name: 'Download',
		component: Download
	},
  {
		path: '/GradeBox', // 等级箱子
		name: 'GradeBox',
		component: GradeBox
	},
  {
    path: '/CdkBox', // 等级箱子
    name: 'CdkBox',
    component: CdkBox
  },
  // {
  //   path: '/Card', // 卡牌玩法
  //   name: 'Card',
  //   component: Card
  // },
  // {
  //   path: '/CardList', // 卡牌玩法
  //   name: 'CardList',
  //   component: CardList
  // },

]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
	routes,
})


// 白名单
//const whiteList = ['/', '/FreeSkin','/BoxShow', '/OrnamentOpen', '/Helpcenter','/BlindBoxFight','/LuckyOrnament','/OrnamentStore','/404']
const whiteList = ['/', '/FreeSkin', '/BoxShow', '/OrnamentOpen', '/Helpcenter', '/LuckyOrnament', '/OrnamentStore',
	'/Download',
  '/GradeBox',
  '/CdkBox',
	'/404'
]
// 路由前置守卫
// to:要进入的路由
// from:从回那个路由来
// next:是否允许进入
router.beforeEach((to, from, next) => {
	// 判断用户是否登录
	if (store.state.token) {
		next()
	} else {
		// 判断即将进入的页面是否在白名单里
		if (whiteList.includes(to.path)) {
			return next()
		} else {
			// 没登录，也没再白名单里
			store.dispatch('setLogSignRetrieve', true)
		}
	}
})
export default router
