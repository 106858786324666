<!-- HomeBodyTitleOne -->
<template>
	<div class="HomeBodyTitle">
		<div class="HomeBodyTitle-list" v-for="item in boxList" :key="item.id">
			<div class="one-title">
				<img src="@/assets/images/title.png" alt="" />
				<i class="title-text">{{ item.box_category_name }}</i>
			</div>
			<div class="one-part">
				<ul>
					<li v-for="(items, index) in item.box_list" :key="items.id" @click="boxShow(items.id)" @mouseenter="mouse()">
						<div class="kuang" :style="VPointer()" @mouseover="over(index)" @mouseout="out()">
							<img class="kuang-img" :src="items.img_main" alt="" />
							<img class="kuang-box" :src="items.img_active" alt="" />
							<img class="kuang-zu" src="@/assets/images/skin-border.png" alt="" />
							<!-- <div class="kuang-arrows">
                <img src="@/assets/images/箭头.png" alt="" />
                <img src="@/assets/images/箭头.png" alt="" />
                <img src="@/assets/images/箭头.png" alt="" />
                <img src="@/assets/images/箭头.png" alt="" />
              </div>
              <img class="kuang-sight" src="@/assets/images/准星.png" alt="" /> -->
						</div>
						<div class="k5">
							<img src="@/assets/images/n7_money.png" alt="" />
							<div class="price">{{ items.price }}</div>
						</div>
						<div class="name-but" :style="VPointer()">
							<!-- <img src="@/assets/images/Unchecked.png" alt="" /> -->
							<div class="name-but-fon" :style="{ color: inx == index ? '#ffffff' : '' }">{{ items.name }}</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { get_box_list } from '@/api/index/home';
export default {
	name: 'HomeBodyTitleOne',
	data() {
		return {
			butImg: '@/assets/images/Unchecked.png',
			butName: [
				{
					unImg: '@/assets/images/Check.png',
					onImg: '@/assets/images/Unchecked.png'
				},
				{
					unImg: '@/assets/images/Check.png',
					onImg: '@/assets/images/Unchecked.png'
				},
				{
					unImg: '@/assets/images/Check.png',
					onImg: '@/assets/images/Unchecked.png'
				}
			],
			audio: new Audio('https://oss.n7buff.com/web/audio/dui.mp3'),
			boxList: [], //箱子列表
			inx: null
		};
	},
	created() {
		this.getBoxListApi();
	},
	methods: {
		// 首页箱子列表
		getBoxListApi() {
			get_box_list().then(res => {
				this.boxList = res.data;
			});
		},
		// 进入开箱
		boxShow(id) {
			this.$router.push({ path: '/BoxShow', query: { id: id } });
			//this.audio.pause()
		},

		mouse() {
			// console.log(1);
			// console.log(this.$route.name);
			if (this.$store.state.Audio && this.$route.name == 'home') {
				//this.audio.play()
				this.audio.play().catch(() => {});
			}
		},
		over(index) {
			this.inx = index;
		},
		out() {
			this.inx = null;
		}
		//
		// mouseleave() {
		// 	//this.audio.pause()
		// }
	}
};
</script>
<style lang="scss" scoped>
@import '@/styles/HomeBodyTitle.scss';
</style>
