<template>
  <div>
    <div class="bar">
      <ul>
        <li :style="VPointer()" @click="hidden">
          <img src="https://oss.n7buff.com/web/images/265.gif" alt=""/>
        </li>
        <li
          v-for="(item, index) in list"
          class="libg"
          :key="index"
          @click="$router.push(item.url)"
          :style="VPointer()"
        >
          <!-- trigger="hover" -->
          <div v-if="item.name == '群聊'">
            <el-tooltip
              placement="left"
              :visible-arrow="false"
              effect="light"
            >
              <div slot="content">
                <img :src="qq_group_chat" class="hoverimg" alt=""/>
              </div>
              <div>
                <div class="imgsbox">
                  <img :src="item.img" class="imgs"/>
                </div>
                <div class="anamebox">
                  <div class="aname">{{ item.name }}</div>
                </div>
              </div>
            </el-tooltip>
          </div>
          <div v-else-if="item.name == '客服'">
            <el-tooltip
              placement="left"
              :visible-arrow="false"
              popper-class="tool"
              effect="light"

            >
              <div slot="content">
                <div
                  v-for="(item, index) in qq_service"
                  :key="index"
                  class="hoverimgbox"
                >
                  <img :src="item.qrcode" alt=""/>
                </div>
              </div>
              <div>
                <div class="imgsbox">
                  <img :src="item.img" class="imgs"/>
                </div>
                <div class="anamebox">
                  <div class="aname">{{ item.name }}</div>
                </div>
              </div>
            </el-tooltip>
          </div>
          <div v-else>
            <div class="imgsbox">
              <img :src="item.img" class="imgs"/>
            </div>
            <div class="anamebox">
              <div class="aname">{{ item.name }}</div>
            </div>
          </div>
        </li>
        <!-- <li
          :style="VPointer()"
          @click="$router.push('/personalCenter?name=personalCenter')"
        >
          <img src="@/assets/function_icon/my.png" alt="" />
        </li>
        <li
          :style="VPointer()"
          @click="$router.push('/personalCenter?name=MyPromotion')"
        >
          <img src="@/assets/function_icon/推广管理.png" alt="" />
        </li>
         <li
          :style="VPointer()"
          @click="$router.push('/personalCenter?name=MyLog')"
        >
          <img src="@/assets/function_icon/journal.png" alt="" />
        </li>
        <li
          :style="VPointer()"
          @click="$router.push('/personalCenter?name=MyBackpack')"
        >
          <img src="@/assets/function_icon/knapsack.png" alt="" />
        </li>
        <li :style="VPointer()">
          <img src="@/assets/function_icon/客服.png" alt="" />
        </li>  -->
      </ul>
    </div>
    <!-- v-if="flag" -->
    <div class="red_packets_box" v-if="flag">
      <div class="red_packets animate__jackInTheBox animate__animated">
        <span class="hidden" @click="hidde_tow" :style="VPointer()">X</span>
        <div class="title">福利中心</div>
        <div class="welfare-center">
          <div class="left hnks">
            <ul>
              <li :style="VPointer()" @click="num = 1" :class="num == 1 ? 'bgc' : ''">红包福利</li>
              <li :style="VPointer()" @click="num = 2" :class="num == 2 ? 'bgc' : ''">活动专区</li>
              <li :style="VPointer()" @click="num = 3" :class="num == 3 ? 'bgc' : ''">拼手气</li>
            </ul>
          </div>
          <div class="right hnkss">
            <div class="fl-inputBox">
              <input type="text" placeholder="请输入红包口令" v-model="password"/>
              <button @click="click" :style="VPointer()">领取</button>
            </div>
            <div class="red_one1" v-if="num == 1">
              <div class="red_content" v-for="(item, index) in red_code_data" :key="index">
                <span>{{ item.player_nike_name }}</span> 获得了 <span>{{ item.price }}</span> 红包
              </div>
            </div>
            <div class="red_one" v-if="num == 2">
              <div class="red_content">暂无活动红包</div>
            </div>
            <div class="red_one" v-if="num == 3">
              <div class="red_content">暂无手气红包</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {get_red, get_contact_us} from '@/api/index/personalCenter'
import {get_receiving_records} from '@/api/index/TopUp'

export default {
  data() {
    return {
      flag: false,
      num: 1,
      password: '',
      visible: true,
      red_code_data: [],
      qq_service: [],
      qq_group_chat: '',
      list: [
        {
          name: '个人',
          img: require('@/assets/images/geren.png'),
          url: '/personalCenter?name=personalCenter'
        },
        {
          name: '推广',
          img: require('@/assets/images/laba.png'),
          url: '/personalCenter?name=MyPromotion'
        },
        {
          name: '日志',
          img: require('@/assets/images/rizhi.png'),
          url: '/personalCenter?name=MyLog'
        },
        {
          name: '背包',
          img: require('@/assets/images/beibao.png'),
          url: '/personalCenter?name=MyBackpack'
        },
        {
          name: '群聊',
          img: require('@/assets/images/kefu.png')
        },
        {
          name: '客服',
          img: require('@/assets/images/kefu.png')
        }
      ]
    }
  },
  mounted() {
    get_contact_us().then((res) => {
      //console.log(res)
      this.qq_group_chat = res.data.qq_group_chat
      this.qq_service = res.data.qq_service
    })
  },
  methods: {
    hidden() {
      this.flag = true
      this.get_receiving_record()
    },
    hidde_tow() {
      this.password = ''
      this.flag = false
    },
    click() {
      // 判断用户是否登录
      if (!sessionStorage.getItem('n7_token')) {
        this.hidde_tow()
        this.$store.dispatch('setLogSignRetrieve', true)
        return
      }
      get_red({password: this.password}).then((res) => {
        //console.log(res)
        if (res.status === 1) {
          this.$store.dispatch('getPlayerApi')
          this.get_receiving_record()
          this.$message({
            message: res.msg,
            type: 'success'
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    get_receiving_record() {
      get_receiving_records({}).then((res) => {
        //console.log(res)
        if (res.status === 1) {
          this.red_code_data = res.data
        } else {
          //this.$message.error(res.msg)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.hnks {
  padding-top: 30px;
  padding-left: 20px;
}

.hnkss {
  padding-top: 50px;
}

.hoverimgbox {
  margin-bottom: 20px;
  background: #fff;
  padding: 10px;

  img {
    width: 100px;
    height: 100px;
  }
}

.hoverimgbox:last-child {
  border-bottom: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;

}

.hoverimg {
  width: 150px;
  height: 150px;
}

.red_one1 {
  margin-top: 30px;
  color: #FFFFFF;
  text-align: left;
  font-size: 24px;
}

.red_one {
  font-size: 28px;
  font-weight: 700;
  margin-top: 30px;
  color: #FFFFFF;
}

.bar {
  position: fixed;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 10px;
  height: 300px;
  z-index: 1000;

  ul {
    li {
      width: 40px;
      margin-bottom: 10px;

      img {
        width: 100%;
      }
    }

    .aname {
      transform: scale(0.8);
    }

    .imgsbox {
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      display: inline-block;
    }

    .imgs {
      transform: scale(0.7);
      width: 30px;
      height: 30px;
    }

    .anamebox {
      font-size: 12px;
      position: absolute;
      bottom: 9px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
    }

    .libg {
      width: 50px;
      height: 60px;
      margin-bottom: 10px;
      background-image: url('@/assets/images/libg.png');
      background-size: 100% 100%;
      position: relative;
    }
  }
}

.red_packets_box {
  position: fixed;
  top: 0;
  margin: auto;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.72);
  // background-color: rgba(0, 0, 0, 1);
  z-index: 1001;
  transition: all 0.5s;

  .red_packets {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 1029px;
    height: 754px;
    // background-image: url('@/assets/img/hongbao/红包框.png');
    padding: 0 68px 75px;
    background: url('@/assets/images/fl.png') no-repeat;
    background-size: 100% 100%;

    .hidden {
      position: absolute;
      top: 60px;
      right: 60px;
      font-size: 20px;
      background-color: #1fc5cb;
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      line-height: 40px;
    }
    .welfare-center{
      display: flex;
    }
  }

  .title {
    font-size: 42px;
    font-weight: 700;
    margin-top: 44px;
    color: #00FFF0;
    line-height: 1;
  }

  .left {
    display: inline-block;
    vertical-align: top;
    // background-color: red;
    width: 288px;
    // border-right: 2px solid rgb(0, 183, 255);
    //height: 350px;
    overflow: hidden;

    ul {
      li {
        //border-radius: 5px;
        //border: 1px solid rgb(0, 183, 255);
        //width: 120px;
        //margin: 20px auto auto;
        //padding: 5px 0;
        width: 135px;
        height: 135px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("@/assets/images/fl-li.png") ;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        font-size:19px ;
        color: #24214F;
        margin-top: 20px;
        margin-left: 23px;
        transition: all 0.3s;
        &:first-of-type{
          margin-top: 40px;
        }
        &:nth-child(1){
          margin-left: 90px;
        }
        &:nth-child(3){
          margin-left: 90px;
        }
      }
    }
  }

  .bgc {
    width: 178px !important;
    height: 178px !important;
    color: #16E1FF !important;
    font-size: 24px !important;
    background-image: url("@/assets/images/fl-li-active.png") !important;
    //background-color: rgb(0, 183, 255);
  }

  .right {
    width: 476px;
    margin-left: 60px;
    margin-top: 40px;
    .fl-inputBox{
      width: 100%;
      height: 56px;
      background: url("@/assets/images/fl-input.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      input {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        height: 45px;
        outline: none;
        border: none;
        padding-left: 20px;
        box-sizing: border-box;
        font-size: 24px;
        background: transparent;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 144px;
        height: 45px;
        border: none;
        color: #fff;
        font-size: 28px;
        background: transparent;
      }
    }

  }
}
</style>
