<!-- 抽奖动画 -->
<template>
  <!-- 父盒子固定宽度，overflow: hidden用于滚动 -->
  <div class="competition-dynamics-warp-box" ref="wrapper">
    <!--滚动区域的盒子，里面放需要滚动的内容，注意再复制一份用于实现无缝对接 -->
    <div
      class="marquee-box"
      ref="marquee"
      @mouseover="mouseover"
      @mouseout="mouseout"
    >
      <!-- 滚动内容 -->
      <!-- <div class="sss">-->
      <div
        class="img-box show-ul-li"
        :style="VPointer()"
        v-for="(item, index) in BoxList"
        :key="item.id"
        @click="goDeatil(item.all_skins_id)"
        @mouseover="mouseoverTwo(index)"
        @mouseleave="mouseoutTow(index)"
      >
        <div class="sss">
          <!-- 每个小盒子 -->
          <div class="b">
            <img class="b-frame" :src="item.rarity_thumb" alt="" />
            <img class="b-main" :src="item.image_url" alt="" />
            <div class="b-name">{{ item.item_name }}</div>
          </div>
          <!-- 旋转后的盒子 -->
          <div class="c">
            <img class="c-frame-c" v-if="liIndex==index" :src="item.rarity_thumb" alt="" />
            <img class="c-main-c" :src="item.image_url" v-if="liIndex==index" alt="" />
            <div class="c-name-c" v-if="liIndex==index">{{ item.item_name }}</div>
            <img class="c-head-c" v-if="liIndex==index" :src="item.player_head_img" alt="" />
            <div class="c-userName-c" v-if="liIndex==index" >{{ item.player_nike_name }}</div>
          </div>
        </div>
      </div>

      <div
        class="img-box show-ul-li"
        :style="VPointer()"
        v-for="(item, index) in BoxList"
        :key="item.id"
        @click="goDeatil(item.all_skins_id)"
        @mouseover="mouseoverTwo(index)"
        @mouseleave="mouseoutTow(index)"
      >
        <div class="sss">
          <!-- 每个小盒子 -->
          <div class="b">
            <img class="b-frame" :src="item.rarity_thumb" alt="" />
            <img class="b-main" :src="item.image_url" alt="" />
            <div class="b-name">{{ item.item_name }}</div>
          </div>
          <!-- 旋转后的盒子 -->
          <div class="c">
            <img class="c-frame-c" :src="item.rarity_thumb" alt="" />
            <img class="c-main-c" :src="item.image_url" alt="" />
            <div class="c-name-c">{{ item.item_name }}</div>
            <img class="c-head-c" v-if="liIndex==index" :src="item.player_head_img" alt="" />
            <div class="c-userName-c" v-if="liIndex==index">{{ item.player_nike_name }}</div>
          </div>
        </div>
      </div>

      <!-- 复制一份滚动内容，用于实现无缝对接-->
      <!-- <div
        class="img-box show-ul-li"
        :style="VPointer()"
        v-for="(item, index) in BoxList"
        :key="index"
        @mouseover="mouseoverTwo(index)"
        @mouseleave="mouseoutTow(index)"
      >
        <div class="sss">
          <div class="b">
            <img class="b-frame" :src="item.rarity_thumb" alt="" />
            <img class="b-main" :src="item.image_url" alt="" />
            <div class="b-name">{{ item.item_name }}</div>
          </div>
          <div class="c">
            <img class="c-frame-c" :src="item.rarity_thumb" alt="" />
            <img class="c-main-c" :src="item.image_url" alt="" />
            <div class="c-name-c">{{ item.item_name }}</div>
            <img class="c-head-c" :src="item.player_head_img" alt="" />
            <div class="c-userName-c">{{ item.player_nike_name }}</div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
  import { get_open_luncky_record } from "@/api/index/home.js";
export default {
  name: 'BoxShowBodyOpenTake',
  data() {
    return {
      datetimer: null,
      box: '',
      BoxList: [], //
      liIndex:null
    }
  },
  mounted() {
    this.getBoxList()
    // this.init()
    // this.init()
  },
  actions() {},
  methods: {
    goDeatil(e){
      //this.$router.push('/OrnamentOpen?id='+e)
	   this.$emit('changes',e)
    },
    // 开箱历史记录
    getBoxList() {
      get_open_luncky_record().then((res) => {
        this.BoxList = res.data
		    this.init()
      })
    },
    //元素超过4个之后才开始新建定时器实现滚动
    init() {
      if (this.BoxList.length > 4) {
        this.box = this.$refs.wrapper
        this.datetimer = setInterval(() => {
          this.move()
        }, 10)
      }
    },
    // 跑马灯工作
    move() {
      let curLeft = this.box.scrollLeft
      //父盒子总宽度除以2 (20是我这边盒子之间的右边距)
			if(!this.$refs.marquee){
				 return 
			}
      let scrollWidth = this.$refs.marquee.scrollWidth / 2
      this.box.scrollLeft = curLeft + 1
      if (curLeft >= scrollWidth) {
        this.box.scrollLeft = 0
      }
    },
    mouseoverTwo(index) {
      this.liIndex=index
      // console.log(132)
      document.querySelectorAll('.sss')[index].style.transform =
        'rotateY(180deg)'
    },
    mouseoutTow(index) {
      this.liIndex=null
      // console.log(456)
      document.querySelectorAll('.sss')[index].style.transform = 'rotateY(0deg)'
    },
    //鼠标悬停
    mouseover() {
      clearInterval(this.datetimer)
      this.datetimer = null
      console.log('鼠标悬停');
    },
    //鼠标离开，继续滚动
    mouseout() {
      this.init()
    }
  },
  //销毁定时器
  beforeDestroy() {
    clearInterval(this.datetimer)
    this.datetimer = null
  }
}
</script>
<style lang="scss" scoped>
.competition-dynamics-warp-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 70;
  .marquee-box {
    display: flex;
    .img-box {
      margin-right: 40px;
      .img {
        width: 270px;
        height: 160px;
      }
      .title {
        width: 270px;
        height: 50px;
        background: #ffffff;
        border-radius: 0px 0px 6px 6px;
        text-align: center;
        line-height: 50px;
        font-size: 16px;
        font-family: Noto Sans S Chinese;
        font-weight: 400;
        color: #333333;
        margin-top: -2px;
      }
    }
  }
}
//轮播动画
.show-ul-li {
  margin: 0 10px;
  width: 180px;
  height: 125px;
  position: relative;
  .sss {
    margin: 0 10px;
    width: 180px;
    position: relative;
    transition: all 0.8s linear;
    z-index: 90;
    backface-visibility: hidden;
    .b {
      // transition: all 0.8s linear;
      &-frame {
        width: 180px;
        z-index: 90;
      }
      &-main {
        width: 110px;
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 90;
      }

      &-name {
        color: #b5dade;
        @extend .b-main;
        top: 50%;
        transform: translate(-50%, 96%);
        font-weight: 700;
        font-size: 14px;
        width: 160px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .c {
      position: absolute;
      // transition: all 0.8s linear;
      top: 0;
      z-index: -1;
      &-frame-c {
        width: 180px;
        z-index: 80;
        position: absolute;
        top: 0%;
        left: 0%;
        // transform: translate(-50%, -50%);
        transform: rotateY(180deg);
        z-index: -1;
      }
      &-main-c {
        width: 110px;
        position: absolute;
        // top: 25px;
        left: 35px;
        top: 20px;
        // transform: translate(-50%, -50%);
        transform: rotateY(180deg);
        z-index: -1;
      }
      &-name-c {
        color: #b5dade;
        position: absolute;
        transform: rotateY(180deg);
        top: 6px;
        left: 3%;
        font-weight: 700;
        font-size: 14px;
        width: 160px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        z-index: -1;
      }
      &-head-c {
        width: 25px;
        height: 25px;
        border-radius: 100px;
        position: absolute;
        top: 75px;
        left: 150px;
        // transform: translate(-50%, -50%);
        transform: rotateY(180deg);
        z-index: -1;
      }
      &-userName-c {
        width: 130px;
        position: absolute;
        top: 75px; 
        left: 15px;
        // transform: translate(-50%, -50%);
        transform: rotateY(180deg);
        z-index: -1;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    // 旋转

    // &:hover .c {
    //   position: relative;
    //   top: -100%;
    //   left: 0;
    //   transform: rotateY(180deg);
    //   z-index: -1;
    //   transition: all 0.8s linear;
    // }
  }
  // .show-ul-li:hover {
  //     transform: rotateY(180deg);
  //     // transition: all 0.8s linear;
}
</style>
